import React, { useState, useEffect, useContext } from 'react';
import { DBContext } from '../../../controller/contexts/dbContext';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ReactPlayer from 'react-player';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import RefreshIcon from '@material-ui/icons/Refresh';
import CardActionArea from '@material-ui/core/CardActionArea';
import Chip from '@material-ui/core/Chip';
import CommentTile from './commentTile';
import { CircularProgress, Divider } from '@material-ui/core';
import List from '@material-ui/core/List';
import Button from '@material-ui/core/Button';

var moment = require('moment');

const VideoCommentsDialogue = (props) => {
  const classes = useStyles();
  const { lessonData } = props;
  const { getLessonComments, commentsData,
    fetching, postAnswerToQuestion
  } = useContext(DBContext);



  // lodes the live data from comments related to the 
  // video component 
  // this method will  call the dbContext function to fetch live comments
  // and detauch listeners when the componet will unmount
  useEffect(() => {
    try {
      console.log('lesson commentd', lessonData);
      getLessonComments(lessonData.id)
      console.log('commentsData', commentsData);

    } catch (error) {
      console.log(error)
    }

    // this return function works as 
    // componentWillUnmount in components , 
    // this can be used to unsubscribe listners

  }, [])




  const returnLessonCard = () => {
    return (
      <ListItem alignItems="flex-start">
        <Card className={classes.videoTitle}>
          <CardHeader

            title={lessonData.videoTitle}
            subheader={moment(lessonData.createdAt.toDate()).format('MMMM Do YYYY')}
          />
          <ReactPlayer
            url={lessonData.videoUrl}
            controls
          />
          <CardContent>
            <Typography variant="body2" color="textSecondary" component="p">
              {lessonData.videoDiscreption}
            </Typography>
            <CardActionArea className={classes.padding}>
              <Chip size="small" label={lessonData.selectedSubject} />
              {lessonData.selectedClasses.map((eachClass) => <Chip variant="outlined" size="small" label={eachClass.classDisplayName} />)}

            </CardActionArea>


          </CardContent>

        </Card>

      </ListItem>
    )
  }
  const returnCommentData = () => {
    if (commentsData == null || fetching) {
      return <CircularProgress />
    }
    if (commentsData.length < 1) {
      return <Typography> No Comments Yet </Typography>
    } else {
      return (<List className={classes.root}>
        {commentsData.map((eachComment) => (
          <CommentTile key={`${Math.random()}+COMMENT`} commentData={eachComment} lessonData={lessonData} />
        ))}


      </List>)



    }
  }
  return (
    <div className={classes.root}>
      <Grid container md spacing={4} direction='column' justify='center'>
        <Grid item xs={12} sm={9}>
          {returnLessonCard()}
          <Divider />
        </Grid>
        <Grid item xs={12} sm={9}>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={<RefreshIcon />}
            onClick={() => getLessonComments(lessonData.id)}
          >
            Refresh
      </Button>
        </Grid>
        <Grid item xs={12} sm={9}>
          {returnCommentData()}
        </Grid>
      </Grid>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  videoTitle: {
    width: '100%',
    height: '80%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  padding: {
    paddingTop: theme.spacing(1)
  },
  error: {
    color: 'red'
  }
}));

export default VideoCommentsDialogue;