import React, { useEffect, useState, useContext } from 'react'
import { DBContext } from '../../../controller/contexts/dbContext'
import Grid from '@material-ui/core/Grid'
import Layout from '../../layout'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'
import Divider from '@material-ui/core/Divider'
import TextField from '@material-ui/core/TextField'
import StudentEntry from '../../components/student/studentEntry'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import AddIcon from '@material-ui/icons/Add'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import {
  Button,
  Paper,
  DialogTitle,
  Typography,
  DialogActions,
  LinearProgress,
  ListItem,
  Avatar,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import Toolbar from '@material-ui/core/Toolbar'
import SerchIcon from '@material-ui/icons/Search'
import Box from '@material-ui/core/Box'

const Students = (props) => {
  const [serchedStudentName, setSerchedStudentName] = useState('')
  const [serchedStudentClass, setSerchedStudentClass] = useState('')
  const classes = useStyles()
  const {
    fetching,
    selectedSchool,
    schoolStudents,
    fetchSchoolStudents,
    error,
    fetchStudentWithSecretAndReturnStudentId,
    fetchStudentData,
    addStudetToTheSchool,
   
  } = useContext(DBContext)
  const [studentToViewAndEdit, setStudentToViewAndEdit] = useState(null)
  const [
    isStuednetEntryDialogueOpen,
    setIsStuednetEntryDialogueOpen,
  ] = useState(false)
  const [
    isAddStudentWithSecretDialogueOpen,
    setIsAddStudentWithSecretDialogueOpen,
  ] = useState(false)
  const [secretEnterd, setSecretEnterd] = useState(null)
  const [studentFetchedWithSecret, setStudentFetchedWithSecret] = useState(null)

  //let selectedSchool = allSchoolsData[schoolIndex];

  //console.log('selected school is ', selectedSchool);

  /**Loads The Students Data Related to the school on every rerender */
  useEffect(() => {
    if(selectedSchool){
      fetchSchoolStudents()
    }
    

    // This will use to reset the state values
    // NOTE: this method will call when the this component
    // unmounting
    // return function cleanup(){
    //   // setStudentToViewAndEdit(null)
    // }
  }, [])

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
  })

  const handleClassesTextInputChange = (event) => {
    // TODO: Show Student searched By className
  }

  const handleStuedntNameTextInputChange = (event) => {
    // TODO: Show students according to text iput
  }

  const openNewStudetEntryDialogue = () => {
    setStudentToViewAndEdit(null)
    togleStudentEntryDailogue()
  }

  const openViewEditStudentDialogue = (student) => {
    setStudentToViewAndEdit(student)
    togleStudentEntryDailogue()
  }
  const togleStudentEntryDailogue = () => {
    setIsStuednetEntryDialogueOpen(
      (isStuednetEntryDialogueOpen) => !isStuednetEntryDialogueOpen,
    )
  }

  const returnStudentEntryDialogue = (studentToView) => {
    return (
      <>
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          aria-labelledby="customized-dialog-title"
          open={isStuednetEntryDialogueOpen}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              edge="end"
              onClick={togleStudentEntryDailogue}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <DialogContent>
            <StudentEntry
              isNewStudentCreating={studentToView === null}
              togleStudentEntryDailogue={togleStudentEntryDailogue}
              student={studentToView}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }
  /**
   * TODO: THIS IS THE NEW FEATURE
   * To add students, we have created this new method.
   * When the parent shares the profile secret with the school
   * School management will add that secret here to fetch for the student detail
   * If they find a student details they can add the student by fetching
   * here in this dialogue and clicking on the add symbol
   * if the student already in some school they can't add them unles they got exited
   * from that school.
   */
  const openAddStudentsUsingSecret = () => {
    setIsAddStudentWithSecretDialogueOpen(true)
  }
  const closeAddStudentsUsingSecret = () => {
    // resent the state values
    setSecretEnterd(null)
    setStudentFetchedWithSecret(null)
    setIsAddStudentWithSecretDialogueOpen(false)
  }
  /**
   * Adds the kids profile to the school
   * TODO: 1) Should check the student register in any school
   * 2) If the student is already in some school, it should
   * alert the user that he is already registerd in some school
   * @param {Object} studentData
   */
  const addStudentToSchool = async (studentData) => {
    // add Studetnt to the Schoolstudents collection
    //console.log('returnStudentProfileObject', studentData.returnStudentProfileObject());
    const output = await addStudetToTheSchool(
      studentData.returnStudentProfileObject(),
    )
    if (output !== 0) {
      return alert(`Error!: ${output}`)
    }
    // fetch students again
    await fetchSchoolStudents()
    //closeThe
    closeAddStudentsUsingSecret()

    // move to the edit profile edit view
    return openViewEditStudentDialogue(studentData)
  }

  // fetches student id with secret and if any student is there with that secret,
  // it will first fetch with secret for student id, if that secret is not expired
  // and its associated with a student id it will fetch student data and updates the
  // state with the student data.
  const handleFetchStudentWithSecret = async () => {
    // to remove previously serached student data.
    setStudentFetchedWithSecret(null)
    if (!secretEnterd || secretEnterd.length !== 11) {
      return alert('Please enter 11 digit Student Profile Secret')
    }
    const studentId = await fetchStudentWithSecretAndReturnStudentId(
      secretEnterd,
    )

    if (!studentId || studentId.length < 10) {
      return alert('Error! Secret code you have enterd is expired or not valid')
    }
    console.log('Profile Studnet id :', studentId)
    //fetch for student with id,
    const student = await fetchStudentData(studentId)
    if (!student) {
      return alert(
        'Error! No user is associated with this secret. Please check again',
      )
    }

    return setStudentFetchedWithSecret(student)
  }
  const returnAddStudentUsingSecret = () => {
    return (
      <Box width={1} p={1}>
        {fetching && <LinearProgress color="primary" />}

        <Dialog
          maxWidth="md"
          open={isAddStudentWithSecretDialogueOpen}
          onClose={closeAddStudentsUsingSecret}
          aria-labelledby="max-width-dialog-title"
        >
          <DialogTitle id="alert-dialog-setting-title12343">
            {'Add New Student'}
          </DialogTitle>
          <DialogContent>
            <Box width={1} mx="auto">
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Grid container direction="row" spacing={2}>
                    <Grid item>
                      <Box p={1} width={1}>
                        <TextField
                          variant="outlined"
                          fullWidth
                          autoFocus
                          label="Please enter 11 numbes secret"
                          value={secretEnterd ? secretEnterd : ''}
                          onChange={(e) =>
                            setSecretEnterd(e.target.value.trim())
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item>
                      <Box p={1} mt={1} mx="auto">
                        <Button
                          disabled={!secretEnterd || secretEnterd.length !== 11}
                          variant="contained"
                          onClick={handleFetchStudentWithSecret}
                        >
                          Serch
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item>
                  {!!studentFetchedWithSecret ? (
                    <Paper style={{ margin: 8 }}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar src={studentFetchedWithSecret.imageUrl} />
                        </ListItemAvatar>
                        <ListItemText
                          primary={`${studentFetchedWithSecret.firstName} ${studentFetchedWithSecret.lastName}`}
                          secondary={`${studentFetchedWithSecret.nameParentCreated}`}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() =>
                              addStudentToSchool(studentFetchedWithSecret)
                            }
                          >
                            <AddIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </Paper>
                  ) : null}
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={closeAddStudentsUsingSecret} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
  }

  return (
    <>
      {isStuednetEntryDialogueOpen &&
        returnStudentEntryDialogue(studentToViewAndEdit)}
      {isAddStudentWithSecretDialogueOpen && returnAddStudentUsingSecret()}
      <Backdrop className={classes.backdrop} open={fetching}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Layout>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Grid
            container
            spacing={1}
            direction="column"
            className={classes.root}
          >
            <Grid item>
              <Divider />
            </Grid>

            {/* For upper serching ares */}
            <Grid item sm={12} xs={12}>
              <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="flex-end"
                spacing={2}
              >
                <Grid item sm={3} xs={12}>
                  <TextField
                    name="Student Name"
                    fullWidth
                    variant="outlined"
                    id={`${Math.random()}`}
                    label="Student Name"
                    value={serchedStudentName}
                    onChange={handleStuedntNameTextInputChange}
                    className={classes.textInput}
                  />
                </Grid>

                <Grid item sm={3} xs={12}>
                  <TextField
                   id={`${Math.random()}`}
                    select
                    fullWidth
                    label="Select Class"
                    value={serchedStudentClass.classDisplayName}
                    onChange={handleClassesTextInputChange}
                    SelectProps={{
                      native: true,
                    }}
                    variant="outlined"
                  >
                    {!!selectedSchool && selectedSchool.schoolClasses
                      ? selectedSchool.schoolClasses.map((option) => (
                          <option
                            key={option + `${Math.random()}`}
                            value={option}
                          >
                            {option.classDisplayName}
                          </option>
                        ))
                      : null}
                  </TextField>
                </Grid>
                <Grid item sm={3} xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={openNewStudetEntryDialogue}
                    //onClick={openAddStudentsUsingSecret}
                  >
                    + Add New Student
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {/* For devider */}

            <Divider />

            {/* For lower Student List Area are */}
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Table className={classes.table}>
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head" align="left">
                        NAME
                      </TableCell>
                      <TableCell variant="head" align="right">
                        CLASS
                      </TableCell>
                      <TableCell variant="head" align="right">
                        SECTION
                      </TableCell>
                      <TableCell variant="head" align="right">
                        ACTIONS
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {error ? (
                      <h4>({`${error}`})</h4>
                    ) : (
                      schoolStudents.map((student) => {
                        //console.log('Student is ', student);
                        return (
                          <TableRow key={student.id } id={`${Math.random()}`}>
                            <TableCell
                              component="th"
                              scope="row"
                              className={classes.tableCell}
                            >
                              {student.firstName + ' ' + student.lastName}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.tableCell}
                            >
                              {student.studentClass.schoolClassName}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.tableCell}
                            >
                              {student.studentClass.sectionSymbol}
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                variant="outlined"
                                size="small"
                                color="primary"
                                className={classes.margin}
                                onClick={() =>
                                  openViewEditStudentDialogue(student)
                                }
                              >
                                View/Edit
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      })
                    )}
                  </TableBody>
                </Table>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '8%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  textInput: {
    width: '100%',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  button: {
    margin: theme.spacing(1),
  },
  margin: {
    margin: theme.spacing(1),
  },
  table: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
}))

export default Students
