import React, { useState, useContext, useEffect } from 'react';
import { DBContext } from '../../../controller/contexts/dbContext';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import CardActionArea from '@material-ui/core/CardActionArea';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';
import Badge from '@material-ui/core/Badge';
var moment = require('moment');


const HomeworkTile = ({ key, homework, openEdit, deleteHomework, openHomeworkSubmissionsDialogue }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const { fetchHomeworkSubmissions } = useContext(DBContext);
  const [homeworkSubmissionData, setHomeworkSubmissionData] = useState(null);
  /**
   * This useEffect method fetches 
   * the submissions of the homework to pass the 
   * Submisson view 
   */
  useEffect(() => {

    //fetchAndUpdateSubmissionsData();
  }, [])

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleEdit = (e) => {
    openEdit(homework)
  }
  const handleDelete = e => {
    deleteHomework(homework.id)
  }

  const fetchAndUpdateSubmissionsData = async () => {
    const submissionData = await fetchHomeworkSubmissions(homework.id);
    console.log('submissionDAta', submissionData);
    submissionData && setHomeworkSubmissionData([...submissionData]);
  }
  return (

    <ListItem alignItems="flex-start"
      key={key}
    >
      <Card className={classes.root}>
        <CardHeader

          action={
            <div>
              <IconButton aria-label="settings-submissions"
                onClick={() => openHomeworkSubmissionsDialogue(homework, homeworkSubmissionData, fetchAndUpdateSubmissionsData)}>
                <Badge
                  badgeContent={!!homework.homeworkSubmissionNotificationNumber ? homework.homeworkSubmissionNotificationNumber : 0}
                  max={999}
                  color="secondary"
                >
                  <AssistantPhotoIcon />
                </Badge>
              </IconButton>

              <IconButton aria-label="settings" onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="simple-menu-table-homeowrk"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleEdit}>Edit</MenuItem>
                <MenuItem className={classes.error} onClick={handleDelete}>Delete</MenuItem>
              </Menu>
            </div>


          }
          title={homework.homeworkTitle}
          subheader={moment(homework.createdAt.toDate()).format('MMMM Do YYYY')}
        />

        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            {homework.homeworkDiscreption}
          </Typography>
          <CardActionArea className={classes.padding}>
            <Chip size="small" label={homework.selectedSubject} />
            {homework.selectedClasses.map((eachClass) => <Chip variant="outlined" size="small" label={eachClass.classDisplayName} />)}

          </CardActionArea>


        </CardContent>

      </Card>

    </ListItem>

  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  padding: {
    paddingTop: theme.spacing(1)
  },
  error: {
    color: 'red'
  }
}));

export default HomeworkTile; 