import React, { useState, useContext, useEffect } from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { DBContext } from '../../../controller/contexts/dbContext'
import Layout from '../../layout'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import InputLabel from '@material-ui/core/InputLabel'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import { green, red } from '@material-ui/core/colors'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import { DatePicker } from '@material-ui/pickers'
import Schedule from '../../../model/models/schedule'
import AttendanceTile from '../../components/attendance/AttendanceTile'
import AccountVerificationError from '../../components/accoutVerificationError'

import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableRow,
  Text,
} from 'grommet'
var moment = require('moment')

const Attendance = () => {
  const classes = useStyles()
  const {
    selectedSchool,
    schoolStudents,
    fetching,
    fetchSchoolAttendanceForDate,
    saveStudntAttendanceForSelectedDay,
  } = useContext(DBContext)
  const [selectedClass, setSelectedClass] = useState('ALL')
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [
    schoolAttendanceForSelectedDay,
    setSchoolAttendanceForSelectedDay,
  ] = useState({})
  const [filterdStudents, setFilterdStudents] = useState([])

  useEffect(() => {
    //setFilterdStudents([...schoolStudents])
    //fetchAndProcessSchoolAttendance(selectedDate)
  }, [])

  /**
   * For changing date and classes
   * selecters
   * student are filterd by class id and posted to the state
   * @param {Object} e
   */
  const handleClassUpdate = async (e) => {
    console.log('classUpdate', e.target.value)

    await setSelectedClass(e.target.value)

    if (e.target.value === 'ALL') {
      return await setFilterdStudents([...schoolStudents])
    } else {
      let list = []
      await schoolStudents.forEach((eachStudent) => {
        if (eachStudent.studentClass.id === e.target.value.id) {
          list.push(eachStudent)
        }
      })
      return await setFilterdStudents([...list])
    }
  }

  const handleUpdateDate = async (date) => {
    const dateJS = date.toDate()
    await setSelectedDate(dateJS)
    //fetch attendance for data

    await setSelectedClass('ALL')
    await setFilterdStudents([...schoolStudents])
    return await fetchAndProcessSchoolAttendance(dateJS)
  }

  /**
   * 1) This function fetches the attendance data for the kid
   *  by using selectedDate in the state.
   * 2) It proces data in a way that it crate an object that contains student id and
   *  attendence data for that student. like {'studentId': ATTENDENCEDATA_FOR_THAT_KID}
   * 3) Sets the state with modifiyed data
   *
   */
  const fetchAndProcessSchoolAttendance = async (date) => {
    const aList = await fetchSchoolAttendanceForDate(date)

    //console.log('aList', aList);
    if (!aList || aList.length < 1) {
      setSchoolAttendanceForSelectedDay({})
    } else {
      var modifyedAList = {}
      for (let i = 0; i < aList.length; i++) {
        modifyedAList = { ...modifyedAList, [aList[i].studentId]: aList[i] }
      }
      await setSchoolAttendanceForSelectedDay({ ...modifyedAList })
    }
  }

  /**
   * This function called from the Attendance Tile Component
   * This saves the give student attendance by calling
   * saveStudntAttendanceForSelectedDay() fuction in the
   * DBContext which returns the Attendance model object which we will
   * be adding to the state.
   * @param {Object} student
   * @param {boolean} isPresent
   */
  const updateAtttendanceForStudent = async (student, isPresent) => {
    if (!!schoolAttendanceForSelectedDay[student.id]) {
      return
    }
    const savedStudentAttendance = await saveStudntAttendanceForSelectedDay(
      student,
      selectedDate,
      isPresent,
    )
    setSchoolAttendanceForSelectedDay({
      ...schoolAttendanceForSelectedDay,
      [student.id]: savedStudentAttendance,
    })
  }

  /**
   * This is the component that returns from the
   * return statement, this takes students list and attendance list and date
   * to render the student attendance for the selected date
   * NOTE: We are passing Date object to this component which is the selected
   * date to make sure we desabled the check boxes in the AttendanceTile component
   * for the future date to prevent doing attendence to tomorrow or furter.
   *
   */
  const StudentsAttendenceList = ({ studentList, attendanceList, date }) => {
    //console.log('attendanceList from StudentsAttendenceList', attendanceList);

    if (schoolStudents.length < 1 && !fetching) {
      return <Typography> No Data Available</Typography>
    }
    return (
      <Box width="100%">
        <Table>
          <TableHeader background="grey">
            <TableRow key={`${Math.random()}`}>
              <TableCell scope="col" border="bottom">
                Profile Image
              </TableCell>
              <TableCell scope="col" border="bottom">
                Name
              </TableCell>
              <TableCell scope="col" border="bottom">
                Class
              </TableCell>
              <TableCell scope="col" border="bottom">
                Present
              </TableCell>
              <TableCell scope="col" border="bottom">
                Absent
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {studentList.length > 1 ? (
              studentList.map((eachStudent) => (
                <AttendanceTile
                  key={eachStudent.id}
                  student={eachStudent}
                  studentAttendance={attendanceList[eachStudent.id]}
                  udateAttendance={updateAtttendanceForStudent}
                  loading={fetching}
                  date={date}
                />
              ))
            ) : (
              <h5>No Students Found</h5>
            )}
          </TableBody>
        </Table>
      </Box>
    )
  }

  return (
    <Layout>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        {fetching && <LinearProgress color="secondary" />}
        <Grid container spacing={2} direction="column" className={classes.root}>
          <AccountVerificationError />

          {/**
           * <Grid item xs={12} sm={12}>
          <Grid container spacing={1}
            direction="row"
            justify='space-between'
            alignItems="center"
            className={classes.p8}
          >
            <Typography variant='h4' >Attendance</Typography>
            
            {/* <Button variant='contained' color='primary'>+ Add Schedule</Button> */}
          {/* </Grid>

           </Grid> */}

          {/* <Grid item xs={12} sm={12}>
            <Paper className={classes.selectors}>
              <Grid container spacing={1}
                direction="row"
                justify='space-between'
                alignItems="center"
              >
                <Grid item sm={6} xs={6} className={classes.selectorItems}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id={`${Math.random()}`}>Select Classe</InputLabel>
                    <Select
                      labelId={`${Math.random()}`}
                      id={`${Math.random()}`}
                      value={selectedClass ? selectedClass : ''}
                      onChange={handleClassUpdate}
                      input={<Input />}
                      renderValue={(selected) => !!selected ? (selected.classDisplayName ? selected.classDisplayName : 'ALL') : ''}
                      MenuProps={MenuProps}
                      fullWidth
                    >
                      <MenuItem key={`${Math.random()}`} value={'ALL'} >
                        <Checkbox checked={selectedClass === 'ALL'} />
                        <ListItemText primary={'ALL'} />
                      </MenuItem>
                      {(!!selectedSchool && selectedSchool.schoolClasses) ? selectedSchool.schoolClasses.map((option) => (
                        <MenuItem key={option.id + `${Math.random()}`} value={option} >
                          <Checkbox checked={selectedClass === option} />
                          <ListItemText primary={option.classDisplayName} />
                        </MenuItem>
                      )) : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={6} className={classes.selectorItems}>
                  <FormControl className={classes.formControl}>
                    <DatePicker
                      fullWidth
                      variant='dialog'
                      id="date-picker-inline-calenderschedule"
                      label="Select Date"
                      value={selectedDate}
                      onChange={handleUpdateDate}
                      onError={console.log}
                      format="dddd, MMMM Do YYYY"

                    />

                  </FormControl>
                </Grid>
              </Grid>
            </Paper>

          </Grid> */}

          {/* <Grid item xs={12} sm={12} className={classes.table}>

            <StudentsAttendenceList
              studentList={filterdStudents}
              attendanceList={schoolAttendanceForSelectedDay}
              date={selectedDate}
            />
          </Grid> */}
        </Grid>
      </Container>
    </Layout>
  )
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    marginTop: '12%',
    marginBottom: '2%',
    borderRadius: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '60%',
    padding: 4,
  },
  selectors: {
    height: 80,
  },
  selectorItems: {
    flexGrow: 1,
    padding: 12,
    width: '10vh',
  },
  table: {
    paddingTop: 16,
    width: '100%',
    height: '100%',
  },
  p8: {
    padding: 8,
  },
}))

export default Attendance
