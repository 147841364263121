import React, { useState, useContext, useEffect } from 'react'
import { Box, Text, Image, Tab, Tabs } from 'grommet'
import CircularProgress from '@material-ui/core/CircularProgress'
import { DBContext } from '../../../controller/contexts/dbContext'
import { makeStyles } from '@material-ui/core/styles'
import Layout from '../../layout'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Avatar from '@material-ui/core/Avatar'
import Backdrop from '@material-ui/core/Backdrop'
import { margin } from 'polished'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import CourseProfile from '../../components/courses/courseProfile'
import RichTabTitle from '../../components/richTabTitle'
import StudentViewForCourseRefferalView from '../../components/courses/studentViewForCourseRefferalView.jsx'
import {
  CircleInformation,
  StatusGood,
  Currency,
  Group,
  Microfocus,
} from 'grommet-icons'
const CoursesPage = () => {
  const classes = useStyles()

  const { fetching, courseList, fetchAvailableCourses } = useContext(DBContext)
  const [selectedCourse, setSelectedCourse] = useState(null)

  // For fetching initial lesson data
  useEffect(() => {
    fetchCourses()
  }, [])
  const fetchCourses = async () => {
    const error = await fetchAvailableCourses()
    if (error) {
      alert(error)
    }
  }
  const updateSelectedCourse = ({ target }) => {
    console.log(target.value)
    setSelectedCourse(target.value)
  }

  const renderSelectedCourseView = () => {
    if (!selectedCourse && !fetching) {
      return (
        <Box
          justify="center"
          align="center"
          padding="large"
          margin="large"
          flex="grow"
        >
          <Typography variant="subtitle1">
            {'Please select a course to view the course data'}
          </Typography>
        </Box>
      )
    }
    if (!!selectedCourse) {
      //return <CourseProfile course={selectedCourse} />

      return (
        <Box pad="none" fill background="yello">
          <Tabs flex>
            <Tab
              title={
                <RichTabTitle
                  icon={<Microfocus color="neutral-1" />}
                  label="Course Profile"
                />
              }
            >
              <CourseProfile course={selectedCourse} />
            </Tab>
            <Tab
              title={
                <RichTabTitle
                  icon={<Group color="status-warning" />}
                  label="Reffer Students"
                />
              }
            >
              <StudentViewForCourseRefferalView course={selectedCourse} />
            </Tab>
          </Tabs>
        </Box>
      )
    }
  }
  return (
    <>
      <Backdrop className={classes.backdrop} open={fetching}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Layout>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Box fill margin={{ top: 'medium' }} padding={{ top: 'medium' }}>
            <Box margin={{ top: '18px' }} pad={{ top: 'small' }}>
              <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel id={`${Math.random() * 2}`}>
                  Select Course
                </InputLabel>
                <Select
                  labelId={`${Math.random() * 2}`}
                  id={`${Math.random() * 2}`}
                  value={selectedCourse}
                  onChange={updateSelectedCourse}
                  label="Select Course"
                >
                  {
                    // For rendering various courses avaialble
                    [...courseList].map((eachCourse) => (
                      <MenuItem value={eachCourse} key={`${Math.random() * 2}`}>
                        <Box
                          height="50px"
                          flex
                          direction="row"
                          justify="start"
                          align="start"
                          gap="small"
                        >
                          <Box justify="center" align="center" pad="8px">
                            <Avatar
                              alt={`${eachCourse.title}`}
                              src={`${eachCourse.bannerUrl}`}
                            />
                          </Box>
                          <Box width="700px">
                            <Box width="600px">
                              <Typography variant="h6" gutterBottom>
                                {eachCourse.title}
                              </Typography>
                            </Box>

                            <Box width="600px">
                              <Typography variant="caption">{`By ${eachCourse.orgData.name}`}</Typography>
                            </Box>
                          </Box>
                          <Box
                            width="50px"
                            height="50px"
                            justify="center"
                            align="center"
                            alignSelf="center"
                            pad="small"
                            margin="small"
                          >
                            <Button color="primary">Select</Button>
                          </Box>
                        </Box>
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Box>
          </Box>

          {/* 
            // Add Selection box to select various courses 
            // When selected, the
            // data belongs to that course will be fetched 
            // we will asign students after that  
            */}
          <Box pad="small" margin={{ top: 'small' }}>
            {renderSelectedCourseView()}
          </Box>
        </Container>
      </Layout>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '8%',
    padding: theme.spacing(2),
    marginBottom: '2%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  formControl: {
    margin: theme.spacing(1),
    width: '100%',
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  padding: {
    padding: 12,
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
    padding: 20,
    marginBottom: 8,
  },
  button: {
    margin: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '100%',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
}))

export default CoursesPage
