import React, { useState, useEffect } from 'react';



const ViewLessionViewsPage = ({ lessonToView }) => {

  useEffect(() => {

  })
  return (
    <div>
      Video views
    </div>
  );
}

export default ViewLessionViewsPage;