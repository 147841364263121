import React, { useState, useEffect, useContext } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { DBContext } from '../../../controller/contexts/dbContext';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import Selectors from '../../components/selectors'
import Backdrop from '@material-ui/core/Backdrop';
import HomeworkEvalutationDialogue from './homeworkEvalutationDialogue';
var moment = require('moment');



const HomeworkSubmissionsPage = ({ close, homeworkToView, }) => {
  const classes = useStyles();
  const { submittHomeworkEvaluation, fetchHomeworkSubmissions } = useContext(DBContext);
  const [isHomeworkEvaluationDialogueOpen, setHomeworkEvaluationDialogueOpen] = useState(false);
  const [index, setIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submissionData, setSubmissionData] = useState(null);

  useEffect(() => {
    fetchAndUpdateSubmissionsData()
  }, [])

  const fetchAndUpdateSubmissionsData = async () => {
    setLoading(true)
    const submissionData = await fetchHomeworkSubmissions(homeworkToView.id);
    setLoading(false);
    //console.log('submissionDAta', submissionData);
    submissionData && setSubmissionData([...submissionData]);




  }

  const getEvaluationOutput = (score) => {
    let output = 'Not Avialable'

    switch (score) {
      case 0:
        output = 'BAD'
        break;
      case 1:
        output = 'Need to improve'
        break;
      case 2:
        output = 'Good'
        break;
      case 3:
        output = 'Excellent'
        break;

      default:
        break;
    }
    return output;
  }
  /**
   * Returns the Homework submissions Table
   */
  const returnHomeworkSubmissonTable = () => {
    if (submissionData === null || submissionData.length < 1) {
      return <Typography> No Homework Submissions Yet</Typography>
    }

    return (

      <div className={classes.table}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Student Name</StyledTableCell>
                <StyledTableCell align="right">Class</StyledTableCell>
                <StyledTableCell align="right">Submitted At</StyledTableCell>
                <StyledTableCell align="right">Score</StyledTableCell>
                <StyledTableCell align="right">Actions</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {submissionData.map((row, index) => (
                <StyledTableRow key={`${Math.random()}+homework`}>
                  <StyledTableCell component="th" scope="row">
                    {row.kidName}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.studyingClass.classDisplayName}</StyledTableCell>
                  <StyledTableCell align="right">{moment(row.submittedAt).fromNow()}</StyledTableCell>

                  <StyledTableCell align="right">{`${getEvaluationOutput(row.homeworkScore)}`}</StyledTableCell>
                  <StyledTableCell align="right">{
                    <Button color="primary"
                      onClick={() => openHomeworkEvaluationDialogue(index)}
                    >
                      View Submisson
                      </Button>
                  }</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

    )
  }

  const returnHomeworkEvalutationDialogue = () => {
    console.log('returnHomeworkEvalutationDialogue');
    return (
      <>
        <HomeworkEvalutationDialogue
          close={closeHomeworkEvaluationDialogue}
          homeworkToView={homeworkToView}
          homeworkSubmissonData={submissionData[index]}
          open={isHomeworkEvaluationDialogueOpen}
          saveEvaluation={saveEvaluation}

        />
      </>
    )
  }
  // save the evalutation data and close the dialogue
  const saveEvaluation = async (homeworkId, submissonId, payload) => {
    setLoading(true);
    await submittHomeworkEvaluation(homeworkId, submissonId, { ...payload });
    submissionData[index] = { ...submissionData[index], ...payload }
    await fetchAndUpdateSubmissionsData()
    setLoading(false);

  }
  const openHomeworkEvaluationDialogue = (index) => {
    console.log('openHomeworkEvaluationDialogue', index);
    setIndex(index);
    setHomeworkEvaluationDialogueOpen(true)
  }
  const closeHomeworkEvaluationDialogue = () => {
    setHomeworkEvaluationDialogueOpen(false)
  }




  return (
    <>
      {isHomeworkEvaluationDialogueOpen && returnHomeworkEvalutationDialogue()}

      <div>

        <Backdrop className={classes.backdrop} open={loading} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <main className={classes.layout}>

        <Grid conatiner spacing={2} direction="column"
          justify="center"
          alignItems="center">
          <Grid item xs={12} sm={9} className={classes.paper}>
            <Grid conatiner spacing={2}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Typography variant='h6' color="initial" className={classes.paddingTop} >
                {homeworkToView.homeworkTitle}
              </Typography>

              <Typography variant='subtitle2' color='textSecondary' >
                {`${moment(homeworkToView.createdAt.toDate()).format('MMMM Do YYYY')}`}
              </Typography>
              <Typography variant='subtitle1' color='inherit' className={classes.paddingTop} >
                {`Descreption: ${homeworkToView.homeworkDiscreption}`}
              </Typography>
              <Typography color='textSecondary' className={classes.paddingTop}>
                {homeworkToView.dueDate ? `Due Date: ${moment(homeworkToView.dueDate).format('MMMM Do YYYY')}` : 'No due date'}
              </Typography>
              <div className={classes.paddingTop}>
                <Typography color='textSecondary'>Subject :</Typography>

                {
                  <Chip size="small" label={homeworkToView.selectedSubject} />
                }
              </div>
              <div className={classes.paddingTop}>
                <Typography variant='subtitle1' color='textSecondary'>Classes :</Typography>
                {
                  homeworkToView.selectedClasses.map((eachClass) =>
                    <Chip variant="outlined" size="small" label={eachClass.classDisplayName} />
                  )
                }
              </div>


              <Typography variant='subtitle1'
                color='primary' className={classes.paddingTop}
              >
                {`Total Submissions: ${submissionData ? submissionData.length : 0} / ${homeworkToView && homeworkToView.studentCount ? homeworkToView.studentCount : 'undefind'}`}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Grid conatiner spacing={1} direction="column">
              <Grid item xs={12} sm={9}>

                {submissionData === null ? <Typography
                  color='error'
                > No submissions yet!
                </Typography>
                  : returnHomeworkSubmissonTable()
                }
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </main>
    </>
  );
}

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#566573',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);



const useStyles = makeStyles((theme) => ({

  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(900 + theme.spacing(2) * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paddingTop: {
    paddingTop: 8,
  },
  paper: {
    color: theme.palette.paper,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  submitButton: {
    margin: 8,
  },
  submit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  tollbarButton: {
    marginLeft: '70%',
  },
  table: {
    minWidth: 800,
  },

}));

export default HomeworkSubmissionsPage;