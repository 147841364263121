export default class SchoolProfile {
  constructor(id, schoolProfileObject) {
    this.id = id
    this.schoolName = schoolProfileObject.schoolName
    this.branchName = schoolProfileObject.branchName
    this.imageURL = schoolProfileObject.imageURL
    this.address = schoolProfileObject.address
    this.city = schoolProfileObject.city
    this.district = schoolProfileObject.district
    this.state = schoolProfileObject.state
    this.country = schoolProfileObject.country
    this.pincode = schoolProfileObject.pincode
    this.phone1 = schoolProfileObject.phone1
    this.phone2 = schoolProfileObject.phone2
    this.schoolClasses = schoolProfileObject.schoolClasses
    this.subjects = schoolProfileObject.subjects
    this.locations = schoolProfileObject.locations
    this.type = schoolProfileObject.type
  }
  returnSchoolData() {
    return {
      id: this.id,
      schoolName: this.schoolName,
      imageURL: this.imageURL,
      type: this.type,
      branchName: this.branchName,
      phone1: this.phone1,
      phone2: this.phone2,
      address: this.address,
      city: this.city,
      country: this.country,
    }
  }
}
