export default class ExamSubmission {

  constructor(id, submissonData) {
    console.log('submissiondataExamSubmiisons', submissonData);
    this.id = id;
    this.answers = submissonData.answers;
    this.examSubmittedAt = submissonData.examSubmittedAt.toDate();
    this.studentName = submissonData.studentProfile.studentName;
    this.examSubmitted = submissonData.examSubmitted;
    this.evaluationDone = submissonData.evaluationDone;
    this.studyingClass = submissonData.studentProfile.studyingClass;
    this.testPercentage = !!submissonData.examResult ? submissonData.examResult.scoreReport.testPercentage : 'Exam not evaluated yet';
    this.testRatio = (submissonData.type === 'MULTIPLECHOICE' && submissonData.examResult)
      ? `${!!submissonData.examResult ? submissonData.examResult.marks : ''}/${submissonData.examResult.totalQuestions}` : `${!!submissonData.examResult ? submissonData.examResult.marks : ''}`;
  }
}