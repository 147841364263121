import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../../../controller/contexts/authContext';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { validateEmail } from '../../../controller/helpers';
import LinearProgress from '@material-ui/core/LinearProgress';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        Edaffix Technologies LLP
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



export default function SignIn() {
  const { loading, doPasswordReset } = useContext(AuthContext);
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const history = useHistory();
  const [fetching, setFetching] = useState(false);

  function handlePWReset() {
    if (validateEmail(email)) {
      setError(null)
      setFetching(true)
      doPasswordReset(email)
        .then(function () {
          setFetching(true)
          alert('Success: Reset mail has been sent. \n Please check the email for pasword reset link')
          history.push('/');
        })
        .catch(error => {
          // Handle Errors here.
          setFetching(false);
          var errorCode = error.code;
          var errorMessage = error.message;
          console.log(errorMessage)
          errorMessage && alert(`${errorMessage} \n ${errorCode}`);

        })

    } else {
      setError('Please Enter Valid Email Address!')
    }
  }
  return (
    <>
      {(fetching || loading) && <LinearProgress />}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <>
            <Typography variant="h3" gutterBottom marked="center" align="center">
              Forgot your password?
          </Typography>
            <Typography variant="body2" align="center">
              {"Enter your email address below and we'll " +
                'send you a link to reset your password.'}
            </Typography>
          </>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(event) => {
                setError(null);
                setEmail(event.target.value);

              }}
              autoFocus
            />
            {error && <Typography variant="subtitle2" color="error">{error}</Typography>}
            <Button
              onClick={handlePWReset}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              {'Send reset link'}
            </Button>

            <Link href='/Signup' variant="body2">
              {"Don't have an account? Sign Up"}
            </Link>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));