import React, { useContext } from 'react'
import LoginPage from '../../view/pages/Login'
import SignupPage from '../../view/pages/Signup'
import ForgetPasswordPage from '../../view/pages/ForgetPassword'
import SettingsPage from '../../view/pages/Settings'
import StudentsPage from '../../view/pages/Students'
import WelcomePage from '../../view/pages/Welcome'
import EdOnlinePage from '../../view/pages/EdOnline'
import HomeworkPage from '../../view/pages/Homework'
import ExamsPage from '../../view/pages/Exams'
import HomePage from '../../view/pages/Home'
import OrgViewPage from '../../view/pages/OrgView'
import CalendarPage from '../../view/pages/Calendar'
import AttendancePage from '../../view/pages/Attendance'
import StaffMeetingsPage from '../../view/pages/StaffMeetings'
import SchedulePage from '../../view/pages/Schedule'
import CoursesPage from '../../view/pages/Courses'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import Loading from '../../view/components/loading'
import { AuthContext } from '../../controller/contexts/authContext'
import PrivateRoute from './privateRoute'
import { makeStyles } from '@material-ui/core/styles'

const NoComponent = () => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <h2>Sorry! This feature is not available now</h2>
    </div>
  )
}

const HomeRouter = () => {
  const { loading } = useContext(AuthContext)
  //console.log('loading is ', loading);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Router>
          <Switch>
            <Route exact path="/" component={LoginPage} />
            <Route exact path="/Signup" component={SignupPage} />
            <Route exact path="/ForgetPassword" component={ForgetPasswordPage} />
            <PrivateRoute exact path="/Home" component={HomePage} />
            <PrivateRoute exact path="/Settings" component={SettingsPage} />
            <PrivateRoute exact path="/Calendar" component={CalendarPage} />
            <PrivateRoute exact path="/Welcome" component={WelcomePage} />
            <PrivateRoute exact path="/Schedule" component={SchedulePage} />
            <PrivateRoute exact path="/Students" component={StudentsPage} />
            <PrivateRoute exact path="/EdOnline" component={EdOnlinePage} />
            <PrivateRoute exact path="/Homework" component={HomeworkPage} />
            <PrivateRoute exact path="/Exams" component={ExamsPage} />
            <PrivateRoute exact path="/OrgView" component={OrgViewPage} />
            <PrivateRoute exact path="/Attendance" component={AttendancePage} />
            <PrivateRoute exact path="/Courses" component={CoursesPage} />
            <PrivateRoute
              exact
              path="/StaffMeetings"
              component={StaffMeetingsPage}
            />
            <Route component={NoComponent} />
          </Switch>
        </Router>
      )}
    </>
  )
}

export default HomeRouter
