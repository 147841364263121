import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SuccessIcon from '@material-ui/icons/Check'

export default function AlertDialog(props) {
  const { close } = props;
  return (
    <div>

      <Dialog
        open={true}
        //onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Success"}
          <SuccessIcon style={{ color: 'green' }} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Veryfication email has been sent to the email address wich you have just provided, Please check it.
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Button onClick={close} color="primary" autoFocus variant="contained">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}