export default class Student {
  constructor(id, studentProfileObject) {
    //console.log('studentProfileObject', studentProfileObject)
    this.id = id
    this.firstName = studentProfileObject.firstName
    this.lastName = studentProfileObject.lastName
    this.aadharNumber = studentProfileObject.aadharNumber
    this.displayName = `${studentProfileObject.firstName} ${studentProfileObject.lastName}`
    this.address = studentProfileObject.address
    this.pincode = studentProfileObject.pincode
    this.country = studentProfileObject.country
    this.landmark = studentProfileObject.landmark
    this.state = studentProfileObject.state
    this.city = studentProfileObject.city
    this.district = studentProfileObject.district
    this.motherPhoneNumber = studentProfileObject.motherPhoneNumber
    this.fatherPhoneNumber = studentProfileObject.fatherPhoneNumber
    this.motherName = studentProfileObject.motherName
    this.fatherName = studentProfileObject.fatherName
    this.previousSchool = studentProfileObject.previousSchool
    this.isTransportBySchool = studentProfileObject.isTransportBySchool
    this.dob = studentProfileObject.dob
    this.imageUrl = studentProfileObject.imageUrl
    this.studentClass = studentProfileObject.studentClass
    this.nameParentCreated = studentProfileObject.nameParentCreated
  }

  returnStudentProfileObject() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      aadharNumber: this.aadharNumber,
      displayName: `${this.firstName} ${this.lastName}`,
      address: !!this.address ? this.address : '',
      pincode: !!this.pincode ? this.pincode : '',
      country: this.country,
      landmark: !!this.landmark ? this.landmark : '',
      state: this.state,
      city: !!this.city ? this.city : '',
      district: !!this.district ? this.district : '',
      motherPhoneNumber: !!this.motherPhoneNumber ? this.motherPhoneNumber : '',
      fatherPhoneNumber: !!this.fatherPhoneNumber ? this.fatherPhoneNumber : '',
      motherName: !!this.motherName ? this.motherName : '',
      fatherName: !!this.fatherName ? this.fatherName : '',
      dob: this.dob,
      imageUrl: this.imageUrl,
      studentClass: !!this.studentClass ? this.studentClass : '',
    }
  }
}
