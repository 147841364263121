import React, { useState, useContext, useEffect } from 'react';
import { DBContext } from '../../../controller/contexts/dbContext';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IconButton from '@material-ui/core/IconButton';
import CardActionArea from '@material-ui/core/CardActionArea';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import QuestionsIcon from '@material-ui/icons/Create';
import TimeIcon from '@material-ui/icons/Timer';
import DateRangeIcon from '@material-ui/icons/DateRange';
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';
import Badge from '@material-ui/core/Badge';
var moment = require('moment');
const ExamDisplayTile = ({ openToEdit, examData, deleteExam, openExamSubmissionsDialogue }) => {

  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  // const { fetchExamSubmissions
  // } = useContext(DBContext);
  // const [examSubmissions, setExamSubmissions] = useState(null);

  // useEffect(() => {

  //   !examSubmissions && fetchAndUpdateSubmissionData()
  // })
  // const fetchAndUpdateSubmissionData = async () => {
  //   // get the data fetched from the dbContext
  //   const submissionData = await fetchExamSubmissions(examData.id);
  //   setExamSubmissions([...submissionData]);
  // }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleEdit = (e) => {
    openToEdit(examData)
    handleClose()
  }
  const handleDelete = e => {
    deleteExam(examData.id)
    handleClose()
  }

  return (
    <ListItem alignItems="flex-start" className={classes.root}>
      <Card className={classes.root}>
        <CardHeader
          action={
            <div>
              <IconButton aria-label="settings-submissions"
                onClick={() => openExamSubmissionsDialogue(examData)}
              >
                <Badge badgeContent={examData.answerSubmissionNotificationNumber && examData.answerSubmissionNotificationNumber} max={999} color="secondary">
                  <AssistantPhotoIcon />
                </Badge>
              </IconButton>


              <IconButton aria-label="settings-exam" onClick={handleClick}>
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="simple-menu-table-exam"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleEdit(examData)}>Edit</MenuItem>
                <MenuItem className={classes.error} onClick={() => handleDelete(examData)}>Delete</MenuItem>
              </Menu>
            </div>


          }
          title={
            <Typography variant="h6">{examData.examTitle}</Typography>

          }
          subheader={`${moment(examData.createdAt).fromNow()}`}
        />

        <CardContent>
          <Grid container spacing={2} direction="row" justify="space-between"
            alignItems="center">
            <Grid item>
              <Grid container spacing={1} direction="row" alignItems="center">
                <QuestionsIcon />
                <Typography>{`${examData.questions.length} Questions`} </Typography>
              </Grid>

            </Grid>

            {
              // Shows the max allowed time if the test is timed
              examData.isTimed && <Grid item>
                <Grid container spacing={1} direction="row" alignItems="center">
                  <TimeIcon />
                  <Typography>{`${examData.maxTime} Mins`}</Typography>
                </Grid>

              </Grid>
            }
            {
              // Shows the time remining if the test is having due date
              examData.isExamEndDate && <Grid item>
                <Grid container spacing={1} direction="row" alignItems="center">
                  <DateRangeIcon />
                  <Typography>{`Ends ${moment().to(moment(examData.dueDate))}`}</Typography>
                </Grid>

              </Grid>
            }
          </Grid>

        </CardContent>
        <CardActions >
          {examData.selectedSubjects && examData.selectedSubjects.map((eachSubject) => <Chip size="small" label={eachSubject} />)}

          {examData.selectedClasses.map((eachClass) => <Chip variant="outlined" size="small" label={eachClass.classDisplayName} />)}

        </CardActions>

      </Card>

    </ListItem>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: 0,
  },
  error: {
    color: 'red'
  },
  padding: { paddingTop: 8 }
}));

export default ExamDisplayTile