import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import ReactPlayer from 'react-player';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import EyeIcon from '@material-ui/icons/RemoveRedEye'
import CommentsIcon from '@material-ui/icons/Forum';
import IconButton from '@material-ui/core/IconButton';
import CardActionArea from '@material-ui/core/CardActionArea';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Chip from '@material-ui/core/Chip';
import Badge from '@material-ui/core/Badge';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
var moment = require('moment');

const LessonTile = ({ lessonData, openEdit, deleteLesson, openLessonView, openCommentsView }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  //const preventDefault = (event) => event.preventDefault();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleEdit = (e) => {
    openEdit(lessonData)
  }
  const handleDelete = e => {
    deleteLesson(lessonData.id)
  }
  return (

    <Card className={classes.root}>
      <CardHeader
        action={
          <div>
            <IconButton aria-label="settings-submissions"
              onClick={() => openLessonView(lessonData)}>
              <Badge badgeContent={lessonData.viewsCount ? lessonData.viewsCount : 0} max={999} color="secondary">
                <EyeIcon />
              </Badge>
            </IconButton>

            <IconButton aria-label="settings-submissions"
              onClick={openCommentsView}>
              <Badge badgeContent={lessonData.commentsCount ? lessonData.commentsCount : 0} max={999} color="secondary">
                <CommentsIcon />
              </Badge>

            </IconButton>


            <IconButton aria-label="settings" onClick={handleClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu-table-lesson"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleEdit}>Edit</MenuItem>
              <MenuItem className={classes.error} onClick={handleDelete}>Delete</MenuItem>
            </Menu>
          </div>


        }
        title={lessonData.videoTitle}
        subheader={moment(lessonData.createdAt.toDate()).format('MMMM Do YYYY')}
      />

      <CardContent>
        {lessonData.type === 'pdf' ?
          <a style={{
            display: "table-cell",
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            padding: 160
          }} href={lessonData.lessonUrl} target="_blank">

            <Typography variant='h6'>View PDF</Typography>

          </a>

          : <ReactPlayer
            url={lessonData.videoUrl}
            controls={true}
          />


        }
        <Typography variant="body2" color="textSecondary" component="p">
          {lessonData.videoDiscreption}
        </Typography>
        <CardActionArea className={classes.padding}>
          <Chip size="small" label={lessonData.selectedSubject} />
          {lessonData.selectedClasses.map((eachClass) => <Chip variant="outlined" size="small" label={eachClass.classDisplayName} key={`${Math.random()}`} />)}

        </CardActionArea>


      </CardContent>

    </Card>


  );

}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  pdf: {
    marginRight: 16,


  },
  padding: {
    paddingTop: theme.spacing(1)
  },
  error: {
    color: 'red'
  }
}));

export default LessonTile;