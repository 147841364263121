import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../../controller/contexts/authContext'
import { DBContext } from '../../../controller/contexts/dbContext'
import SchoolCollegeEntryEdit from './schoolCollegeEntryEdit';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';



const cryptoRandomString = require('crypto-random-string');

const Settings = (props) => {
  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const {
    fetching, selectedProgramType, isNewProgramCreating,
  } = useContext(DBContext);


  useEffect(() => {
    console.log('selectedProgramType', selectedProgramType);
  })


return (

    <div className={classes.root}>
      {/* Displays loading screen if app fetching/updating DATA from the cloud */}
      <Backdrop className={classes.backdrop} open={fetching} >
        <CircularProgress color="inherit" />
      </Backdrop>

      <SchoolCollegeEntryEdit />
    </div>
  );


}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    marginTop: '8%',
    width: '100%',
    height: '100%'
  },
  main: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    height: '100%'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  image: {
    height: 200,
    width: 400,
  },
  video: {
    height: 200,
    width: 400,
  },
  schoolClasses: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
  addButton: {
    padding: theme.spacing.unit * 2,
  },
  textField: {
    width: '100%',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit / 2,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },

}))




export default Settings;