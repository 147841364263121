import React, { useState, useContext, useEffect } from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { DBContext } from '../../../controller/contexts/dbContext';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Chip from '@material-ui/core/Chip';
import ExamEvalutationDialogue from './examEvaluationDialogue';

import Button from '@material-ui/core/Button';

var moment = require('moment');
//moment(examToView.dueDate).format()           


const ViewExamSubmissionsPage = ({ close, examToView, }) => {
  const classes = useStyles();
  const { fetchExamSubmissions, submitWrittenExamEvaluation, fetching } = useContext(DBContext)
  const [index, setIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [examSubmissionsData, setExamSubmissionData] = useState(null);
  const [isExamEvaluationDialogueOpen, setExamEvaluationDialogueOpen] = useState(false);

  useEffect(() => {
    fetchAndUpdateSubmissionsData();
  }, [])

  const fetchAndUpdateSubmissionsData = async () => {

    return await fetchAndUpdateSubmissionData();

  }

  const fetchAndUpdateSubmissionData = async () => {
    setLoading(true)
    // get the data fetched from the dbContext
    const submissionData = await fetchExamSubmissions(examToView.id);
    console.log('submissionData', submissionData);

    setExamSubmissionData && setExamSubmissionData(submissionData);
    setLoading(false);
  }
  /**
  * We have used the virtual list to scale to many items
  */

  const returnWrittenExamsSubmissionsTiles = () => {
    if (examSubmissionsData === null || examSubmissionsData.length < 1) {
      return <Typography> No Exam Submissions Yet</Typography>
    }
    return (
      <div className={classes.table}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Student Name</StyledTableCell>
                <StyledTableCell align="right">Class</StyledTableCell>
                <StyledTableCell align="right">Answer Count</StyledTableCell>
                <StyledTableCell align="right">Score</StyledTableCell>
                <StyledTableCell align="right">Time</StyledTableCell>
                {examToView.type === 'WRITTEN' && <StyledTableCell align="right">Submission</StyledTableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {examSubmissionsData.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row.studentName}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.studyingClass.classDisplayName}</StyledTableCell>
                  <StyledTableCell align="right">{row.testRatio}</StyledTableCell>
                  <StyledTableCell align="right">{`${row.testPercentage}%`}</StyledTableCell>
                  <StyledTableCell align="right">{moment(row.examSubmittedAt).fromNow()}</StyledTableCell>
                  {examToView.type === 'WRITTEN' && <StyledTableCell align="right">{
                    <Button color="primary"
                      onClick={() => openExamEvaluationDialogue(index)}
                    >
                      View Submisson
                    </Button>
                  }</StyledTableCell>}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )
  }

  const returnMultipleChoiceExamsSubmissionsTiles = () => {
    if (examSubmissionsData === null || examSubmissionsData.length < 1) {
      return <Typography> No Exam Submissions Yet</Typography>
    }
    return (
      <div className={classes.table}>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="customized table1">
            <TableHead>
              <TableRow>
                <StyledTableCell>Student Name</StyledTableCell>
                <StyledTableCell align="right">Class</StyledTableCell>
                <StyledTableCell align="right">Answer Count</StyledTableCell>
                <StyledTableCell align="right">Score</StyledTableCell>
                <StyledTableCell align="right">Time</StyledTableCell>

              </TableRow>
            </TableHead>
            <TableBody>
              {examSubmissionsData.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row.studentName}
                  </StyledTableCell>
                  <StyledTableCell align="right">{row.studyingClass.classDisplayName}</StyledTableCell>
                  <StyledTableCell align="right">{row.testRatio}</StyledTableCell>
                  <StyledTableCell align="right">{`${row.testPercentage}`}</StyledTableCell>
                  <StyledTableCell align="right">{moment(row.examSubmittedAt).fromNow()}</StyledTableCell>

                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    )

  }
  const returnExamEvalutationDialogue = () => {
    console.log('returnHomeworkEvalutationDialogue');
    return (
      <>
        <ExamEvalutationDialogue
          close={closeExamEvaluationDialogue}
          examToView={examToView}
          examSubmissonData={examSubmissionsData[index]}
          open={isExamEvaluationDialogueOpen}
          saveEvaluation={saveEvaluation}

        />
      </>
    )
  }

  // save the evalutation data and close the dialogue
  const saveEvaluation = async (examId, submissonId, payload) => {
    setLoading(true);
    await submitWrittenExamEvaluation(examId, submissonId, { ...payload });
    await fetchAndUpdateSubmissionsData()
    setLoading(false);

  }
  const openExamEvaluationDialogue = (index) => {
    console.log('openHomeworkEvaluationDialogue', index);
    setIndex(index);
    setExamEvaluationDialogueOpen(true)
  }
  const closeExamEvaluationDialogue = () => {
    setExamEvaluationDialogueOpen(false)
  }


  return (
    <main className={classes.layout}>
      {isExamEvaluationDialogueOpen && returnExamEvalutationDialogue()}

      <Grid conatiner spacing={2} direction="column"
        justify="center"
        alignItems="center">
        <Grid item xs={12} sm={9} className={classes.paper}>
          <Grid conatiner spacing={2}
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Typography variant='h6' color="initial" className={classes.paddingTop} >{examToView.examTitle}</Typography>
            <Typography variant='subtitle2' color='textSecondary' >{`${examToView.createdAt}`}</Typography>
            <Typography variant='subtitle1' color='inherit' className={classes.paddingTop}>{examToView.dueDate ?
              `Due Date: ${examToView.dueDate}` : 'No due date'}</Typography>
            <div className={classes.paddingTop}>
              <Typography variant='subtitle1' color='inherit'>Subject :</Typography>

              {
                examToView.selectedSubjects.map((eachSubject) =>
                  <Chip size="small" label={eachSubject} key={`${Math.random()}`} />
                )
              }
            </div>
            <div className={classes.paddingTop}>

              <Typography variant='subtitle1' color='inherit'>Classes :</Typography>
              {
                examToView.selectedClasses.map((eachClass) =>
                  <Chip variant="outlined" size="small" label={eachClass.classDisplayName} />
                )
              }
            </div>


            <Typography variant='subtitle1'
              color='inherit'
              className={classes.paddingTop}
            >
              {examToView.isTimed ? `Max Time: ${examToView.maxTime}` : 'No max time'}
            </Typography>

            <Typography variant='subtitle1'
              color='primary' className={classes.paddingTop}
            >
              {`Total Submissions: ${examSubmissionsData ? examSubmissionsData.length : 0} / ${examToView && examToView.studentCount ? examToView.studentCount : 'undefind'}`}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid conatiner spacing={1} direction="column">
            <Grid item xs={12} sm={9}>
              {(loading || fetching) && <LinearProgress />}
              {
                examToView.type === 'WRITTEN' ?
                  returnWrittenExamsSubmissionsTiles() :
                  returnMultipleChoiceExamsSubmissionsTiles()
              }
              {/* {fetching && <LinearProgress />} */}
            </Grid>
          </Grid>
        </Grid>

      </Grid>
    </main>
  );
}
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#566573',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);



const useStyles = makeStyles((theme) => ({

  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(900 + theme.spacing(2) * 2)]: {
      width: 900,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paddingTop: {
    paddingTop: 8,
  },
  paper: {
    color: theme.palette.paper,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  submitButton: {
    margin: 8,
  },
  submit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  tollbarButton: {
    marginLeft: '70%',
  },
  table: {
    minWidth: 800,
  },

}));

export default ViewExamSubmissionsPage;  