import React, { useEffect, useState } from 'react';
import firebase from '../firebase';

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loading, setLoading] = useState(false);
    const auth = firebase.auth();

    // *** Auth API ***
    const doCreateUserWithEmailAndPassword = (email, password) =>
        auth.createUserWithEmailAndPassword(email, password);

    const doSignInWithEmailAndPassword = (email, password) =>
        auth.signInWithEmailAndPassword(email, password);

    const doPasswordReset = email => auth.sendPasswordResetEmail(email);

    const doPasswordUpdate = password =>
        auth.currentUser.updatePassword(password);

    const doSignOut = () => auth.signOut();

    useEffect(() => {
        setLoading(true)
        const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
            setCurrentUser(user)
            setLoading(false)
            user && console.log(user.displayName)
        });
        return () => unregisterAuthObserver();
    }, []);

    return (
        <AuthContext.Provider
            value={{
                currentUser, loading, auth, doSignOut, doCreateUserWithEmailAndPassword,
                doSignInWithEmailAndPassword, doPasswordReset, doPasswordUpdate,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}