import React, { useContext } from 'react';
import { DBContext } from '../../../controller/contexts/dbContext';
import Grid from '@material-ui/core/Grid';
import { Jutsu } from 'react-jutsu'

const LiveClassPreviewDialogue = (props) => {
  const { VCLASS_URL, selectedSchool } = useContext(DBContext);
  const { liveClassData, close } = props
  return (
    <Grid container md spacing={1}>

      <Jutsu
        subject={liveClassData.title}
        domain={VCLASS_URL}
        roomName={liveClassData.roomId}
        displayName={selectedSchool.schoolName}
        onMeetingEnd={() => close()}
        loadingComponent={<p>ʕ •ᴥ•ʔ Conference is loading ...</p>}
        onload={console.log('video loded')}
        containerStyles={{ width: '1080px', height: '720px' }}
      />

    </Grid>
  );
}

export default LiveClassPreviewDialogue;