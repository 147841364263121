import React, { useState, useContext, useEffect } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Hidden,
  Button,
  Drawer,
  CssBaseline,
  MenuList,
  MenuItem,
  ListItemText,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MenuIcon from '@material-ui/icons/Menu'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import HomeIcon from '@material-ui/icons/Home'
import NoticeboardIcon from '@material-ui/icons/Airplay'
import CalenderIcon from '@material-ui/icons/Today'
import StudentIcon from '@material-ui/icons/People'
import SettingsIcon from '@material-ui/icons/Settings'
import NewsIcon from '@material-ui/icons/Subscriptions'
import AssignmentIcon from '@material-ui/icons/Assignment'
import TimetableIcon from '@material-ui/icons/Update'
import AttendenceIcon from '@material-ui/icons/HowToReg'
import VideocamIcon from '@material-ui/icons/Videocam'
import ExamIcon from '@material-ui/icons/Spellcheck'
import { AuthContext } from '../../controller/contexts/authContext'
import { DBContext } from '../../controller/contexts/dbContext'
import { Offline } from 'react-detect-offline'
import MuiAlert from '@material-ui/lab/Alert'
import Snackbar from '@material-ui/core/Snackbar'
import LocalLibrary from '@material-ui/icons/LocalLibrary'

const drawerWidth = 240

const Layout = (props) => {
  const [mobileOpen, setMobileOpen] = useState(false)
  const { doSignOut } = useContext(AuthContext)
  const { allSchoolsData, schoolIndex, selectedSchool } = useContext(DBContext)
  const classes = useStyles()
  const {
    location: { pathname },
    children,
  } = props

  const [open, setOpen] = React.useState(true)

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
  }

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const logOut = () => {
    try {
      doSignOut()
    } catch (err) {
      console.log(err, 'reason for not logingout')
    }
  }

  const drawer = (
    <div>
      <Hidden smDown>
        <div className={classes.toolbar} />
      </Hidden>
      <MenuList>
        <MenuItem
          className={
            pathname === '/Home' ? classes.menuItemActive : classes.menuItem
          }
          component={Link}
          to="/Home"
        >
          <ListItemIcon className={classes.icon}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} primary="Home" />
        </MenuItem>

        {/* <MenuItem
          className={
            pathname === '/Noticeboard'
              ? classes.menuItemActive
              : classes.menuItem
          }
          component={Link}
          to="/Noticeboard"
        >
          <ListItemIcon className={classes.icon}>
            <NoticeboardIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            primary="Noticeboard"
          />
        </MenuItem> */}

        {/* <MenuItem
          className={
            pathname === '/Schedule' ? classes.menuItemActive : classes.menuItem
          }
          component={Link}
          to="/Schedule"
        >
          <ListItemIcon className={classes.icon}>
            <CalenderIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            primary="Calendar"
          />
        </MenuItem> */}

        <MenuItem
          className={
            pathname === '/Attendance'
              ? classes.menuItemActive
              : classes.menuItem
          }
          component={Link}
          to="/Attendance"
        >
          <ListItemIcon className={classes.icon}>
            <AttendenceIcon />
          </ListItemIcon>
          <ListItemText
            autoFocus
            classes={{ primary: classes.primary }}
            primary="Attendance"
          />
        </MenuItem>

        <MenuItem
          className={
            pathname === '/EdOnline' ? classes.menuItemActive : classes.menuItem
          }
          component={Link}
          to="/EdOnline"
        >
          <ListItemIcon className={classes.icon}>
            <NewsIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            primary="EdOnline"
          />
        </MenuItem>

        <MenuItem
          className={
            pathname === '/Homework' ? classes.menuItemActive : classes.menuItem
          }
          component={Link}
          to="/Homework"
        >
          <ListItemIcon className={classes.icon}>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            primary="Homework"
          />
        </MenuItem>

        <MenuItem
          className={
            pathname === '/Exams' ? classes.menuItemActive : classes.menuItem
          }
          component={Link}
          to="/Exams"
        >
          <ListItemIcon className={classes.icon}>
            <ExamIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            primary="Exams"
          />
        </MenuItem>

        <MenuItem
          className={
            pathname === '/Students' ? classes.menuItemActive : classes.menuItem
          }
          component={Link}
          to="/Students"
        >
          <ListItemIcon className={classes.icon}>
            <StudentIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            primary="Students"
          />
        </MenuItem>
        <MenuItem
          className={
            pathname === '/Courses' ? classes.menuItemActive : classes.menuItem
          }
          component={Link}
          to="/Courses"
        >
          <ListItemIcon className={classes.icon}>
            <LocalLibrary />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            primary="Courses"
          />
        </MenuItem>

        {/* <MenuItem
          className={
            pathname === '/Timeline' ? classes.menuItemActive : classes.menuItem
          }
          component={Link}
          to="/Timeline"
        >
          <ListItemIcon className={classes.icon}>
            <TimetableIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            primary="Timeline"
          />
        </MenuItem> */}

        {/* <MenuItem className={pathname === "/StaffMeetings" ? (classes.menuItemActive) : (classes.menuItem)} component={Link} to="/StaffMeetings">
          <ListItemIcon className={classes.icon}>
            <VideocamIcon />
          </ListItemIcon>
          <ListItemText classes={{ primary: classes.primary }} primary="Staff Meeting" />
        </MenuItem> */}

        <MenuItem
          className={
            pathname === '/Settings' ? classes.menuItemActive : classes.menuItem
          }
          component={Link}
          to="/Settings"
        >
          <ListItemIcon className={classes.icon}>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText
            classes={{ primary: classes.primary }}
            primary="Settings"
          />
        </MenuItem>
      </MenuList>
    </div>
  )

  return (
    <>
      <Offline>
        <Snackbar open={open} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            You are offline:No internet connection !
          </Alert>
        </Snackbar>
      </Offline>

      <CssBaseline />

      <div className={classes.root}>
        <AppBar className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              className={classes.navIconHide}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="inherit" noWrap>
              {!!selectedSchool
                ? `${selectedSchool.schoolName}`
                : 'Your School Name'}
            </Typography>
            {/* To make logout button push towords the left end  */}
            <div className={classes.grow} />

            <Button color="inherit" onClick={logOut}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Hidden mdUp>
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle} //for opening and closing the side bar
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden smDown implementation="css">
          <Drawer
            variant="permanent"
            open
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <main className={classes.content}>
          {/* <div className={classes.toolbar} /> */}

          {/* Other components in the center of the app goes here */}

          {children}
        </main>
      </div>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'absolute',
    display: 'flex',
    width: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    position: 'fixed',
    left: 0,
    top: 0,
  },
  grow: {
    flexGrow: 1,
  },
  navIconHide: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuItem: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& $primary, & $icon': {
        color: theme.palette.common.white,
      },
    },
    height: theme.spacing.unit * 5,
  },
  menuItemActive: {
    backgroundColor: theme.palette.primary.main,
    '& $primary, & $icon': {
      color: theme.palette.common.white,
    },

    height: theme.spacing.unit * 5,
  },
  primary: {
    fontSize: theme.spacing.unit * 2,
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    [theme.breakpoints.up('md')]: {
      position: 'fixed',
    },
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up('md')]: {
      marginLeft: drawerWidth,
    },
  },
  nested: {
    paddingLeft: theme.spacing.unit * 3,
  },
  alert: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}))

export default withRouter(Layout)
