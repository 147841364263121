import React, { useContext, useEffect, useState } from 'react'
import { DBContext } from '../../../controller/contexts/dbContext'
import {
  Box,
  Text,
  Button,
  InfiniteScroll,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHeader,
  CheckBox,
  Spinner,
} from 'grommet'
import { CircularProgress } from '@material-ui/core'
import { StatusGood, StatusGoodSmall } from 'grommet-icons'

const StudentViewForCourseRefferalView = ({ course }) => {
  const step = 10
  const {
    fetching,
    schoolStudents,
    reffardStudentListForCourse,
    fetchCourseRefferalList,
    addCourseReffaralStudentList,
    refferStudent,
  } = useContext(DBContext)

  const [reffaring, setReffaring] = useState([])
  const [refferdIds, setRefferdIs] = useState([])
  useEffect(() => {
    fetchCourseReffealStudentsList()
  }, [])

  // fetch school course reffarals list from the cloud
  // set the students list in the state
  const fetchCourseReffealStudentsList = async () => {
    const error = await fetchCourseRefferalList(course.id)
    if (error) {
      alert(error)
    }
    const idList = reffardStudentListForCourse.map(
      (eachStudent) => eachStudent.id,
    )

    if (idList.length > 0) {
      setRefferdIs([...idList])
    }
  }

  /**
   * 1) Prepare data by creating an obbect with an object with
   * id: student object
   */
  const handleReffering = async () => {
    let studetnList = []
    for (var i = 0; i < schoolStudents.length; i++) {
      if (reffaring.indexOf(schoolStudents[i].id) > -1) {
        const studentObj = schoolStudents[i].returnStudentProfileObject()
        studetnList.push({ ...studentObj })
      }
    }
    //NOTE: we are adding student details of already added studetns
    // frp, 'reffardStudentListForCourse' lsit
    const error = await addCourseReffaralStudentList(course.id, [
      ...studetnList,
      ...reffardStudentListForCourse,
    ])
    if (error) {
      return alert(error)
    }
    setReffaring([])
  }
  const renderRefferingView = () => {
    if (reffaring.length > 0) {
      return (
        <Box height="50px" flex direction="row" justify="between" pad="medium">
          <Text color="brand">{`${reffaring.length} ${
            reffaring.length === 1 ? 'Student' : 'Students'
          } Selected`}</Text>

          <Button
            label={`Reffer Selected`}
            onClick={handleReffering}
            disabled={fetching}
          />
        </Box>
      )
    }
  }

  // updated when checking and unchking the student
  // if the checking is true that means we need to add
  // that studetn to the reffering list
  // otherwise we need to remove the stuent from the list
  const updateReffering = (e, studentId) => {
    if (e.target.checked) {
      setReffaring([...reffaring, studentId])
    } else {
      const list = [...reffaring]
      const newList = list.filter((echId) => echId !== studentId)
      setReffaring([...newList])
    }
  }

  //Save refferal data to the cloud
  const refferStudentToTheCourse = async (student) => {
    if (refferdIds.indexOf(student.id) > -1) {
      return alert('Student already added')
    }
    const error = await refferStudent(course.id, student)

    if (error) {
      return alert(error)
    }
    await setRefferdIs([...refferdIds, { id: student.id, reffard: true }])
    console.log(refferdIds.length)
  }
  const renderStudentListTable = () => {
    return (
      <Box pad="small">
        {renderRefferingView()}
        <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope="col" border="bottom">
                Student Name
              </TableCell>

              <TableCell scope="col" border="bottom">
                Class
              </TableCell>
              <TableCell scope="col" border="bottom">
                Refferd
              </TableCell>
              <TableCell scope="col" border="bottom">
                Action
              </TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            <InfiniteScroll
              renderMarker={(maker) => (
                <TableRow>
                  <TableCell>{maker}</TableCell>
                </TableRow>
              )}
              scrollableAncestor="window"
              items={[...schoolStudents]}
              step={step}
            >
              {(student) => (
                <TableRow key={student.id}>
                  <TableCell>{student.displayName}</TableCell>
                  <TableCell>
                    {student.studentClass
                      ? `${student.studentClass.classDisplayName}`
                      : 'NaN'}
                  </TableCell>
                  <TableCell>
                    {refferdIds.indexOf(student.id) > -1 ? (
                      <StatusGood color="status-ok" />
                    ) : (
                      <StatusGoodSmall color="light-6" />
                    )}
                  </TableCell>
                  <TableCell>
                    <Button
                      disabled={refferdIds.indexOf(student.id) > -1}
                      label="Reffer"
                      size="small"
                      onClick={(e) => refferStudentToTheCourse(student)}
                    />
                    {/* <CheckBox
                      checked={reffaring.indexOf(student.id) > -1}
                      onChange={(e) => updateReffering(e, student.id)}
                      disabled={refferdIds.indexOf(student.id) > -1}
                    /> */}
                  </TableCell>
                </TableRow>
              )}
            </InfiniteScroll>
          </TableBody>
        </Table>
      </Box>
    )
  }

  // If there are no students in the school list,
  // we show the error message

  // if (fetching) {
  //   return (
  //     <Box flex justify="center" align="center" pad="large" margin="large">
  //       <CircularProgress />
  //     </Box>
  //   )
  // }
  if (!schoolStudents || schoolStudents.length < 1) {
    return (
      <Box flex justify="center" align="center" pad="large" margin="large">
        <Text>No Students are present for this school</Text>
      </Box>
    )
  }
  // Else return studet table
  return renderStudentListTable()
}

export default StudentViewForCourseRefferalView
