import React, { useState, useContext } from 'react'
import { DBContext } from '../../../controller/contexts/dbContext'
import { makeStyles } from '@material-ui/core/styles'
import Layout from '../../layout'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Selectors from '../../components/selectors'
import Backdrop from '@material-ui/core/Backdrop'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import CreateExam from '../../components/exams/createExam'
import Toolbar from '@material-ui/core/Toolbar'
import ExamIcon from '@material-ui/icons/NoteAddRounded'
import ExamDisplayTile from '../../components/exams/examDisplayTile'
import ListItem from '@material-ui/core/ListItem'
import { FixedSizeList } from 'react-window'
import Typography from '@material-ui/core/Typography'
import { useEffect } from 'react'
import ViewExamSubmissionsPage from '../../components/exams/viewExamSubmissionsPage'
import { Offline, Online } from 'react-detect-offline'
import { Box } from 'grommet'
import AccountVerificationError from '../../components/accoutVerificationError'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const Exams = () => {
  const classes = useStyles()
  const {
    fetching,
    allSchoolsData,
    schoolIndex,
    error,
    fetchExamsData,
    examsData,
    deleteExams,
    isVeryfied,
  } = useContext(DBContext)

  const [selectedClasses, setSelectedClasses] = useState([])
  const [selectedSubject, setSelectedSubject] = useState('')
  const [examToViewAndEdit, setExamToViewAndEdit] = useState(null)
  const [isExamCreatingDialogueOpen, setIsExamCreatingDialogueOpen] = useState(
    false,
  )
  const [
    isExamSubmissionsDialogueOpen,
    setIsExamSubmissionsDialogueOpen,
  ] = useState(false)
  const [examToViewSubmisssions, setExamToViewSubmisssions] = useState('')
 
  const [examSubmissions, setExamSubmissions] = useState(null)

  useEffect(() => {
    //fetchExamsData()
  }, [])

  const togleExamEntryDialogue = () => {
    setIsExamCreatingDialogueOpen(
      (isExamCreatingDialogueOpen) => !isExamCreatingDialogueOpen,
    )
  }
  const onCreateExamButtonPressed = () => {
    setExamToViewAndEdit(null)
    togleExamEntryDialogue()
  }

  const handleExamDelete = (examId) => {
    return deleteExams(examId)
  }

  // For opening Exam Edit dialogue
  const handleExamEdit = (examDataToEdit) => {
    setExamToViewAndEdit(examDataToEdit)
    setIsExamCreatingDialogueOpen(true)
  }

  const returnExamCreatingDialogue = () => {
    return (
      <>
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          aria-labelledby="customized-dialog-title-examEntry"
          open={isExamCreatingDialogueOpen}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              edge="end"
              onClick={togleExamEntryDialogue}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography>Close</Typography>
          </Toolbar>

          <DialogContent>
            <CreateExam
              togleExamEntryDialogue={togleExamEntryDialogue}
              examToedit={examToViewAndEdit}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }
  const returnExamSubmissionsViewDialogue = () => {
    return (
      <>
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          aria-labelledby="customized-dialog-title-examView"
          open={isExamSubmissionsDialogueOpen}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              edge="end"
              onClick={closeExamSubmissionsDialogue}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography>Close</Typography>
          </Toolbar>

          <DialogContent>
            <ViewExamSubmissionsPage
              close={closeExamSubmissionsDialogue}
              examToView={examToViewSubmisssions}
              examSubmissionsData={examSubmissions}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }

  // For opening of Exam submission dialogue
  const handleExamSubmissionsDialogueOpen = (examData, examSubmissionsData) => {
    console.log('handleExamSubmissionsDialogueOpen')
    setExamToViewSubmisssions(examData)
    setExamSubmissions(examSubmissionsData)
    setIsExamSubmissionsDialogueOpen(true)
  }

  // For closing of Exam submission dialogue
  const closeExamSubmissionsDialogue = () => {
    setExamToViewSubmisssions(null)
    setIsExamSubmissionsDialogueOpen(false)
  }

  /**
   * This is the row renderd by the table
   * which showes each list item
   * @param {*} props
   */
  function renderRow(props) {
    const { index, style } = props
    const examToShow = examsData[index]

    return (
      <ListItem button style={style} key={index + '~~' + Math.random()}>
        {/*  Render list item to show exam title and other details */}
        <ExamDisplayTile
          openToEdit={handleExamEdit}
          examData={examToShow}
          deleteExam={handleExamDelete}
          className={classes.tile}
          openExamSubmissionsDialogue={handleExamSubmissionsDialogueOpen}
        />
      </ListItem>
    )
  }
  /**
   * We have used the virtual list to scale to many items
   */
  const returnExamsList = () => {
    if (examsData.length < 1 && !fetching) {
      return <Typography> No Exam Created Yet</Typography>
    }
    if (fetching) {
      return <Typography> Fetching Data</Typography>
    }
    return (
      <div className={classes.tableRoot}>
        <FixedSizeList
          height={examsData.length * 200}
          itemSize={200}
          itemCount={examsData.length}
        >
          {renderRow}
        </FixedSizeList>
      </div>
    )
  }

  return (
    <>
      {isExamCreatingDialogueOpen && returnExamCreatingDialogue()}
      {isExamSubmissionsDialogueOpen && returnExamSubmissionsViewDialogue()}

      <div>
        <Backdrop className={classes.backdrop} open={fetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div>{error && alert(error)}</div>
      <Layout>
        <Container component="main" maxWidth="md">
          <CssBaseline />

          <Grid
            container
            spacing={4}
            direction="column"
            className={classes.root}
          >
            {/* For Uper selectors */}
            <Grid item xs={12} sm={10}>
              {/* // PLEASE ACCOUNT VERIFICATION HERE */}
              <AccountVerificationError />
              {/* <Grid
                container
                spacing={1}
                direction="row"
                justify="space-around"
                alignItems="center"
              >
                <Grid item>
                  <Selectors
                    updateClasses={setSelectedClasses}
                    updateSubject={setSelectedSubject}
                    selectedClasses={selectedClasses}
                    selectedSubject={selectedSubject}
                    selectedSchool={selectedSchool}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    startIcon={<ExamIcon />}
                    onClick={onCreateExamButtonPressed}
                  >
                    Add Exam
                  </Button>
                </Grid>
              </Grid> */}
            </Grid>

            {/* For lower table to show exams */}
            {/* <Grid item xs={12} sm={10}>
              <Grid container justify="center" alignItems="center">
                <Grid item xs={12} sm={10}>
                  {returnExamsList()}
                </Grid>
              </Grid>
            </Grid> */}
          </Grid>
        </Container>
      </Layout>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '8%',
    padding: theme.spacing(2),
    marginBottom: '2%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  padding: {
    padding: 12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
  tollbarButton: {
    marginLeft: 'auto',
  },
  tableRoot: {
    padding: 0,
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tile: {
    padding: 0,
  },
}))

export default Exams
