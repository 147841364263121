import React, { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent,DialogContentText,
  DialogTitle, Button, TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const StudentLocationEntry = (props) => {
  const [location, setLocation] = useState('');
  const { updateLocationDetails, closeLocationDialogue,isOpen } = props;
  const classes = useStyles();

  // send the Location name to the Settings 

  const updateLocation = () => {
    // Update the location in the settings page
    // and reset the state with empty 
    // and closes the dialogue 
    if (location !== '') {
      updateLocationDetails(location.trim().toUpperCase())
      console.log(location)
      setLocation('')
      close()
    }
  }
  const close = () => {
    closeLocationDialogue();
  }
  return (
    <>
    <Dialog
          open={isOpen}
          onClose={null}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth = {'sm'}
        >
          <DialogTitle id="alert-dialog-title">{"Add Locations where you get students from"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
                Pleas Add Locations
                
            </DialogContentText>

            
            <TextField
              id="outlined-subject"
              placeholder="Eg: Delhi-6, Bangalore-Marthahalli, Hydrabad-Koti ..."
              className={classes.textField}
              value={location}
              onChange={(event)=>setLocation(event.target.value)}
              margin="normal"
              variant="outlined"
              helperText="LOCATION"
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button onClick={updateLocation} color="primary" >
              Add
            </Button>
          </DialogActions>
        </Dialog>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  textField: {
    margin: 8,
    marginTop: 16,

  },
}));

export default StudentLocationEntry;