import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import placeholder from '../../assets/image-placeholder.jpg'
var moment = require('moment')

const StudentProfileReview = (props) => {
  const classes = useStyles()

  const {
    dob,
    studentProfileImageUrl,
    studentClass,
    firstName,
    lastName,
    address,
    city,
    pincode,
    country,
    landmark,
    stateName,
    fatherName,
    motherName,
    fatherPhoneNumber,
    motherPhoneNumber,
    isTransportBySchool,
    previousSchool,
    district,
    aadharNumber,
  } = props

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          {/* For Image */}
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Avatar
              alt="Icon"
              src={
                studentProfileImageUrl != null
                  ? studentProfileImageUrl
                  : placeholder
              }
              className={classes.bigAvatar}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* For First Name */}
          <Typography
            variant="caption"
            display="block"
            color="textSecondary"
            gutterBottom
          >
            FirstName
          </Typography>
          <Typography variant="body1" gutterBottom>
            {firstName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* For First Name */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            Last Name / Family Name
          </Typography>
          <Typography variant="body1" gutterBottom>
            {lastName}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {/* For Address Line 1 */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            Aadhar Card Number
          </Typography>
          <Typography variant="body1" gutterBottom>
            {aadharNumber}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          {/* For Address Line 1 */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            Addresss
          </Typography>
          <Typography variant="body1" gutterBottom>
            {address}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* For Address Line 1 */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            Landmark
          </Typography>
          <Typography variant="body1" gutterBottom>
            {landmark}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* For City */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            City/Village
          </Typography>
          <Typography variant="body1" gutterBottom>
            {city}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* For City */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            District
          </Typography>
          <Typography variant="body1" gutterBottom>
            {district}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* For State */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            State
          </Typography>
          <Typography variant="body1" gutterBottom>
            {stateName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* For Zip/Postal Code */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            Pincode/Zip
          </Typography>
          <Typography variant="body1" gutterBottom>
            {pincode}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* For Country */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            Country
          </Typography>
          <Typography variant="body1" gutterBottom>
            {country}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* For Date of birth */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            Date of Birth
          </Typography>
          <Typography variant="body1" gutterBottom>
            {moment(dob).format('dddd, MMMM Do YYYY')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* For Studyign class */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            Class Studying
          </Typography>
          <Typography variant="body1" gutterBottom>
            {studentClass.classDisplayName}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          {/* For Father Name */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            Father Name
          </Typography>
          <Typography variant="body1" gutterBottom>
            {fatherName}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          {/* For Father Name */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            Father's Phone Number
          </Typography>
          <Typography variant="body1" gutterBottom>
            {fatherPhoneNumber}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* For Mother Name */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            Mother Name
          </Typography>
          <Typography variant="body1" gutterBottom>
            {motherName}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* For Mother's Phone Number */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            Mother's Phone Number
          </Typography>
          <Typography variant="body1" gutterBottom>
            {motherPhoneNumber}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          {/* For Transportation */}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            Transportation
          </Typography>
          <Typography variant="body1" gutterBottom>
            {isTransportBySchool ? 'By School/ Collage' : 'By Self'}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          {/* For Previous School*/}
          <Typography
            variant="caption"
            color="textSecondary"
            display="block"
            gutterBottom
          >
            Previous School
          </Typography>
          <Typography variant="body1" gutterBottom>
            {previousSchool ? previousSchool.schoolName : ''}
          </Typography>
        </Grid>
      </Grid>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  bigAvatar: {
    display: 'flex',
    alignContent: 'center',
    width: 120,
    height: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}))

export default StudentProfileReview
