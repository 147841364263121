import React, { useState, useContext } from 'react'
import { DBContext } from '../../../controller/contexts/dbContext'
import { makeStyles } from '@material-ui/core/styles'
import Layout from '../../layout'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import VideoIcon from '@material-ui/icons/SlowMotionVideo'
import LessonTable from '../../components/edOnline/lessonTable'
import Selectors from '../../components/selectors'
import LessonEntryEditDialogue from '../../components/edOnline/lessonUploadEditComponent'
import Backdrop from '@material-ui/core/Backdrop'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Slide from '@material-ui/core/Slide'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import LessonViewsPage from '../../components/edOnline/viewLessonViewsPage'
import VideoCommentsDialogue from '../../components/edOnline/videoCommentsDialogue'
import LiveClassDialogue from '../../components/edOnline/liveClass'
import LiveClassPreviewDialogue from '../../components/edOnline/liveClassPreviewDialogue'
import AccountVerificationError from '../../components/accoutVerificationError'

const cryptoRandomString = require('crypto-random-string')
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const EdOnline = () => {
  const classes = useStyles()
  const {
    fetching,
    allSchoolsData,
    schoolIndex,
    error,
    createOnlineClass,
    endOnlineClass,
    selectedSchool,
  } = useContext(DBContext)
  ///const selectedSchool = allSchoolsData[schoolIndex]

  const [openLessonEntryDialogue, setOpenLessonEntryDialogue] = useState(false)
  const [selectedClasses, setSelectedClasses] = useState([])
  const [selectedSubject, setSelectedSubject] = useState('')
  const [lessonToBeEdited, setLessonToBeEdited] = useState(null)
  const [studentCount, setStudentCount] = useState(0)
  // For Lesion Views Dialogue
  const [
    lessonForLessonViewsDialogue,
    setLessonForLessonViewsDialogue,
  ] = useState(null)
  const [isLessonViewsDialogueOpen, setIsLessonViewsDialogueOpen] = useState(
    false,
  )
  // For Lesson Comments Dialogue
  const [lessonForCommets, setLessonForCommets] = useState(null)
  const [
    isLessonCommentsDialogueOpen,
    setIsLessonCommentsDialogueOpen,
  ] = useState(false)
  // for live classes
  const [isLiveDialogueOpen, setLiveDialogueOpen] = useState(false)
  const [roomName, setRoomName] = useState(null)
  const [liveClassDbUpdated, setLiveClassDbUpdated] = useState(false)

  // for live class preview
  const [
    isLiveClassPreviewDialogueOpen,
    setIsLiveClassPreviewDialogueOpen,
  ] = useState(false)
  const [liveClassToPreview, setLiveClassToPreview] = useState(null)

  const handleClose = () => {
    setOpenLessonEntryDialogue(false)
  }
  // for creating new lesson
  const openLessonEntry = () => {
    setOpenLessonEntryDialogue(true)
  }
  const updateStudentCount = (count) => {
    console.log('count form edonlie', count)
    setStudentCount(count)
  }

  // LIVE CLASSES
  // For live Lesion  Dialogue
  const openLiveClassDialogue = async () => {
    const roomNameString = cryptoRandomString({ length: 23 })
    setRoomName(roomNameString)
    setLiveDialogueOpen(true)
  }
  // For live class dialogue
  //Note: to prevent situations like, users closing
  // live view before they finished the class,
  // it will update the student database if it is
  // not been done in liveClass dialogue
  // so that kids will be notifyed that
  // the class is ended
  const closeLiveClassDialogue = async () => {
    try {
      await endOnlineClass(roomName)
      setRoomName(null)
      setLiveDialogueOpen(false)
      setLiveClassDbUpdated(false)
    } catch (error) {
      console.log('eror finishing online class', error)
      setRoomName(null)
      setLiveDialogueOpen(false)
      setLiveClassDbUpdated(false)
    }
  }

  const returnLiveClassDialogue = () => {
    return (
      <>
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          aria-labelledby="customized-dialog-title-olclass23829dss"
          open={isLiveDialogueOpen}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              edge="end"
              onClick={closeLiveClassDialogue}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography>Close</Typography>
          </Toolbar>

          <DialogContent>
            <LiveClassDialogue
              close={closeLiveClassDialogue}
              roomName={roomName}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }

  /***
   *  live class preview dialogue
   */

  const openLiveClassPreviewDialogue = (liveClassData) => {
    setLiveClassToPreview(liveClassData)
    setIsLiveClassPreviewDialogueOpen(true)
  }

  const closeLiveClassPreviewDialogue = () => {
    setIsLiveClassPreviewDialogueOpen(false)
  }

  const returnLiveClassPreviewDialogue = () => {
    return (
      <>
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          aria-labelledby="customized-dialog-title-olclass23829dss"
          open={isLiveClassPreviewDialogueOpen}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              edge="end"
              onClick={closeLiveClassPreviewDialogue}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography>Close</Typography>
          </Toolbar>

          <DialogContent>
            <LiveClassPreviewDialogue
              close={closeLiveClassPreviewDialogue}
              liveClassData={liveClassToPreview}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }

  // LessoN VIEWS
  // For Lesion Views Dialogue
  const openLessonViewsDialogue = (lessonData) => {
    setLessonForLessonViewsDialogue(lessonData)
    setIsLessonViewsDialogueOpen(true)
  }
  // For closing of lesion views dialogue
  const closeLessonViewsDialogue = () => {
    setLessonForLessonViewsDialogue(null)
    setIsLessonViewsDialogueOpen(false)
  }

  const returnLessonsViewDialogue = () => {
    return (
      <>
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          aria-labelledby="customized-dialog-title-examView23829"
          open={isLessonViewsDialogueOpen}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              edge="end"
              onClick={closeLessonViewsDialogue}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography>Close</Typography>
          </Toolbar>

          <DialogContent>
            <LessonViewsPage
              close={closeLessonViewsDialogue}
              lessonToView={lessonForLessonViewsDialogue}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }
  // COMMENTS
  // For comments Views Dialogue
  const openVideoCommentsDialogue = (lessonData) => {
    setLessonForCommets(lessonData)
    setIsLessonCommentsDialogueOpen(true)
  }
  // For closing of lesion views dialogue
  const closeVideoCommentsDialogue = () => {
    setLessonForCommets(null)
    setIsLessonCommentsDialogueOpen(false)
  }

  const returnVideoCommentsDialogue = () => {
    return (
      <>
        <Dialog
          fullScreen
          TransitionComponent={Transition}
          aria-labelledby="customized-dialog-title-examView23829dss"
          open={isLessonCommentsDialogueOpen}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              edge="end"
              onClick={closeVideoCommentsDialogue}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography>Close</Typography>
          </Toolbar>

          <DialogContent>
            <VideoCommentsDialogue
              close={closeVideoCommentsDialogue}
              lessonData={lessonForCommets}
            />
          </DialogContent>
        </Dialog>
      </>
    )
  }
  return (
    <>
      <div>
        {isLessonViewsDialogueOpen && returnLessonsViewDialogue()}
        {isLessonCommentsDialogueOpen && returnVideoCommentsDialogue()}
        {isLiveDialogueOpen && roomName && returnLiveClassDialogue()}
        {isLiveClassPreviewDialogueOpen && returnLiveClassPreviewDialogue()}

        <Backdrop className={classes.backdrop} open={fetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div>{error && alert(error)}</div>
      <Layout>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          {openLessonEntryDialogue && (
            <LessonEntryEditDialogue
              open={openLessonEntryDialogue}
              closeDialogue={handleClose}
              selectedSchool={selectedSchool}
              lessonData={lessonToBeEdited}
            />
          )}
          <Grid
            container
            spacing={2}
            direction="column"
            className={classes.root}
          >
            {/* For selecting class and subject */}
            {/* <AccountVerificationError /> */}
            <Grid item xs={12} sm={12}>
              <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-start"
                alignItems="flex-start"
              >
                <Grid item xs={12} sm={10}>
                  <Selectors
                    updateClasses={setSelectedClasses}
                    updateSubject={setSelectedSubject}
                    selectedClasses={selectedClasses}
                    selectedSubject={selectedSubject}
                    selectedSchool={selectedSchool}
                    studentCount={studentCount}
                    updateStudentCount={updateStudentCount}
                  />
                </Grid>

                <Grid item xs={6} sm={6}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    startIcon={<VideoIcon />}
                    onClick={openLiveClassDialogue}
                  >
                    Start Live Lesson
                  </Button>
                </Grid>
              </Grid>
              <Grid item xs={6} sm={6}>
                <Button
                  variant="outlined"
                  color="primary"
                  className={classes.button}
                  startIcon={<VideoIcon />}
                  onClick={openLessonEntry}
                >
                  Add Lesson
                </Button>
              </Grid>

              <Grid item xs={12} sm={9}>
                <LessonTable
                  openEditLessonEntry={openLessonEntry}
                  openLessonViewsDialogue={openLessonViewsDialogue}
                  openVideoCommentsDialogue={openVideoCommentsDialogue}
                  openLiveClassPreviewDialogue={openLiveClassPreviewDialogue}
                />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Layout>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '8%',
    padding: theme.spacing(2),
    marginBottom: '2%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  padding: {
    padding: 12,
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
    padding: 20,
    marginBottom: 8,
  },
  button: {
    margin: theme.spacing(2),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
}))

export default EdOnline
