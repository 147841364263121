export default class LiveClass {

  constructor(id, data) {
    this.id = id;
    this.createdAt = data.createdAt;
    this.roomId = data.roomId;
    this.selectedClasses = data.selectedClasses;
    this.selectedSubject = data.selectedSubject;
    this.baseUrl = data.baseUrl;
    this.status = data.status;
    this.title = data.title;
    this.createdBy = data.createdBy;
    this.schoolData = data.schoolData;
  }
}