export default class Comment {
    constructor(id, commentData) {
        this.id = id;
        this.createdAt = commentData.createdAt.toDate();
        this.studentName = commentData.studentName;
        this.studentId = commentData.studentId;
        this.commentText = commentData.commentText
        this.replyText = commentData.replyText;
        this.replyTime = commentData.replyTime != null ?
            commentData.replyTime.toDate() : null
        this.replyBy = commentData.replyBy;
    }
}