import React, { useEffect, useState, useContext } from "react";
import { DBContext } from '../../../controller/contexts/dbContext';
import { makeStyles } from '@material-ui/core/styles';
import LessonTile from './lessonTileComponent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import LessonEntryEditDialogue from './lessonUploadEditComponent';
import ListItem from '@material-ui/core/ListItem';
import { FixedSizeList } from 'react-window';
import List from '@material-ui/core/List';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import CardContent from '@material-ui/core/CardContent';
import CardActionArea from '@material-ui/core/CardActionArea';
import Chip from '@material-ui/core/Chip';
import Box from '@material-ui/core/Box';
import EyeIcon from '@material-ui/icons/RemoveRedEye'

var moment = require('moment');

const LessonTable = ({ selectedSchool,
  openLessonViewsDialogue,
  openVideoCommentsDialogue,
  openLiveClassPreviewDialogue,
}) => {

  const { fetching } = useContext(DBContext);
  const classes = useStyles();
  const {
    onlineLessonsData,
    fetchOnlineLessonVideosAndUpdateState,
    deleteOnlineClassVideo,
    liveClassesList,
    fetchLiveClasses

  } = useContext(DBContext);
  const [openLessonEditDialogue, setOpenLessonEditDialogue] = useState(false);
  const [lessonToBeEdited, setLessonToBeEdited] = useState(null);
  const [tabValue, setTabValue] = useState('lesson');


  useEffect(() => {
    fetchOnlineLessonVideosAndUpdateState();
  }, [])

  const openEditDiaglogue = (lesson) => {
    setLessonToBeEdited(lesson)
    setOpenLessonEditDialogue(true)
  }

  const handleClose = () => {
    setOpenLessonEditDialogue(false)
  }

  const deleteLesson = (lessonId) => {
    deleteOnlineClassVideo(lessonId)
  }

  const openComments = (lessonData) => {
    openVideoCommentsDialogue(lessonData)
  }

  /**
   * This is the row renderd by the table 
   * which showes each list item
   * @param {*} props 
   */
  function renderRow(props) {
    const { index, style } = props;
    const lessonToShow = onlineLessonsData[index];

    return (
      <ListItem button style={style} key={index + '~~' + Math.random()}>
        {/*  Render list item to show lesson title and other details */}
        <LessonTile
          key={`${Math.random()}+Video`}
          lessonData={lessonToShow}
          selectedSchool={selectedSchool}
          openEdit={openEditDiaglogue}
          deleteLesson={deleteLesson}
          openLessonView={openLessonViewsDialogue}
          openCommentsView={() => openComments(lessonToShow)}
        />

      </ListItem>
    );
  }

  /**
   * We have used the virtual list to scale to many items
   */
  const returnLessonList = () => {
    if (onlineLessonsData.length < 1 && !fetching) {
      return <Typography> No Exam Created Yet</Typography>
    }
    if (fetching) {
      return <Typography> Fetching Data</Typography>
    }
    return (
      <div className={classes.tableRoot}>


        <FixedSizeList height={onlineLessonsData.length * 600} itemSize={600} itemCount={onlineLessonsData.length}>
          {renderRow}
        </FixedSizeList>
      </div>
    )
  }
  // FOR LIVE CLASSES
  //Updates the tab value according to the tab data
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 'live') {
      // fetch live clases from the context
      fetchLiveClasses();
    }
  }

  // Render live classes list 
  const returnLiveClassesList = () => {
    if (fetching) {
      return <CircularProgress />
    }
    if (!fetching && (!liveClassesList || liveClassesList.length < 1)) {
      return <Typography> No Live classes created yet</Typography>
    }
    return (
      <List className={classes.tableRoot}>
        {liveClassesList.map((eachLiveClass) => (
          <Box width={1}>
            <ListItem style={{ width: '100%' }}>
              <Card style={{ width: '100%' }}>
                <CardHeader
                  action={
                    <div>
                      <IconButton aria-label="settings-submissions"
                        disabled={eachLiveClass.status === 'FINISHED'}
                        onClick={() => openLiveClassPreviewDialogue(eachLiveClass)} >
                        <EyeIcon />
                      </IconButton>

                    </div>


                  }
                  title={eachLiveClass.title}
                  subheader={moment(eachLiveClass.createdAt.toDate()).fromNow()}
                />

                <CardContent>

                  <Typography variant="body2" color="textSecondary" component="p">
                    {eachLiveClass.status === 'LIVE' ? <Chip size='medium' color='secondary' label='LIVE' /> : <Typography>FINISHED</Typography>}
                  </Typography>
                  <CardActionArea className={classes.padding}>
                    <Chip size="small" label={eachLiveClass.selectedSubject} />
                    {eachLiveClass.selectedClasses.map((eachClass) => <Chip
                      variant="outlined"
                      size="small"
                      label={eachClass.classDisplayName}
                      key={`${Math.random()}`}
                    />
                    )}

                  </CardActionArea>


                </CardContent>

              </Card>
            </ListItem>
          </Box>
        ))}
      </List>
    )
  }

  return (
    <>
      {openLessonEditDialogue && <LessonEntryEditDialogue
        open={openLessonEditDialogue}
        closeDialogue={handleClose}
        selectedSchool={selectedSchool}
        lessonData={lessonToBeEdited}
      />}
      <Grid container justify="center" alignItems="center">
        <Grid item xs={12} sm={12}>

          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="primary"
            centered
            style={{ background: '#D3D3D3', marginBottom: 12, padding: 4 }}
          >
            <Tab label="Lessons" value='lesson' />
            <Tab label="Live Classes" value='live' />
          </Tabs>
        </Grid>

        <Grid item xs={12} sm={12}>
          {tabValue === 'lesson' ? returnLessonList() : returnLiveClassesList()}
        </Grid>


      </Grid>

    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  padding: {
    paddingTop: theme.spacing(1)
  },
  error: {
    color: 'red'
  },
  tableRoot: {
    padding: 0,
    height: '100%',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default LessonTable;