import React, { useContext } from 'react';
import { DBContext } from '../../../controller/contexts/dbContext';
import Grid from '@material-ui/core/Grid';
import { Jutsu } from 'react-jutsu'

const MeetingRoomComponent = (props) => {
  const { VCLASS_URL, selectedSchool } = useContext(DBContext);
  const { meetigData, close, participantName } = props;
  return (
    <Grid container md spacing={1} justify='center' alignContent='center'>

      <Jutsu
        subject={meetigData.agenda}
        domain={VCLASS_URL}
        roomName={meetigData.roomId}
        displayName={participantName}
        onMeetingEnd={() => close()}
        loadingComponent={<p>ʕ •ᴥ•ʔ Conference is loading ...</p>}
        onload={console.log('video loded')}
        containerStyles={{ width: '1080px', height: '720px' }}
      />

    </Grid>
  );
}

export default MeetingRoomComponent;