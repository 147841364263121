import React, { useState, useEffect, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { DBContext } from '../../../controller/contexts/dbContext'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Selectors from '../../components/selectors'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import LiveIcon from '@material-ui/icons/LiveTv'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Jutsu } from 'react-jutsu' // Component

const LiveClassDialogue = ({ close, roomName }) => {
  const classes = useStyles()
  const {
    selectedSchool,
    schoolIndex,
    VCLASS_URL,
    createLiveClass,
    endLiveClass,
  } = useContext(DBContext)
  const [selectedClasses, setSelectedClasses] = useState([])
  const [selectedSubject, setSelectedSubject] = useState('')
  const [titleText, setTitleText] = useState(null)
  const [loading, setLoding] = useState(false)
  const [isLiveStarted, setIsLiveStarted] = useState(false)
  const [studentCount, setStudentCount] = useState(0)
  const [classEnded, setClassended] = useState(false)

  useEffect(() => {
    console.log('room Id', roomName)
    return () => {
      if (!classEnded) {
        console.log('from UseEffec')
        try {
          endClasss()
        } catch (error) {
          console.log('EndClass: not done:', error)
        }
      }
    }
  }, [roomName])

  const updateStudentCount = (count) => {
    //console.log('count form edonlie', count);
    setStudentCount(count)
  }

  const handleTextUpdate = (event) => {
    //console.log(event.target.value)
    setTitleText(event.target.value)
  }

  // callback when the school ended
  const endClasss = async () => {
    console.log('On end class called')
    setClassended(true)
    await endLiveClass(roomName)
  }

  const returnLiveClassComponent = () => {
    // component renderd doesent mean that
    // the video loaded
    // it will take some time to load libraries
    // so we are doing loding to true
    console.log('roomName', roomName)

    return (
      <div>
        <Grid container md spacing={1}>
          <Jutsu
            subject={titleText}
            domain={VCLASS_URL}
            roomName={roomName}
            displayName={selectedSchool.schoolName}
            onMeetingEnd={() => endClasss()}
            loadingComponent={<p>ʕ •ᴥ•ʔ Conference is loading ...</p>}
            onload={console.log('video loded')}
            containerStyles={{ width: '1080px', height: '720px' }}
          />
        </Grid>
      </div>
    )
  }

  const returnDetailsEntry = () => {
    return (
      <Grid container sm spacing={2} direction="column">
        <Grid item xs={12} sm={6}>
          <Selectors
            updateClasses={setSelectedClasses}
            updateSubject={setSelectedSubject}
            selectedClasses={selectedClasses}
            selectedSubject={selectedSubject}
            selectedSchool={selectedSchool}
            studentCount={studentCount}
            updateStudentCount={updateStudentCount}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={handleTextUpdate}
            value={titleText}
            id="outlined-textarea+jfdksjajklj09876"
            label="Title"
            placeholder="Title of the Lession"
            multiline
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.button}
            startIcon={<LiveIcon />}
            onClick={startLiveClass}
          >
            Go Live
          </Button>
        </Grid>
      </Grid>
    )
  }

  const startLiveClass = async () => {
    if (selectedClasses.length < 0 || selectedSubject === '') {
      return alert('Please select Classes and Subject')
    }
    if (roomName === null) {
      return alert(
        'Oops! something wrong happend, please close this window and reopen',
      )
    }
    if (titleText === '' || titleText.length < 10) {
      return alert('Please enter title more then 10 charectors long')
    }
    try {
      setLoding(true)
      await createLiveClass(
        roomName,
        titleText,
        selectedClasses,
        selectedSubject,
      )

      setIsLiveStarted(true)
      setLoding(false)
    } catch (error) {
      console.log('cant create video class', error)
      setLoding(false)
      alert(
        'Sorry, we are unable to start the class, please check your internet connection and try again.',
      )
    }
  }

  if (classEnded) {
    return 'CLASS ENDED'
  }
  return (
    <div className={classes.root}>
      {loading && <LinearProgress />}
      <Grid
        container
        md
        spacing={1}
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} className={classes.root}>
          {isLiveStarted ? returnLiveClassComponent() : returnDetailsEntry()}
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    marginTop: '1%',
    padding: theme.spacing(2),
    marginBottom: '2%',
  },
}))

export default LiveClassDialogue
