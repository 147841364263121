export default class Homework {
  constructor(id, homeworkData) {
    this.id = id;
    this.homeworkTitle = homeworkData.homeworkTitle;
    this.createdAt = homeworkData.createdAt;
    this.updatedAt = homeworkData.updatedAt;
    this.dateStringForSorting = homeworkData.dateStringForSorting;
    this.homeworkDiscreption = homeworkData.homeworkDiscreption;
    this.selectedSubject = homeworkData.selectedSubject;
    this.selectedClasses = homeworkData.selectedClasses;
    this.dueDate = homeworkData.dueDate.toDate();
    this.studentCount = homeworkData.studentCount;
    this.homeworkSubmissionsCount = homeworkData.homeworkSubmissionsCount;
    this.fileType = homeworkData.fileType;
    this.fileUrl = homeworkData.fileUrl;
  }
}