import React, { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, Grid,MenuItem,
  DialogTitle,  Button, TextField,DialogContentText
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const classNamesList = ['PLAY-CLASS','LKG','UKG','1','2','3','4','5', '6', '7', '8', '9', '10','11','12' ]
const sectionsList = ['A', 'B', 'C', 'D', 'E', 'F','G', 'H', 'I','J', 'K', 'L','M','N','O','P','Q','R','S']

const ClassEntryDialogue = (props) => {
  const [schoolClassName, updateSchoolClassName] = useState('');
  const [sectionSymbol, updateSectionSymbol] = useState('');
  const [classDisplayName, updateClassDisplayName] = useState('');
  const classes = useStyles();
  const { isOpen, updateClassDetails, closeClassEntryDialogue } = props

  const updateClass = () => {
    if (schoolClassName !== '' && sectionSymbol !== '') {
      updateClassDetails({
        'id': schoolClassName.toUpperCase() + sectionSymbol.toUpperCase(),
        'schoolClassName': schoolClassName.trim().toUpperCase(),
        'sectionSymbol': sectionSymbol.trim().toUpperCase(),
        'classDisplayName': classDisplayName.length > 1 ? classDisplayName.toUpperCase() : schoolClassName.toUpperCase() + '-' + sectionSymbol.toUpperCase()
      })
    }
    return close();
  }

  const close = () => {
    closeClassEntryDialogue();
  }

  return (
    <>
    <Dialog
          open={isOpen}
          onClose={null}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth = {'sm'}
        >
          <DialogTitle id="alert-dialog-title">{"Add Classes in the school"}</DialogTitle>
          <DialogContent>
          <DialogContentText>
                Select CLASS NUMBER(eg: 1, 2, 3..) or CLASS NAME(eg: L.K.G, U.K.G ) and SECTION(eg:A, B, C ...) if you have and you can give NickName(eg: STARS) to the Section which is optional
                
          </DialogContentText>

            <Grid container
              direction="row"
              justify="space-around"
              alignItems="center"
              >
                <Grid item >
                  
                  <TextField
                    id="standard-select-class"
                    select
                    label="Select Class"
                    margin="normal"
                    value={schoolClassName}
                    onChange={(event)=>updateSchoolClassName(event.target.value)}
                    helperText="Please select class"
                  >
                    {classNamesList.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item >
                  
                  <TextField
                    id="standard-select-section"
                    select
                    margin="normal"
                    label="Select Section"
                    value={sectionSymbol}
                    onChange={(event)=>updateSectionSymbol(event.target.value)}
                    helperText="Please select section"
                  >
                    {sectionsList.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>

                <Grid item >
                  <TextField
                    id="outlined-NickName"
                    placeholder={schoolClassName + '-' + sectionSymbol}
                    className={classes.textField}
                    value={classDisplayName}
                    onChange={(event)=>updateClassDisplayName(event.target.value)}
                    margin="normal"
                    variant="outlined"
                    helperText="Class Disply Name(OPTIONAL)"
                  />
                </Grid>

            </Grid>
            

            
          </DialogContent>
          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button onClick={updateClass} color="primary" >
              Add
            </Button>
          </DialogActions>
        </Dialog>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  textField: {
    margin: 8,
    marginTop: 16,

  },
}));

export default ClassEntryDialogue;