import React, { useState, useContext, useEffect } from 'react';
import { DBContext } from '../../../controller/contexts/dbContext';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Image from 'material-ui-image';
import Selectors from '../selectors';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { DateTimePicker } from '@material-ui/pickers';
import { PREVENT_COURS_URL } from '../../../controller/helpers/consts';

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const cryptoRandomString = require('crypto-random-string');
var moment = require('moment');
//TO PREVENT COURSE




const HomeworkEntryEdit = ({ open, closeDialogue, homeworkData, selectedSchool }) => {
  const classes = useStyles();
  // File reader to get the file data 
  const fileReader = new FileReader();
  const { fetching, createHomework, updateHomework, SCHOOL_HOMEWORK_FILES_BUCKET } = useContext(DBContext);
  const [homeworkTitle, setHomeworkTitle] = useState('');
  const [homeworkDiscreption, setHomeworkDiscreption] = useState('');
  // default date is next date
  const [dueDate, setDueDate] = useState(moment()
    .add(1, 'd')
    .toDate());
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [studentCount, setStudentCount] = useState(0);
  // NEW for supporting file upload
  const [fileData, setFileData] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [fileType, setFileType] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(' homeworkData is: ', homeworkData);
    if (!!homeworkData) {
      setHomeworkTitle(homeworkData.homeworkTitle);
      setHomeworkDiscreption(homeworkData.homeworkDiscreption);
      setDueDate(homeworkData.dueDate);
      setSelectedClasses(homeworkData.selectedClasses);
      setSelectedSubject(homeworkData.selectedSubject);
      setStudentCount(homeworkData.studentCount);
      // new 
      setFileData(homeworkData.fileData);
      setFileUrl(homeworkData.fileUrl);
      setFileType(homeworkData.fileType);
    }
  }, [])

  const updateDueDate = (date) => {
    console.log('the Date ids', date);
    setDueDate(date.toDate());
  }
  const handlePostHomework = async () => {
    if (!homeworkTitle || !homeworkDiscreption || !dueDate || !selectedClasses || !selectedSubject) {
      return alert('Please fill all the details')
    }

    // If we have the data to upload
    if (!!fileData) {
      setLoading(true);
      const fileName = cryptoRandomString({ length: 15 })
      // storage ref
      const fileStorageRef = SCHOOL_HOMEWORK_FILES_BUCKET.child(selectedSchool.id).child(fileName);

      // save file data
      try {
        // put the file in storage
        await fileStorageRef.put(fileData);
        // get the url 
        const fileUrl = await fileStorageRef.getDownloadURL();
        // get the url for the data uploaded
        const payload = {
          homeworkTitle, homeworkDiscreption, dueDate, selectedClasses, selectedSubject,
          dateStringForSorting: moment(dueDate).format('DD/MM/yyyy'), studentCount,
          fileType, fileName, fileUrl,
        }
        console.log('Homework Payload', payload);
        //stop loading 
        setLoading(false);
        createHomework({ ...payload })
        return closeDialogue();

      } catch (error) {
        //stop loading 
        setLoading(false);
        console.log('Error Submitting homeowrk:', error);
        return alert('Error Submitting Homework, Please try again!')
      }
    }
    // if we are saving otherthen the files
    else if (!!homeworkData) {
      const payload = {
        homeworkTitle, homeworkDiscreption, dueDate, selectedClasses, selectedSubject,
        dateStringForSorting: moment(dueDate).format('DD/MM/yyyy'), studentCount,

      }
      updateHomework({ ...payload }, homeworkData.id)
      return closeDialogue();
    }

    // the last option is crating homework without any files
    // save the homework without file
    const payload = {
      homeworkTitle, homeworkDiscreption, dueDate, selectedClasses, selectedSubject,
      dateStringForSorting: moment(dueDate).format('DD/MM/yyyy'), studentCount,

    }
    console.log('Homework Payload', payload);
    //stop loading 
    setLoading(false);
    createHomework({ ...payload })
    return closeDialogue();


  }

  const handleFileSelect = ({ target }) => {

    //const fileReader = new FileReader();
    //const name = target.accept.includes('image') ? 'images' : 'videos';
    if (target !== null && target.files[0] !== null) {

      fileReader.onload = (e) => {
        console.log(target.files[0].type);
        var extension = target.files[0].type;
        // The file reader gives us an ArrayBuffer:
        let buffer = e.target.result;
        // We have to convert the buffer to a blob:
        let FileBlob = new Blob([new Uint8Array(buffer)]);
        console.log('file type:', extension);
        //console.log('PDF BLOB', FILEBlob);
        // The blob gives us a URL to the video file:
        // let url = URL.createObjectURL(videoBlob);
        // console.log('url', url,);
        //le t base64String = btoa(String.fromCharCode(...new Uint8Array(e.target.result)));
        setFileData(FileBlob);
        extension === 'application/pdf' ? setFileType('pdf') : setFileType('image');
        //URL.revokeObjectURL(url)
      };

      //fileReader.readAsDataURL(target.files[0]);
      fileReader.readAsArrayBuffer(target.files[0]);
    }
  }

  return (
    <>
      <Dialog onClose={closeDialogue} aria-labelledby="customized-dialog-title-homework" open={open}>
        <DialogTitle id="customized-dialog-title-homework" onClose={closeDialogue}>
          {!!homeworkData ? <>
            <Typography variant="h5">Update Homework</Typography>
            <Typography variant="body2" color="textSecondary">NOTE: You can't chage className and subject</Typography>
          </> : 'Create Homework'
          }
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3} className={classes.padding} >
            <Grid item xs={12} sm={10}>
              <Selectors updateClasses={setSelectedClasses}
                updateSubject={setSelectedSubject}
                selectedSchool={selectedSchool}
                selectedClasses={selectedClasses}
                selectedSubject={selectedSubject}
                studentCount={studentCount}
                updateStudentCount={setStudentCount}
                noEdit
              />

            </Grid>
            <Grid item xs={12} sm={9} >

              <div>
                <DateTimePicker
                  fullWidth
                  variant='dialog'
                  id="date-picker-inline-HOMEWORK"
                  ampm={false}
                  label="Select Homework Due Date"
                  value={dueDate}
                  onChange={updateDueDate}
                  onError={console.log}
                  disablePast
                  format="dddd, MMMM Do YYYY, h:mm:ss"

                />

              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3} direction="column" >
            <Grid item xs={12} sm={9} className={classes.padding}>
              {
                // if vidoe uploading in progress, it will show circler progress
                fileReader.readyState === 1 && CircularProgress()
              }
              {(!!fileData || !!fileUrl) && <>
                {fileType === 'pdf' ? <>

                  {!!fileData ? <Document
                    file={fileData}
                  // onLoadSuccess={onDocumentLoadSuccess}
                  >
                    <Page
                      key={`page_${1}`}
                      pageNumber={1}
                    />

                  </Document> : <div className={classes.pdf}>
                      <Document
                        file={PREVENT_COURS_URL + fileUrl}
                      >
                        <Page pageNumber={1} />
                      </Document>
                    </div>

                  }

                </> : <div className={classes.image}>
                    {
                      // if the file is an image
                      !!fileData ? <Image src={URL.createObjectURL(fileData)} /> : <Image src={fileUrl} />

                    }


                  </div>}

              </>}

            </Grid>

          </Grid>
          <div className={classes.padding}>
            <Grid container spacing={3} direction="column" >
              <Grid item xs={12} sm={9}>
                <div className={classes.padding}>
                  <input
                    accept="image/*,application/pdf"
                    className={classes.input}
                    id="icon-pdf-upload-button-file"
                    type="file"
                    onChange={handleFileSelect}
                  />
                  <label htmlFor="icon-pdf-upload-button-file">
                    <Button
                      component="span"
                      className={classes.button}
                      startIcon={<AttachFileIcon />}
                      color='primary'
                      disabled={!!homeworkData}
                    >
                      Select File
                    </Button>
                  </label>
                </div>
              </Grid>

              {/* For Entering  Homework Titile*/}
              <Grid item xs={12} sm={9}>
                <TextField
                  required
                  id="outlined-required-Homework Titile"
                  label="Homework Title"
                  variant="outlined"
                  value={homeworkTitle}
                  onChange={(e) => setHomeworkTitle(e.target.value)}
                  fullWidth
                />

              </Grid>
              {/* For Entering   Discreption*/}
              <Grid item xs={12} sm={9}>
                <TextField
                  required
                  id="outlined-required-homework-discreption"
                  label="Descreption"
                  variant="outlined"
                  value={homeworkDiscreption}
                  onChange={(e) => setHomeworkDiscreption(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>

            </Grid>
          </div>
        </DialogContent>
        <DialogActions>
          {(loading || fetching) ? <CircularProgress /> : <Button variant="contained" onClick={handlePostHomework} color="primary">
            {!!homeworkData ? 'Update Homework ' : 'Post Homework'}
          </Button>}
        </DialogActions>
      </Dialog>

    </>);
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '8%',
    padding: theme.spacing(2),
    marginBottom: '2%',

  },
  padding: {
    padding: 12,
  },
  image: {
    padding: theme.spacing(1),
    minWidth: 550,
    minHeight: 600,
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',/* Player ratio: 100 / (1280 / 720) */
    padding: 20,
    marginBottom: 8,
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  pdf: { width: "80%", height: "100%" },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
}));


export default HomeworkEntryEdit;