import React, { useState, useContext, useEffect } from 'react';
import { DBContext } from '../../../controller/contexts/dbContext';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import ReactPlayer from 'react-player';
import { validURL } from '../../../controller/helpers';
import Selectors from '../selectors';
import Icon from '@material-ui/core/Icon';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import firebase from '../../../controller/firebase';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const cryptoRandomString = require('crypto-random-string');


const LessionUploadEditComponent = ({ open, closeDialogue, lessionData, selectedSchool }) => {
  const classes = useStyles();
  // File reader to get the file data 
  const fileReader = new FileReader();

  const { updateOnlineLesson,
    createOnlineLesson, fetching,
    SCHOOL_ONLINE_CLASS_VIDEOS_BUCKET,
    SCHOOL_ONLINE_CLASS_PDF_BUCKET,
  } = useContext(DBContext)
  const [lessonTitle, setLessonTitle] = useState('');
  const [lessonDiscreption, setLessonDiscreption] = useState('');
  //const [urlEntryError, setUrlEntryError] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState('');
  const [videoData, setVideoData] = useState(null);
  const [pdfData, setPdfData] = useState(null);
  const [videoUploadingEror, setVideoUploadingError] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [studentCount, setStudentCount] = useState(0);
  const [progress, setProgress] = useState(null);
  const [type, setType] = useState('video');

  // to change file type 
  const handleTypeChange = (event) => {
    setType(event.target.value);
  };

  useEffect(() => {
    console.log('lessionData is: ', lessionData);
    if (!!lessionData) {
      setLessonTitle(lessionData.lessonTitle)
      setLessonDiscreption(lessionData.lessonDiscreption)
      setVideoUrl(lessionData.videoUrl)
      setSelectedClasses(lessionData.selectedClasses)
      setSelectedSubject(lessionData.selectedSubject)
      setStudentCount(lessionData.studentCount)
    }
  }, [])


  const handlePostLesson = () => {
    if (!lessonTitle || !lessonDiscreption || !selectedClasses || !selectedSubject || !type) {
      return alert('Please enter all details');
    }

    if (!!videoData || !!pdfData) {
      const fileName = cryptoRandomString({ length: 15 })
      //start progress indicator
      setIsUploading(true);
      // Upload file and metadata to the object 'images/mountains.jpg'
      let lessonStorageRef;
      if (type === 'video') {
        lessonStorageRef = SCHOOL_ONLINE_CLASS_VIDEOS_BUCKET.child(selectedSchool.id).child(fileName);
      } else {
        lessonStorageRef = SCHOOL_ONLINE_CLASS_PDF_BUCKET.child(selectedSchool.id).child(fileName);
      }


      var uploadTask;
      if (type === 'video') {
        uploadTask = lessonStorageRef.put(videoData);
      } else {
        uploadTask = lessonStorageRef.put(pdfData);
      }



      // Listen for state changes, errors, and completion of the upload.
      uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          //console.log('Upload is ' + progress + '% done');
          setProgress(Math.round(progress));
          switch (snapshot.state) {
            case firebase.storage.TaskState.PAUSED: // or 'paused'
              console.log('Upload is paused');
              break;
            case firebase.storage.TaskState.RUNNING: // or 'running'
              console.log('Upload is running');
              break;
            default:
              break;
          }
        }, function (error) {
          console.log('Error while uploading file to cloud storage', error);
          setIsUploading(false)
          setProgress(null);
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;

            case 'storage/canceled':
              // User canceled the upload
              break;

            case 'storage/unknown':
              // Unknown error occurred, inspect error.serverResponse
              break;
            default:
              break;
          }
        }, function () {
          setIsUploading(false)
          setProgress(null);
          // url = await lessonStorageRef.getDownloadURL();
          // console.log('video url', url);
          // setIsUploading(false)
          // Upload completed successfully, now we can get the download URL
          lessonStorageRef.getDownloadURL().then(function (downloadURL) {
            console.log('File available at', downloadURL);
            const payload = {
              'videoTitle': lessonTitle, 'videoDiscreption': lessonDiscreption,
              type,
              lessonTitle, lessonDiscreption, fileName,
              selectedClasses, selectedSubject,
              studentCount,
            }

            if (type === 'video') {
              createOnlineLesson({ ...payload, 'videoUrl': downloadURL.toString() })
            } else {
              createOnlineLesson({ ...payload, 'lessonUrl': downloadURL.toString() })
            }

            return closeDialogue()
          });
        });
    }
    // For already uploaded videos if we change the metadata
    else if (!!lessionData) {
      // NOTE: For upload we don't allow to change classes, lesson type and 
      // so no need to update spectific to type
      setIsUploading(false)
      const payload = {
        'videoTitle': lessonTitle, 'videoDiscreption': lessonDiscreption,
        lessonTitle, lessonDiscreption, studentCount,
        selectedClasses, selectedSubject,
      }

      updateOnlineLesson({ ...payload }, lessionData.id)
      return closeDialogue()
    } else {
      setIsUploading(false)
      return alert('Please selet VIDEO/PDF');
    }

  }



  // select the file from input and reads the file 
  // with file reader and if video upload finished 
  // it will post set data using setVideoData() function  
  const handleCapture = ({ target }) => {
    //const fileReader = new FileReader();
    //const name = target.accept.includes('image') ? 'images' : 'videos';
    if (target !== null && target.files[0] !== null) {

      fileReader.onload = (e) => {
        console.log(target.files[0].name);
        // The file reader gives us an ArrayBuffer:
        let buffer = e.target.result;
        // We have to convert the buffer to a blob:
        let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });
        // The blob gives us a URL to the video file:
        // let url = URL.createObjectURL(videoBlob);
        // console.log('url', url,);
        //le t base64String = btoa(String.fromCharCode(...new Uint8Array(e.target.result)));
        setVideoData(videoBlob);
        //URL.revokeObjectURL(url)
      };

      //fileReader.readAsDataURL(target.files[0]);
      fileReader.readAsArrayBuffer(target.files[0]);
    }

  };

  //"application/pdf"
  // select the file from input and reads the file 
  // with file reader and if video upload finished 
  // it will post set data using setVideoData() function  
  const handlePDFCapture = ({ target }) => {
    //const fileReader = new FileReader();
    //const name = target.accept.includes('image') ? 'images' : 'videos';
    if (target !== null && target.files[0] !== null) {

      fileReader.onload = (e) => {
        console.log(target.files[0].name);
        // The file reader gives us an ArrayBuffer:
        let buffer = e.target.result;
        // We have to convert the buffer to a blob:
        let PDFBlob = new Blob([new Uint8Array(buffer)], { type: "application/pdf" });
        console.log('PDF BLOB', PDFBlob);
        // The blob gives us a URL to the video file:
        // let url = URL.createObjectURL(videoBlob);
        // console.log('url', url,);
        //le t base64String = btoa(String.fromCharCode(...new Uint8Array(e.target.result)));
        setPdfData(PDFBlob);
        //URL.revokeObjectURL(url)
      };

      //fileReader.readAsDataURL(target.files[0]);
      fileReader.readAsArrayBuffer(target.files[0]);
    }

  };


  return (
    <div >

      <Dialog onClose={closeDialogue}
        aria-labelledby="customized-dialog-title-video456"
        open={open}>
        {/* Shows progress Indication when the file is uploading  */}
        {isUploading && <LinearProgress color='secondary' />}

        <DialogTitle id="customized-dialog-title-video +%6"
          onClose={closeDialogue}>
          {!!lessionData ? <>
            <Typography variant="h5">Update Lesson</Typography>
            <Typography variant="body2" color="textSecondary">NOTE: You can't chage className and subject</Typography>
          </> : <>
              <Typography variant="h5">Upload Lesson</Typography>
              <Typography variant="body2" color="textSecondary">You can send this lesson to varius classes/groups</Typography>

            </>
          }
          <div className={classes.padding}></div>
          {isUploading && <Typography color='error' variant='body1'> Video is being upload, Please dont close this window... {progress && `Uploding ${progress}%`} </Typography>}
        </DialogTitle>

        <DialogContent dividers className={classes.root}>
          <Grid container spacing={2} sm={9}>
            <Grid item xs={12} sm={9}>
              <Selectors updateClasses={setSelectedClasses}
                updateSubject={setSelectedSubject}
                selectedSchool={selectedSchool}
                selectedClasses={selectedClasses}
                selectedSubject={selectedSubject}
                studentCount={studentCount}
                updateStudentCount={setStudentCount}
              />
            </Grid>
            <Grid item xs={12} sm={12}>

              <FormControl component="fieldset">
                <FormLabel component="legend"><Typography>Lesson Type</Typography>  </FormLabel>
                <RadioGroup row aria-label="Lesson Type" name="Lesson Type" value={type} onChange={handleTypeChange}>
                  <FormControlLabel value="video" control={<Radio />} label="Video" />
                  <FormControlLabel value="pdf" control={<Radio />} label="Pdf" />
                </RadioGroup>
              </FormControl>

            </Grid>
            <Grid item xs={12} sm={9}>
              {type === 'video' ? <>
                {
                  // if vidoe uploading in progress, it will show circler progress
                  fileReader.readyState === 1 && CircularProgress()
                }

                {/* For Showing Video */}
                {(videoData || videoUrl) && <div className={classes.playerWrapper}>

                  <ReactPlayer
                    className={classes.reactPlayer}
                    url={videoData ? videoData : videoUrl}
                    width='100%'
                    height='100%'
                    controls
                  />
                </div>
                }
              </> : <>
                  {
                    // if pdf processing in progress, it will show circler progress
                    fileReader.readyState === 1 && CircularProgress()
                  }
                  {(!!pdfData || !!pdfUrl) && <div className={classes.playerWrapper}>
                    <Document
                      file={!!pdfData ? pdfData : pdfUrl}
                    // onLoadSuccess={onDocumentLoadSuccess}
                    >
                      <Page
                        key={`page_${1}`}
                        pageNumber={1}
                      />

                    </Document>
                  </div>

                  }

                </>
              }
            </Grid>
          </Grid>
          <div className={classes.padding}>
            <Grid container spacing={3} direction="column" >

              {/* For Selecting video file*/}
              <Grid item xs={12} sm={9}>
                {type === 'video' ? <>
                  <input
                    accept="video/*"
                    className={classes.input}
                    id="icon-video-upload-button-file"
                    type="file"
                    onChange={handleCapture}
                  />
                  <label htmlFor="icon-video-upload-button-file">
                    <Button
                      component="span"
                      variant="contained"
                      className={classes.button}
                      startIcon={<CloudUploadIcon />}
                    >
                      {!!lessionData ? 'Update Video' : 'Select Video'}
                    </Button>
                  </label>
                </> : <>
                    {/* For uploading PDF files */}
                    <input
                      accept="application/pdf"
                      className={classes.input}
                      id="icon-pdf-upload-button-file"
                      type="file"
                      onChange={handlePDFCapture}
                    />
                    <label htmlFor="icon-pdf-upload-button-file">
                      <Button
                        component="span"
                        variant="contained"
                        className={classes.button}
                        startIcon={<CloudUploadIcon />}
                      >
                        {!!lessionData ? 'Update PDF' : 'Select PDF File'}
                      </Button>
                    </label>


                  </>}

                {/* <TextField
                  required
                  id="outlined-required-video-url"
                  label="Video URL"
                  variant="outlined"
                  value={videoUrl}
                  onChange={handleURLEntry}
                  fullWidth
                />
                {urlEntryError ?
                  <Typography variant="caption" color='error'>
                    Please Upload Valid Video Url Link
                  </Typography> :
                  <Typography variant="caption" color='textSecondary'>
                    * Please upload your video to YouTube and past the video link here.
                  </Typography>
                } */}
              </Grid>
              {/* For Entering  Video title*/}
              <Grid item xs={12} sm={9}>
                <TextField
                  required
                  id="-required-video-title"
                  label="Title"
                  variant="outlined"
                  value={lessonTitle}
                  onChange={(e) => setLessonTitle(e.target.value)}
                  fullWidth
                />
              </Grid>
              {/* For Entering  Video Discreption*/}
              <Grid item xs={12} sm={9}>
                <TextField
                  required
                  id="outlined-required-video-discreption"
                  label="Descreption"
                  variant="outlined"
                  value={lessonDiscreption}
                  onChange={(e) => setLessonDiscreption(e.target.value)}
                  fullWidth
                  multiline
                  rows={4}
                />
              </Grid>

            </Grid>
          </div>
        </DialogContent>
        <DialogActions>

          {progress ? <CircularProgress /> :

            <Button variant="contained"
              onClick={handlePostLesson}
              color="primary"
              disabled={fetching}
            >
              {!!lessionData ? 'Update Lesson ' : 'Post Lesson'}
            </Button>}

        </DialogActions>
      </Dialog>

    </div>
  )
}


const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    minWidth: 550,
    marginTop: '8%',
    padding: theme.spacing(2),
    marginBottom: '2%',

  },
  padding: {
    padding: 12,
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%',/* Player ratio: 100 / (1280 / 720) */
    padding: 16,
    marginBottom: 8,
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,

  },
  pdf: {
    height: '10vh',
  },
  videoCard: {
    maxHeight: 340,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
}));

export default LessionUploadEditComponent;


 // const handleURLEntry = e => {
  //   const url = e.target.value
  //   if (validURL(url)) {
  //     setVideoUrl(url)
  //     setUrlEntryError(false)
  //   } else {
  //     setUrlEntryError(true)
  //     setVideoUrl(url)
  //   }

  // }

  // const handlePostVideo = async () => {
  //   if (!videoTitle || !videoDiscreption || !selectedClasses || !selectedSubject) {
  //     return alert('Please enter all details');
  //   }

  //   let url = null;
  //   const fileName = cryptoRandomString({ length: 15 })
  //   if (videoData !== null) {

  //     setIsUploading(true)
  //     try {
  //       // NOTE: We store every video under SCHOOL ID so that if the school got deleted, 
  //       // we can also delete all schools along with it
  //       const lessonStorageRef = SCHOOL_ONLINE_CLASS_VIDEOS_BUCKET.child(selectedSchool.id).child(fileName)
  //       console.log('file name is', fileName);

  //       //Upload video and get URL

  //       await lessonStorageRef.put(videoData)
  //       //await lessonStorageRef.putString(videoData, 'base64')


  //       url = await lessonStorageRef.getDownloadURL();
  //       console.log('video url', url);
  //       setIsUploading(false)

  //     } catch (error) {
  //       console.log('Error while uploading video to cloud storage', error);
  //       setIsUploading(false)
  //     }
  //   }

  //   let payload;
  //   if (url !== null) {
  //     // we have added student count when we first posting
  //     // the video  

  //     payload = {
  //       videoTitle, videoDiscreption, fileName,
  //       selectedClasses, selectedSubject, 'videoUrl': url.toString(),
  //       studentCount
  //     }


  //     //console.log('the payload is ', payload);
  //   } else {
  //     payload = {
  //       videoTitle, videoDiscreption, studentCount,
  //       selectedClasses, selectedSubject, videoUrl
  //     }
  //   }



  //   if (!!lessionData) {
  //     updateOnlineClassVideo({ ...payload }, lessionData.id)
  //     return closeDialogue()
  //   } else {
  //     createOnlineClassVideo({ ...payload })
  //     return closeDialogue()
  //   }
  // }