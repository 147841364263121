import React, { useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Typography } from '@material-ui/core';
import { DBContext } from '../../../controller/contexts/dbContext';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

import { Divider } from '@material-ui/core';

const CommentTile = ({ commentData, lessonData }) => {

  const [replyOpen, setReplyOpen] = useState(false);
  const [answer, setAnswer] = useState(null);

  const classes = useStyles();
  const { postAnswerToQuestion, getLessonComments } = useContext(DBContext);

  const postAnswer = async () => {
    //console.log('the answer is ', answer, 'lesson data', lessonData);
    try {
      await postAnswerToQuestion(lessonData.id, commentData.id, answer)
      setAnswer(null)
      setReplyOpen(false)
      getLessonComments(lessonData.id)
    } catch (error) {
      alert(error)
    }



  }
  const handleChange = (event) => {
    setAnswer(event.target.value);
  }


  return (
    <div>
      <Grid container direction='column' justify="flex-start" spacing={0}>
        <Grid item xs={12} sm={9}>
          <Paper className={classes.question}>
            <Typography variant="subtitle2" gutterBottom>
              {commentData.studentName}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {commentData.createdAt.toString()}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {commentData.commentText}
            </Typography>
            {(!commentData.replyText || commentData.replyText.length < 1) && <Button color="primary"
              className={classes.button}
              onClick={
                () => setReplyOpen(replyOpen => !replyOpen)
              }
            >
              Reply
          </Button>}
          </Paper>
        </Grid>
        <Grid item xs={12} sm={9}>
          {replyOpen && <Paper className={classes.answerEntry}>
            <TextField
              id="outlined-multiline-reply=tjklj"
              label="Answer"
              multiline
              rows={2}
              variant="outlined"
              onChange={handleChange}
              fullWidth
            />
            <Button color="primary"
              variant="contained"

              onClick={
                postAnswer
              }
            >
              Post
            </Button>
          </Paper>}
        </Grid>
        <Grid item xs={12} sm={9}>
          {commentData.replyText != null && commentData.replyText.length > 1 && <Paper className={classes.answer}>
            <Typography variant="subtitle2" gutterBottom>
              {commentData.replyBy}
            </Typography>
            <Typography variant="caption" display="block" gutterBottom>
              {commentData.replyTime.toString()}
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
              {commentData.replyText}
            </Typography>
          </Paper>}
        </Grid>
        <Grid item xs={12} sm={9}>
          <Divider />
        </Grid>

      </Grid>

    </div>
  );
}
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',

  },
  question: {
    minHeight: 50,
    width: '100%',
    padding: 8,
  },
  answer: {
    marginLeft: 50,
    padding: 8,
    marginTop: 4,
    marginBottom: 4,
    backgroundColor: '#DCDCDC',
    minHeight: 70,

  },
  answerEntry: {
    minHeight: 50,
    width: '100%',
  },
  button: {
    fontSize: 10,
    marginLeft: 0,
  }
}));

export default CommentTile;