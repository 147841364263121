import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select';

const StudentOtherDetails = (props) => {
  const {
    isTransportBySchool, setIsTransportBySchool,
    previousSchool, setPreviousSchool,
  } = props;
  const classes = useStyles();
  return (
    <>

      <Grid container spacing={3} alignItems='center'>
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.formControl}>
            <InputLabel id="select-transportation-$">Transportation</InputLabel>
            <Select
              fullWidth
              labelId="class-name-label"
              id="class-name-selecting-id"
              value={isTransportBySchool}
              onChange={(event) => setIsTransportBySchool(event.target.value)}
            >

              <MenuItem key={'YES$*'} value={true} fullWidth>
                Yes
                </MenuItem>
              <MenuItem key={'NO$#'} value={false} fullWidth>
                No
                </MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={previousSchool}
            id="previousSchool"
            name="previousSchool"
            label="PreviousSchool"
            fullWidth
            autoComplete="previousSchool"
            onChange={(event) => setPreviousSchool(event.target.value)}
          />
        </Grid>
      </Grid>
    </>
  );
}

const useStyles = makeStyles(theme => ({

  formControl: {
    margin: theme.spacing(2),
    minWidth: 200,
  },

}))

export default StudentOtherDetails;