import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import { green, red } from '@material-ui/core/colors';
import {
  Avatar,
  TableCell,
  TableRow,
  Text,
} from 'grommet';
var moment = require('moment');

/**
 * Customized check boxes 
 * To show present and absent
 */
const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(Checkbox);

const RedCheckbox = withStyles({
  root: {
    color: red[400],
    '&$checked': {
      color: red[600],
    },
  },
  checked: {},
})(Checkbox);

const AttendanceTile = (props) => {
  const { student, studentAttendance, udateAttendance, loading, date } = props;

  /**
   * Update student attendance in the cloud
   * and update attendance data
   * @param {Object} event 
   */
  const changeAttendance = (event) => {
    if (event.target.checked) {
      if (event.target.name === 'Present') {
        udateAttendance(student, true);
      } else {
        udateAttendance(student, false);
      }
    }
  }

  return (
    <TableRow key={`${Math.random()}`} background='red'>
      <TableCell scope="row">
        <Avatar src={student.imageUrl} />
      </TableCell>
      <TableCell scope="row">
        {student.displayName}
      </TableCell>
      <TableCell scope="row">
        {student.studentClass.classDisplayName}
      </TableCell>
      <TableCell scope="row">
        <GreenCheckbox
          checked={!!studentAttendance && studentAttendance.isPresent === true}
          onChange={changeAttendance}
          disabled={!!studentAttendance || loading || moment(date) > moment(new Date())}
          name="Present"
        />
      </TableCell>
      <TableCell scope="row">
        <RedCheckbox
          checked={!!studentAttendance && studentAttendance.isPresent === false}
          onChange={changeAttendance}
          disabled={!!studentAttendance || loading || moment(date) > moment(new Date())}
          name="Absent"
        />
      </TableCell>
    </TableRow>
  )
}
export default AttendanceTile;