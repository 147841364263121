export default class CourseProfile {
  constructor(id, courseProfileData) {
    this.id = id
    this.courseId = courseProfileData.courseId
    this.title = courseProfileData.title
    this.shortDescription = courseProfileData.shortDescription
    this.bannerUrl = courseProfileData.bannerUrl
      ? courseProfileData.bannerUrl
      : ''
    this.description = courseProfileData.description

    this.whatWillYouLearn = courseProfileData.whatWillYouLearn
    this.courseIncludes = courseProfileData.courseIncludes
    this.price = courseProfileData.price
    this.subjects = courseProfileData.subjects ? courseProfileData.subjects : []
    this.orgData = courseProfileData.orgData
  }
}
