import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const StudentsParentDetails = (props) => {
  const {
    fatherName, setFatherName,
    motherName, setMotherName,
    fatherPhoneNumber, setFatherPhoneNumber,
    motherPhoneNumber, setMotherPhoneNumber,
  } = props;

  const [mPhoneError, setMPhoneError] = useState(false)
  const [fPhoneError, setFPhoneError] = useState(false)

  const validateMPnoneNumberAndUpdateState = (phoneNumber) => {
    if (phoneNumber.length === 10) {
      setMPhoneError(false)
      setMotherPhoneNumber(phoneNumber)
    } else {
      setMPhoneError(true)
      setMotherPhoneNumber(phoneNumber)
    }
  }

  const validateFPnoneNumberAndUpdateState = (phoneNumber) => {
    if (phoneNumber.length === 10) {
      setFPhoneError(false)
      setFatherPhoneNumber(phoneNumber)
    } else {
      setFPhoneError(true)
      setFatherPhoneNumber(phoneNumber)
    }
  }
  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            onChange={(event) => setFatherName(event.target.value)}
            value={fatherName}
            id="Father Name"
            name="fatherName"
            label="Father Name"
            fullWidth
            autoComplete="fatehername"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            value={motherName}
            onChange={(event) => setMotherName(event.target.value)}
            id="mothername"
            name="Mother Name"
            label="Mother's Name"
            fullWidth
            autoComplete="mothername"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={fPhoneError}
            value={fatherPhoneNumber}
            onChange={(event) => validateFPnoneNumberAndUpdateState(event.target.value)}
            id="fatherphoneNumber"
            name="fatherphoneNumber"
            label="Father's Phone Number"
            fullWidth
            autoComplete="fatherphoneNumber"
            helperText={fPhoneError ? 'Phone Number must be 10 digits' : 'Please Enter 10 digit mobile Number'}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            required
            error={mPhoneError}
            value={motherPhoneNumber}
            onChange={(event) => validateMPnoneNumberAndUpdateState(event.target.value)}
            id="motherphoneNumber"
            name="motherphoneNumber"
            label="Mother's Phone Number"
            fullWidth
            autoComplete="motherphoneNumber"
            helperText={mPhoneError ? 'Phone Number must be 10 digits' : 'Please Enter 10 digit mobile Number'}
          />
        </Grid>


      </Grid>
    </>
  );
}

export default StudentsParentDetails;