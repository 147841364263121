import React from 'react'
import { Box, Text, Image } from 'grommet'
import Typography from '@material-ui/core/Typography'

const CourseProifleView = ({ course }) => {
  return (
    <Box align="start" padding="small">
      {/* Upper row area */}
      <Box direction="row" gap="medium">
        <Box
          height="150px"
          width="250px"
          background="light-6"
          flex="shrink"
          round="small"
        >
          <Image fit="cover" src={course.bannerUrl} sizes="small"></Image>
        </Box>
        <Box height="150px" width="large" justify="start">
          <Typography variant="h5" gutterBottom>
            {course.title}
          </Typography>
          <Typography variant="body1">{course.shortDescription}</Typography>
          <Box pad="small" direction="row" gap="small">
            <Text color="neutral-1">
              Cost: <span>&#8377;</span> {`${course.price}`}
            </Text>
            <Text size="small" color="dark-5">
              Per Student/Month
            </Text>
          </Box>
        </Box>
      </Box>

      {/* Subjects area */}
      <Box margin={{ top: 'medium' }}>
        <Typography variant="h6">Subjects/Topcs:</Typography>
        <Box margin={{ top: 'small' }}>
          {course.subjects &&
            course.subjects.length > 0 &&
            course.subjects.map((eachSubject) => (
              <Typography variant="body1">{`- ${eachSubject}`}</Typography>
            ))}
        </Box>
      </Box>

      {/* What will studetns learn  */}
      <Box margin={{ top: 'medium' }}>
        <Typography variant="h6">
          What will students learn from this course:
        </Typography>
        <Box margin={{ top: 'small' }}>
          {course.whatWillYouLearn &&
            course.whatWillYouLearn.length > 0 &&
            course.whatWillYouLearn.map((item) => (
              <Typography variant="body1">{`- ${item}`}</Typography>
            ))}
        </Box>
      </Box>

      {/* Course Includes */}
      <Box margin={{ top: 'medium' }}>
        <Typography variant="h6">Course Includes:</Typography>
        <Box margin={{ top: 'small' }}>
          {course.courseIncludes &&
            course.courseIncludes.length > 0 &&
            course.courseIncludes.map((item) => (
              <Typography variant="body1">{`. ${item}`}</Typography>
            ))}
        </Box>
      </Box>

      {/* Course Descreption */}
      <Box margin={{ top: 'medium' }}>
        <Typography variant="h6">Course Descreption:</Typography>
        <Box margin={{ top: 'small' }}>
          {course.description && (
            <Typography variant="body1">{`${course.description}`}</Typography>
          )}
        </Box>
      </Box>
      {/* Organization Data */}
      <Box margin={{ top: 'medium' }}>
        <Typography variant="h6">Created By:</Typography>
        <Box margin={{ top: 'small' }}>
          {course.orgData && (
            <Typography variant="subtitle2">{`${course.orgData.name}`}</Typography>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default CourseProifleView
