import React from 'react';
import { AuthProvider } from "./controller/contexts/authContext";
import { DBContextProvider } from './controller/contexts/dbContext';
import HomeRouter from './controller/routers/homeRouter';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { hot } from 'react-hot-loader/root';
import { Grommet } from 'grommet';
import { grommet } from 'grommet/themes';
function App() {
  return (
    <div >
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AuthProvider>
          <DBContextProvider>
            <Grommet full theme={grommet}>
              <HomeRouter />
            </Grommet>

          </DBContextProvider>
        </AuthProvider>
      </MuiPickersUtilsProvider>
    </div>
  );
}

export default hot(App);
