// -Show  Loading indicator screen while  fetching
// -Fetch data from DBContext and set state there 
// Show the student count and other Dashboard elements 
//  

import React, { useState, useEffect, useContext } from 'react';
import { DBContext } from '../../../controller/contexts/dbContext';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../../layout';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import Selectors from '../../components/selectors'
import Backdrop from '@material-ui/core/Backdrop';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

const Home = () => {
  const classes = useStyles();
  const {
    fetching, allSchoolsData, schoolIndex, error,
    fetchSchoolStudents, schoolStudents,selectedSchool

  } = useContext(DBContext);
  //let selectedSchool = allSchoolsData[schoolIndex]

  useEffect(() => {
    // fetch students data,
    if(selectedSchool){
      fetchSchoolStudents(selectedSchool.id)
    }
   
    // fetch other data 
    // daily logs 
    // todo list 
    // show that anyone having birthday
  }, [])
  return (
    <div>
      <div>
        <Backdrop className={classes.backdrop} open={fetching} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div>
        {error && alert(error)}
      </div>
      <Layout>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          <Grid container
            spacing={2}
            direction='row'
            justify="space-between"
            alignItems="stretch"
            className={classes.root}>
            {/* For Left side items */}
            <Grid item xs={12} sm={8}>
              <Grid container
                spacing={3}
                direction='column'
                justify="space-between"
                alignItems="flex-start"
              >
                <Grid container
                  spacing={3}
                  direction='row'
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item xs={6} sm={5}>
                    <Paper variant='elevation' className={classes.paperBox}>
                      <Typography variant="h4" gutterBottom>
                        {schoolStudents ? schoolStudents.length : '0'}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        Total Students
                      </Typography>

                    </Paper>
                  </Grid>
                  <Grid item xs={6} sm={5}>
                    <Paper variant='elevation' className={classes.paperBox}>
                      <Typography variant="h5" gutterBottom>
                        {'TODO'}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        1. Create Lession Videos
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
                <Grid container
                  spacing={6}
                  direction='row'
                  justify="flex-start"
                  alignItems="flex-end"
                >
                  <Grid item xs={6} sm={5}>
                    <Paper variant='elevation' className={classes.paperBox}>
                      <Typography variant="h4" gutterBottom>
                        {'20K'}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        Fee Collection Today
                      </Typography>
                    </Paper>
                  </Grid>
                  <Grid item xs={6} sm={5}>
                    <Paper variant='elevation' className={classes.paperBox}>

                      <Typography variant="h5" gutterBottom>
                        {'Today Schedule'}
                      </Typography>
                      <Typography variant="subtitle1" gutterBottom>
                        1. Create Lession Videos
                      </Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
            {/* For Right side lengthy dail tasks done list  */}
            <Grid item xs={12} sm={4}>
              <Paper variant='elevation' className={classes.paperFullHeight}>
                <Typography variant="h5" gutterBottom>
                  {'Tasks'}
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  Things done today
                      </Typography>
              </Paper>

            </Grid>
          </Grid>

        </Container>

      </Layout>
    </div>
  );
}



const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '8%',
    padding: theme.spacing(1),
    marginLeft: 8,
    marginBottom: '2%',

  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  padding: {
    padding: 12,
  },
  paperFullHeight: {
    padding: theme.spacing(2),
    textAlign: 'center',
    height: '100%',
    color: theme.palette.text.secondary,
  },
  paperBox: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    color: theme.palette.text.secondary,
    minHeight: 200,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
}));

export default Home;