import React, { useState, useContext, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { AuthContext } from '../../../controller/contexts/authContext'
import { DBContext } from '../../../controller/contexts/dbContext'
import Layout from '../../layout'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import LinearProgress from '@material-ui/core/LinearProgress'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Backdrop from '@material-ui/core/Backdrop'
import Typography from '@material-ui/core/Typography'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Checkbox from '@material-ui/core/Checkbox'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import Input from '@material-ui/core/Input'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'
import { DatePicker } from '@material-ui/pickers'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/CloseOutlined'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Toolbar from '@material-ui/core/Toolbar'
import { FixedSizeList } from 'react-window'
import ListItem from '@material-ui/core/ListItem'
import Schedule from '../../../model/models/schedule'
import AccountVerificationError from '../../components/accoutVerificationError'

var moment = require('moment')

const Calender = () => {
  const classes = useStyles()
  const { selectedSchool, fetching } = useContext(DBContext)
  const [selectedClass, setSelectedClass] = useState(null)
  const [selectedDate, setSelectedDate] = useState(new Date())
  const [schedules, setSchedules] = useState([1, 2, 3])

  // useEffect(() => {
  //   populateDummySchedules()
  // }, []);

  const handleClassUpdate = (e) => {
    console.log('classUpdate')
    setSelectedClass(e.target.value)
  }

  const handleUpdateDate = (date) => {
    setSelectedDate(date.toDate())
  }

  const populateDummySchedules = () => {
    const tomorrow = moment().add(1, 'd')
    const list = [1, 2, 3, 4, 5]

    list.forEach((each) =>
      setSchedules((schs) => [
        ...schs,
        new Schedule(each, {
          schoolId: '12345',
          createdAt: new Date(),
          createdBy: {},
          selectedClasses: selectedSchool.schoolClasses.slice(1, 4),
          selectedSubject: 'MATHS',
          type: 'LIVE CLASS',
          message: 'ATTEND LIVE CLASS',
          startingTime: tomorrow.toDate(),
          endingTime: tomorrow.add(1, 'h'),
          schoolData: {},
        }),
      ]),
    )
  }

  const returnScheduleEntryEditDialogue = () => {
    return <></>
  }

  const returnScheduleList = () => {
    if (setSchedules.length < 1 && !fetching) {
      return <Typography> No Schedules</Typography>
    }
    if (fetching) {
      return <Typography> Fetching Data</Typography>
    }
    return (
      <div className={classes.tableRoot}>
        <FixedSizeList
          height={schedules.length * 200}
          itemSize={200}
          itemCount={schedules.length}
        >
          {renderRow}
        </FixedSizeList>
      </div>
    )
  }

  /**
   * This is the row renderd by the table
   * which showes each list item
   * @param {*} props
   */
  function renderRow(props) {
    const { index, style } = props
    const scheduleToShow = schedules[index]

    return (
      <ListItem button style={style} key={index + '~~' + Math.random()}>
        {/*  Render list item to show exam title and other details */}
        <Typography>Schedule</Typography>
      </ListItem>
    )
  }

  return (
    <Layout>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        <Grid container spacing={2} direction="column" className={classes.root}>
          <AccountVerificationError />

          {/* <Grid item xs={12} sm={12}>
            <Grid container spacing={1}
              direction="row"
              justify='space-between'
              alignItems="center"
              className={classes.p8}
            >
              <Typography variant='h4' >Calender</Typography>
              <Button variant='contained' color='primary'>+ Add Schedule</Button>
            </Grid>

          </Grid>
          <Grid item xs={12} sm={12}>
            <Paper className={classes.selectors}>
              <Grid container spacing={1}
                direction="row"
                justify='space-between'
                alignItems="center"
              >
                <Grid item sm={6} xs={6} className={classes.selectorItems}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id={`${Math.random()}`}>Select Classe</InputLabel>
                    <Select
                      labelId={`${Math.random()}`}
                      id={`${Math.random()}`}
                      value={selectedClass ? selectedClass : ''}
                      onChange={handleClassUpdate}
                      input={<Input />}
                      renderValue={(selected) => !!selected ? selected.classDisplayName : ''}
                      MenuProps={MenuProps}
                      fullWidth
                    >

                      {(!!selectedSchool && selectedSchool.schoolClasses) ? selectedSchool.schoolClasses.map((option) => (
                        <MenuItem key={option.id + `${Math.random()}`} value={option} fullWidth>
                          <Checkbox checked={selectedClass === option} />
                          <ListItemText primary={option.classDisplayName} />
                        </MenuItem>
                      )) : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={6} className={classes.selectorItems}>
                  <FormControl className={classes.formControl}>
                    <DatePicker
                      fullWidth
                      variant='dialog'
                      id="date-picker-inline-calenderschedule"
                      label="Select Date"
                      value={selectedDate}
                      onChange={handleUpdateDate}
                      onError={console.log}
                      format="dddd, MMMM Do YYYY"

                    />

                  </FormControl>
                </Grid>

              </Grid>

            </Paper>

          </Grid>

          <Grid item xs={12} sm={12} className={classes.table}>
            {returnScheduleList()}
          </Grid> */}
        </Grid>
      </Container>
    </Layout>
  )
}

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    marginTop: '12%',
    marginBottom: '2%',
    borderRadius: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '60%',
    padding: 4,
  },
  selectors: {
    height: 80,
  },
  selectorItems: {
    flexGrow: 1,
    padding: 12,
    width: '10vh',
  },
  table: {
    paddingTop: 16,
    width: '100%',
    height: '100%',
  },
  p8: {
    padding: 8,
  },
}))

export default Calender
