import React, { useState, useContext, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { DBContext } from '../../../controller/contexts/dbContext';
import Layout from '../../layout';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import MeetingRoomComponent from '../../components/staffMeeting/staffMeetingRoomComponent';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import {
  FormField, Box, Layer, Text, Form, TextInput,
} from 'grommet';

const cryptoRandomString = require('crypto-random-string');
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
var moment = require('moment');



const StaffiMeetings = () => {
  const classes = useStyles();
  const { startStaffMeeting,
    fetchSchoolStaffMeetings,
    schoolStaffMeetings,
    addUserToStaffMeetingWhileJoining,
    fetching,
  } = useContext(DBContext);
  const [isMeetingOpen, setIsMeetingOpen] = useState(false);
  const [roomID, setRoomID] = useState(null);
  const [selectedMeetingData, setSelectedMeetingData] = useState(null);
  const [meetingDetailsEntryDialogueOpen, setMeetingDetailsEntryDialogueOpen] = useState(false);
  const [isJoinMeetingDetailsEntryDialogueOpen, setIsJoinMeetingDetailsEntryDialogueOpen] = useState(false);
  const [agenda, setAgenda] = useState(null);
  const [guestName, setGuestName] = useState(null);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    fetchStaffMeetingsForTheDay();
  }, [])

  const fetchStaffMeetingsForTheDay = async () => {
    return await fetchSchoolStaffMeetings()
  }


  const startMeeting = async () => {
    if (!agenda || agenda.length < 4) {
      return alert('Please enter the agenda for the meeting')
    }
    if (!guestName || guestName.length < 4) {
      return alert('Please enter yor full name')
    }
    //console.log(agenda, guestName);
    //create a meeting in database 

    // if the creation is successfull 
    // open the meeting screen and join the 
    // meeting.

    // else show the error.
    const roomIDString = cryptoRandomString({ length: 23 });
    try {
      setLoading(true);
      const savedMeetingData = await startStaffMeeting(guestName, agenda, roomIDString);
      setLoading(false);

      setSelectedMeetingData(savedMeetingData);
      setMeetingDetailsEntryDialogueOpen(false)
      openMeetingDialogue();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }

  const joinMeeting = async () => {
    console.log('join meeting -called', guestName,);

    if (!guestName || guestName.length < 4) {
      return alert('Please enter yor full name')
    }
    if (!selectedMeetingData) {
      return alert('Error! Please try again')
    }
    // update the atendee name in the meeting DB
    // open meeting view 

    try {
      setLoading(true);
      const updatedMeetingData = await addUserToStaffMeetingWhileJoining(selectedMeetingData, guestName);
      console.log('updatedMeetingData', updatedMeetingData);
      setLoading(false);
      setSelectedMeetingData(updatedMeetingData);
      //close entry room
      closeJoinMeetingDetailsEntryDialogue();
      // open meeting room
      openMeetingDialogue();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }

  }

  /**
   * Fuctions for opening and closing 
   * mettings
   */

  const openMeetingDialogue = () => {
    setIsMeetingOpen(true)
  }

  const closeMeetingDialogue = () => {
    setIsMeetingOpen(false);
  }



  const openMetingDetailsEntry = () => {
    setMeetingDetailsEntryDialogueOpen(true);
  }

  const closeMetingDetailsEntry = () => {
    setMeetingDetailsEntryDialogueOpen(false);
  }

  /**
   * For joining meeting 
   */

  const openJoinMeetingDetailsEntryDialogue = (eachMeetingData) => {
    setSelectedMeetingData(eachMeetingData)
    setIsJoinMeetingDetailsEntryDialogueOpen(true);
  }
  const closeJoinMeetingDetailsEntryDialogue = () => {
    setIsJoinMeetingDetailsEntryDialogueOpen(false);
  }

  /**
   * Returning components
   */

  const returnCreateMetingDetailsEntry = () => {
    return (

      <Layer position="center" >
        <Box pad="small" gap="medium" width="medium" direction='column'>
          <Text size='large'>Please provide some details to start meeting</Text>


          <Box paddingTop='small' gap='medium' direction='column' flex="grow" overflow="auto">
            <TextField
              required
              id={`${Math.random()}`}
              label="Agenda"
              helperText="Please enter the agenda for the meeting"
              onChange={event => setAgenda(event.target.value)}
            />

            <TextField
              required
              id={`${Math.random()}`}
              label="Name"
              helperText="Name of the Moderator"
              onChange={event => setGuestName(event.target.value)}
            />

          </Box>
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="end"
            pad={{ top: 'medium', bottom: 'medium' }}
          >
            <Button
              onClick={closeMetingDetailsEntry}
              desable={fetching === true || loading === true}
            >
              Close
            </Button>

            <Button
              onClick={startMeeting}
              desable={fetching || loading}
              color='primary'
              variant='contained'
            >
              Start
            </Button>
          </Box>
        </Box>
      </Layer>
    )
  }
  const returnJoinMeetingDetailsEntry = () => {
    return (

      <Layer position="center" >
        <Box pad="small" gap="medium" width="medium" direction='column'>
          <Text size='large'>Please enter your name to join meeting</Text>


          <Box paddingTop='small' gap='medium' direction='column' flex="grow" overflow="auto">


            <TextField
              required
              id={`${Math.random()}`}
              label="Name"
              helperText="Please enter your name"
              onChange={event => setGuestName(event.target.value)}
            />

          </Box>
          <Box
            as="footer"
            gap="small"
            direction="row"
            align="center"
            justify="end"
            pad={{ top: 'medium', bottom: 'medium' }}
          >
            <Button
              onClick={closeJoinMeetingDetailsEntryDialogue}
              desable={fetching === true || loading === true}
            >
              Close
            </Button>

            <Button
              onClick={() => joinMeeting()}
              desable={fetching || loading}
              color='primary'
              variant='contained'
            >
              Join Meeting
            </Button>
          </Box>
        </Box>
      </Layer>
    )
  }

  const returnMeetingsList = (meetingsListData) => {
    return (
      <Box width='90%' height='100%'>
        <List dense >

          {meetingsListData.map((eachMeetingData, index) => (
            <Paper className={classes.p8} key={index}>
              <ListItem alignItems="flex-start" key={index}>
                <ListItemIcon fontSize='large'>
                  <PeopleOutlineIcon />
                </ListItemIcon>

                <ListItemText

                  primary={
                    <>
                      <li>
                        <Typography variant='h6'>{`${eachMeetingData.agenda}`}</Typography>
                      </li>

                      <li>
                        <Typography
                          variant='caption'
                          className={classes.inline}
                          color='textSecondary'
                        >
                          {`${moment(eachMeetingData.createdAt).fromNow()}`}
                        </Typography>
                      </li>

                    </>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.inline}
                        color="textPrimary"
                      >
                        {`${eachMeetingData.meetingStartedBy} - `}
                      </Typography>

                      {eachMeetingData.participents.length > 0 ? `${eachMeetingData.participents.join(',')}` : 'No participents yet'}


                    </React.Fragment>
                  }
                />

                <ListItemSecondaryAction>
                  {!eachMeetingData.isFinshed ? <Button color='primary' onClick={() => openJoinMeetingDetailsEntryDialogue(eachMeetingData)}>
                    Join
                  </Button> : <Typography color='textSecondary'>MEETING ENDED</Typography>}
                </ListItemSecondaryAction>
              </ListItem>
            </Paper>
          ))}
        </List>
      </Box>
    )
  }

  const returnMeetingView = () => {

    return (
      <>
        <Dialog fullScreen TransitionComponent={Transition}
          aria-labelledby="customized-dialog-title-olclass23829dsrs"
          open={isMeetingOpen}
        >

          <Toolbar>
            <IconButton color='inherit' edge="end"
              onClick={closeMeetingDialogue}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography>Close</Typography>

          </Toolbar>

          <DialogContent >
            <MeetingRoomComponent
              close={closeMeetingDialogue}
              meetigData={selectedMeetingData}
              participantName={guestName}
            />
          </DialogContent>

        </Dialog>
      </>
    )
  }

  return (
    <Layout>
      <Container component="main" maxWidth="md">
        <CssBaseline />
        {isMeetingOpen && returnMeetingView()}

        {
          // for creating new meeting details entry
          meetingDetailsEntryDialogueOpen && returnCreateMetingDetailsEntry()

        }
        {
          // for joining preexisted meeting details entry
          isJoinMeetingDetailsEntryDialogueOpen && returnJoinMeetingDetailsEntry()

        }
        <Grid container spacing={2} direction="column" className={classes.root} >
          <Grid item xs={12} sm={12}>
            <Grid container spacing={1}
              direction="row"
              justify='space-between'
              alignItems="center"
              className={classes.p8}
            >
              <Typography variant='h4' >Staff Meeting</Typography>
              <Button variant='contained' color='primary' onClick={openMetingDetailsEntry}>+ Start Meeting</Button>
            </Grid>

          </Grid>
          <Grid item xs={12} sm={12} className={classes.meetingList}>
            <Grid container spacing={2} direction="column" className={classes.root} >
              {fetching && <LinearProgress />}
              {(!!schoolStaffMeetings && schoolStaffMeetings.length > 0) ? returnMeetingsList(schoolStaffMeetings) : <Typography> No Meetings Yet</Typography>}

            </Grid>

          </Grid>
        </Grid>
      </Container>
    </Layout>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    marginTop: '12%',
    marginBottom: '2%',
    borderRadius: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '60%',
    padding: 4,
  },
  inline: {
    display: 'inline',
  },
  selectors: {
    height: 80,
  },
  selectorItems: {
    flexGrow: 1,
    padding: 12,
    width: '10vh'
  },
  meetingList: {
    paddingTop: 32,
    width: '100%',
    height: '100%',

  },
  p8: {
    padding: 8,
  }
})

);

export default StaffiMeetings;