import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from '../contexts/authContext'

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
  const { currentUser } = useContext(AuthContext)
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !!currentUser ? (
          <RouteComponent {...routeProps} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: {
                from: routeProps.location,
              },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
