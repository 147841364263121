import React, { useState, useEffect } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import 'date-fns';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {
  TextField, Button,
  Paper, Chip
} from '@material-ui/core';
const cryptoRandomString = require('crypto-random-string');
var moment = require('moment');


const AcadamicYear = (props) => {
  const { isOpen, data, close, save, update } = props;
  const [selectedAcadamicYearIndex, setSelectedAcadamicYearIndex] = useState(null);
  // for acadamic year
  const [selectedStartDate, setSelectedStartDate] = React.useState(new Date());
  const [selectedEndDate, setSelectedEndDate] = React.useState(new Date(new Date().setFullYear(new Date().getFullYear() + 1)));

  useEffect(() => {
    if (!!data) {
      // if data is present
      console.log('data', data);
      setSelectedStartDate(data.yearStartingDate)
      setSelectedEndDate(data.yearEndingDate)
    }
  }, [])

  const handleStartDateChange = (date) => {
    setSelectedStartDate(date.toDate());
  };

  const handleEndDateChange = (date) => {
    setSelectedEndDate(date.toDate());
  };
  const saveAcadamicYearValues = () => {

    const data = {
      yearStartingDate: selectedStartDate,
      yearEndingDate: selectedEndDate,
      displayName: `${selectedStartDate.getFullYear()}-${selectedEndDate.getFullYear()}`,
      id: cryptoRandomString({ length: 5 })
    }
    return save(data);
  }
  const updateAcadamicYearData = () => {
    const updatedData = {
      yearStartingDate: selectedStartDate,
      yearEndingDate: selectedEndDate,
      displayName: `${selectedStartDate.getFullYear()}-${selectedEndDate.getFullYear()}`,
      id: data.id
    }
    return update(updatedData)
  }

  return (
    <Dialog
      maxWidth='md'
      open={isOpen}
      onClose={close}
      aria-labelledby="max-width-dialog-title"
    >
      <DialogTitle>Acadamic Year</DialogTitle>
      <DialogContent>
        <Grid containter container direction='column' alignItems='center'>
          <Grid item>
            <Grid container direction='row'>
              <Box p={2}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="ddd, MMMM Do YYYY"
                  margin="normal"
                  id={`${Math.random()}`}
                  label="Start Date"
                  value={selectedStartDate}
                  onChange={handleStartDateChange}

                />

              </Box>
              <Box p={2}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="ddd, MMMM Do YYYY"
                  margin="normal"
                  id={`${Math.random()}`}
                  label="End Date"
                  value={selectedEndDate}
                  onChange={handleEndDateChange}

                />
              </Box>
            </Grid>
          </Grid>
          <Grid item>
            <Typography variant='h6' color='textSecondary' >{`Display Name: ${selectedStartDate.getFullYear()}-${selectedEndDate.getFullYear()}`}</Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Close
      </Button>
        <Button variant='contained' onClick={!!data ? updateAcadamicYearData : saveAcadamicYearValues} color="primary">
          {!!data ? 'Update' : 'Save'}
        </Button>

      </DialogActions>
    </Dialog>





  )
}

export default AcadamicYear;