import React, { useState, useContext, useEffect } from 'react'
import firebase from '../../../controller/firebase'
import { makeStyles } from '@material-ui/core/styles'
import { DBContext } from '../../../controller/contexts/dbContext'
import CssBaseline from '@material-ui/core/CssBaseline'
import Paper from '@material-ui/core/Paper'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import StudentBasicDetails from './studentBasicDetails'
import StudentsParentDetails from './studentsParentDetails'
import StudentOtherDetails from './studentsOtherDetails'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import StudentDetailsPreview from './studentDetailsPreview'
import LinearProgress from '@material-ui/core/LinearProgress'
import Grid from '@material-ui/core/Grid'
const steps = ['Basic Details', 'Parents Details', 'Other Details']

const StudentEntry = (props) => {
  const classes = useStyles()
  const { isNewStudentCreating, togleStudentEntryDailogue, student } = props
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [aadharNumber, setAadharNumber] = useState('')
  const [address, setAddress] = useState('')
  const [pincode, setPincode] = useState('')
  const [country, setCountry] = useState('')
  const [landmark, setLandmark] = useState('')
  const [state, setStateName] = useState('')
  const [city, setCity] = useState('')
  const [district, setDistrict] = useState('')
  const [motherPhoneNumber, setMotherPhoneNumber] = useState('')
  const [fatherPhoneNumber, setFatherPhoneNumber] = useState('')
  const [motherName, setMotherName] = useState('')
  const [fatherName, setFatherName] = useState('')
  const [previousSchool, setPreviousSchool] = useState('')
  const [isTransportBySchool, setIsTransportBySchool] = useState(false)
  const [dob, setDob] = useState(new Date())
  const [imageUrl, setStudentProfileImageUrl] = useState(null)
  const [studentClass, setStudentClass] = useState('')
  const [activeStep, setActiveStep] = useState(0)
  // if the details are edting
  const [isEditingProfile, setIsEditingProfile] = useState(false)
  const [loding, setLoding] = useState(false)
  const {
    createStudent,
    removeStudentFromSchool,
    updateStudentProfile,
  } = useContext(DBContext)
  const [agreedToTNC, setAgreedToTNC] = useState(false)

  useEffect(() => {
    //console.log('Student for view is:', student, isNewStudentCreating);
    if (student) {
      setStateDataFromStudent(student)
    } else if (isNewStudentCreating) {
      resetStateData()
    }
  }, [])

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <StudentBasicDetails
            dob={dob}
            setDob={setDob}
            studentProfileImageUrl={imageUrl}
            setStudentProfileImageUrl={setStudentProfileImageUrl}
            studentClass={studentClass}
            aadharNumber={aadharNumber}
            setAadharNumber={setAadharNumber}
            setStudentClass={setStudentClass}
            isNewStudentCreating={isNewStudentCreating}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            address={address}
            setAddress={setAddress}
            city={city}
            setCity={setCity}
            district={district}
            setDistrict={setDistrict}
            pincode={pincode}
            setPincode={setPincode}
            country={country}
            setCountry={setCountry}
            landmark={landmark}
            setLandmark={setLandmark}
            stateName={state}
            setStateName={setStateName}
          />
        )
      case 1:
        return (
          <StudentsParentDetails
            fatherName={fatherName}
            setFatherName={setFatherName}
            motherName={motherName}
            setMotherName={setMotherName}
            fatherPhoneNumber={fatherPhoneNumber}
            setFatherPhoneNumber={setFatherPhoneNumber}
            motherPhoneNumber={motherPhoneNumber}
            setMotherPhoneNumber={setMotherPhoneNumber}
          />
        )
      case 2:
        return (
          <StudentOtherDetails
            isTransportBySchool={isTransportBySchool}
            setIsTransportBySchool={setIsTransportBySchool}
            previousSchool={previousSchool}
            setPreviousSchool={setPreviousSchool}
          />
        )

      default:
        throw new Error('Unknown setp')
    }
  }
  const handleNext = () => {
    setActiveStep((activeStep) => activeStep < 3 && activeStep + 1)
  }

  const handleBack = () => {
    setActiveStep((activeStep) => activeStep > 0 && activeStep - 1)
  }

  const setStateDataFromStudent = (student) => {
    setFirstName(student.firstName ? student.firstName : '')
    setLastName(student.lastName ? student.lastName : '')
    setAadharNumber(student.aadharNumber ? student.aadharNumber : '')
    setAddress(student.address ? student.address : '')
    setPincode(student.pincode ? student.pincode : '')
    setCountry(student.country ? student.country : '')
    setLandmark(student.landmark ? student.landmark : '')
    setStateName(student.state ? student.state : '')
    setCity(student.city ? student.city : '')
    setDistrict(student.district ? student.district : '')
    setMotherPhoneNumber(
      student.motherPhoneNumber ? student.motherPhoneNumber : '',
    )
    setFatherPhoneNumber(
      student.fatherPhoneNumber ? student.fatherPhoneNumber : '',
    )
    setMotherName(student.motherName ? student.motherName : '')
    setFatherName(student.fatherName ? student.fatherName : '')
    setPreviousSchool(student.previousSchool ? student.previousSchool : '')
    setIsTransportBySchool(
      student.isTransportBySchool ? student.isTransportBySchool : false,
    )
    setDob(student.dob ? student.dob.toDate() : '')
    setStudentProfileImageUrl(student.imageUrl ? student.imageUrl : '')
    setStudentClass(student.studentClass ? student.studentClass : '')
  }

  const resetStateData = () => {
    setFirstName('')
    setLastName('')
    setAddress('')
    setPincode('')
    setCountry('')
    setLandmark('')
    setStateName('')
    setCity('')
    setDistrict('')
    setMotherPhoneNumber('')
    setFatherPhoneNumber('')
    setMotherName('')
    setFatherName('')
    setPreviousSchool('')
    setIsTransportBySchool(false)
    setDob(new Date())
    setStudentProfileImageUrl('')
    setStudentClass('')
    setActiveStep(0)
  }

  /**
   * Creates the kid in the Database
   */
  const addKidProfile = async () => {
    if (firstName.length < 4 || lastName < 4 || studentClass === '') {
      alert(
        'First Name and Last Name should be moret then 3 letters & Class Must be selected to proceed ',
      )
    } else {
      const mNumber =
        motherPhoneNumber.length === 10
          ? { motherPhoneNumber: '+91' + motherPhoneNumber }
          : null
      const fNumber =
        fatherPhoneNumber.length === 10
          ? { fatherPhoneNumber: '+91' + fatherPhoneNumber }
          : null
      togleStudentEntryDailogue()
      return await createStudent({
        firstName,
        lastName,
        displayName: firstName + ' ' + lastName,
        address,
        aadharNumber,
        pincode,
        country,
        landmark,
        state,
        city,
        district,
        ...mNumber,
        ...fNumber,
        motherName,
        fatherName,
        classId: studentClass.id,
        previousSchool,
        isTransportBySchool,
        dob: firebase.firestore.Timestamp.fromDate(dob),
        imageUrl,
        studentClass,
      })
    }
  }
  /**
   * Updates the kid details
   * @param {String} studentID
   */
  const updateKidProfile = async (studentID) => {
    console.log(
      'motherPhoneNumber',
      motherPhoneNumber,
      fatherPhoneNumber,
      'fatherPhoneNumber',
      'from updteProfile',
    )
    if (firstName.length < 4 || lastName < 4 || studentClass === '') {
      alert(
        'First Name and Last Name should be moret then 3 letters & Class Must be selected to proceed ',
      )
    } else {
      let mNumber
      let fNumber
      if (motherPhoneNumber.length === 10) {
        mNumber = { motherPhoneNumber: '+91' + motherPhoneNumber }
      }

      if (fatherPhoneNumber.length === 10) {
        fNumber = { fatherPhoneNumber: '+91' + fatherPhoneNumber }
      }

      if (motherPhoneNumber.length === 13) {
        mNumber = { motherPhoneNumber: motherPhoneNumber }
      }

      if (fatherPhoneNumber.length === 13) {
        fNumber = { fatherPhoneNumber: fatherPhoneNumber }
      }

      console.log('mNumber', mNumber, fNumber, 'fNumber', 'from updteProfile')
      togleStudentEntryDailogue()
      return await updateStudentProfile(studentID, {
        firstName,
        lastName,
        displayName: firstName + ' ' + lastName,
        address,
        pincode,
        country,
        landmark,
        state,
        city,
        aadharNumber,
        district,
        ...mNumber,
        ...fNumber,
        motherName,
        fatherName,
        classId: studentClass.id,
        previousSchool,
        isTransportBySchool,
        dob: firebase.firestore.Timestamp.fromDate(dob),
        imageUrl,
        studentClass,
      })
    }
  }

  /** Function that determines should we need to create the kid
   * or we need to update the kid details?
   * NOTE: if the kid is present that means we are updating the details
   */
  const saveOrUpdateProfile = () => {
    if (!!student) {
      updateKidProfile(student.id)
    } else {
      addKidProfile()
    }
  }
  /** Deletes the kid */
  const removeStudent = async () => {
    togleStudentEntryDailogue()
    return await removeStudentFromSchool(student.id)
  }

  return (
    <>
      <CssBaseline />
      <main className={classes.layout}>
        {loding && <LinearProgress />}
        <Paper className={classes.paper}>
          {/* If we are creating new student we will render everything to enter 
          If we are previewign the profiel we will only see the summary of the 
          profile */}
          {isNewStudentCreating || isEditingProfile ? (
            <>
              <Typography component="h1" variant="h4" align="center">
                Add or Update Student Details
              </Typography>
              <Stepper activeStep={activeStep} className={classes.stepper}>
                {steps.map((label) => (
                  <Step key={label + '$$3'}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
              <>
                {activeStep === steps.length ? (
                  <div className={classes.submit}>
                    {/* Last coponenet to render when creating new student 
                  to preview all the detilas of the student  */}
                    <Typography variant="h5" gutterBottom>
                      Ready to submit?
                    </Typography>

                    <StudentDetailsPreview
                      dob={dob}
                      studentProfileImageUrl={imageUrl}
                      studentClass={studentClass}
                      isNewStudentCreating={isNewStudentCreating}
                      firstName={firstName}
                      lastName={lastName}
                      address={address}
                      aadharNumber={aadharNumber}
                      city={city}
                      pincode={pincode}
                      country={country}
                      landmark={landmark}
                      stateName={state}
                      fatherName={fatherName}
                      motherName={motherName}
                      fatherPhoneNumber={fatherPhoneNumber}
                      motherPhoneNumber={motherPhoneNumber}
                      isTransportBySchool={isTransportBySchool}
                      previousSchool={previousSchool}
                      district={district}
                    />
                    <Typography variant="body2">
                      Please check details provided and click Submtt button to
                      submit.
                    </Typography>

                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          name="AgreeToTC"
                          checked={agreedToTNC}
                          onChange={(event) =>
                            setAgreedToTNC(event.target.checked)
                          }
                        />
                      }
                      label="I/We Agree to Terms and Conditions"
                    />
                    <Button
                      disabled={!agreedToTNC}
                      variant="contained"
                      color="primary"
                      className={classes.submitButton}
                      onClick={() => saveOrUpdateProfile()}
                    >
                      {student ? 'Update' : 'Submit'}
                    </Button>
                    <Button onClick={handleBack}>Back</Button>
                  </div>
                ) : (
                  <>
                    {getStepContent(activeStep)}
                    <div className={classes.buttons}>
                      {activeStep !== 0 && (
                        <Button onClick={handleBack} className={classes.button}>
                          Back
                        </Button>
                      )}
                      {activeStep < steps.length && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleNext}
                          className={classes.button}
                        >
                          Next
                        </Button>
                      )}
                    </div>
                  </>
                )}
              </>
            </>
          ) : (
            <>
              <Grid
                container
                spacing={2}
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <StudentDetailsPreview
                    dob={dob}
                    studentProfileImageUrl={imageUrl}
                    studentClass={studentClass}
                    isNewStudentCreating={isNewStudentCreating}
                    firstName={firstName}
                    lastName={lastName}
                    address={address}
                    aadharNumber={aadharNumber}
                    city={city}
                    pincode={pincode}
                    country={country}
                    landmark={landmark}
                    stateName={state}
                    fatherName={fatherName}
                    motherName={motherName}
                    fatherPhoneNumber={fatherPhoneNumber}
                    motherPhoneNumber={motherPhoneNumber}
                    isTransportBySchool={isTransportBySchool}
                    previousSchool={previousSchool}
                    setIsEditingProfile={setIsEditingProfile}
                    isEditingProfile={isEditingProfile}
                    district={district}
                  />
                </Grid>
                <Grid>
                  <Button onClick={() => setIsEditingProfile(true)}>
                    Edit
                  </Button>
                </Grid>
                <Grid>
                  <Button color="secondary" onClick={() => removeStudent()}>
                    Remove Student From School
                  </Button>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </main>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
  submitButton: {
    margin: 8,
  },
  submit: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

export default StudentEntry
