import React from 'react'
import Typography from '@material-ui/core/Typography'

import { Box } from 'grommet'

const AccountVerificationError = () => {
  return (
    <Box
      background="pink"
      round="small"
      pad="medium"
      justify="center"
      align="center"
      gap="small"
    >
      <Typography variant="h5">
        {' '}
        Your account needs to be verified to use this feature
      </Typography>
      <Typography variant="body1">
        {' '}
        Please contact <em>hello@edaffix.com</em> for account activation
      </Typography>
    </Box>
  )
}

export default AccountVerificationError
