import React, { useState, useContext } from 'react'
import firebase from '../firebase'
import { AuthContext } from './authContext'
import SchoolProfile from '../../model/models/schoolProfile'
import OnlineLesson from '../../model/models/onlineLesson'
import Student from '../../model/models/studentProfile'
import Homework from '../../model/models/homework'
import Exam from '../../model/models/exam'
import ExamSubmission from '../../model/models/examSubmission'
import HomeworkSubmission from '../../model/models/homeworkSubmission'
import Comment from '../../model/models/comment'
import OrgData from '../../model/models/orgData'
import LiveClass from '../../model/models/liveClasses'
import StudentAttendance from '../../model/models/studentAttendance'
import StaffMeeting from '../../model/models/staffMeeting'
import { v1 as uuidv1 } from 'uuid'
import CourseProfile from '../../model/courseProfile'
export const DBContext = React.createContext()

export const DBContextProvider = ({ children }) => {
  // For verification
  // Verification
  const isVeryfied = false

  const { currentUser } = useContext(AuthContext)
  const [userData, setUserData] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [allSchoolsData, setAllSchoolsData] = useState(null)
  //const [allProgramsData, setAllProgramsData] = useState(null);
  //const [selectedProgram, setSelectedProgram] = useState(null);
  //const [schoolAuthData, setSchoolAuthData] = useState(null);
  const [schoolIndex, setSchoolIndex] = useState(0)
  const [orgData, setOrgData] = useState(null)
  const [selectedSchool, setSelectedSchool] = useState(null)
  const [isNewSchoolCreating, setIsNewSchoolCreating] = useState(false)
  const [newSchoolNamae, setNewSchoolName] = useState(null)
  const [error, setError] = useState(null)
  const [schoolStudents, setSchoolStudents] = useState([])
  const [onlineLessonsData, setonlineLessonsData] = useState([])
  const [homeworkData, setHomeworkData] = useState([])
  const [examsData, setExamsData] = useState([])
  const [commentsData, setCommentsData] = useState(null)

  // NEW
  const [selectedProgramType, setSelectedProgramType] = useState(null)
  const [isNewProgramCreating, setIsNewProgramCreating] = useState(false)

  const [allProgramsData, setAllProgramsData] = useState(null)
  const [selectedProgram, setSelectedProgram] = useState(null)

  const [liveClassesList, setLiveClassesList] = useState(null)

  // ATTENDANCE
  const [
    schoolAttendanceForSelectedDay,
    setSchoolAttendanceForSelectedDay,
  ] = useState(null)

  // SCHOOL STAFF MEETINGS
  const [schoolStaffMeetings, setSchoolStaffMeetings] = useState(null)
  // Coruses
  const [courseList, setCourseList] = useState([])
  const [
    reffardStudentListForCourse,
    setReffardStudentListForCourse,
  ] = useState([])

  //Firestore
  const db = firebase.firestore()
  //Storage
  const storage = firebase.storage()

  /* Database Refs*/
  // Organizations
  const ORGS_DB = db.collection('ORGS')
  const ORG_SCHOOOLCOLLEGES_DB = db.collection('ORG_SCHOOOLCOLLEGES')

  // COURSES
  // const COURSES_DB = db.collection('COURSES');
  // const COURSEUSERS_DB = db.collection('COURSEUSERS');
  // const COURSESTAFF_DB = db.collection('COURSESTAFF');
  // const COURSEPERMISSIONS_DB = db.collection('COURSEPERMISSIONS');

  // Schools
  const SCHOOLS_DB = db.collection('SCHOOLS')
  const SCHOOLUSERS_DB = db.collection('SCHOOLUSERS')
  const SCHOOLSTAFF_DB = db.collection('SCHOOLSTAFF')
  const SCHOOLPERMISSIONS_DB = db.collection('SCHOOLPERMISSIONS')
  const SCHOOL_STUDENTS_DB = db.collection('SCHOOL_STUDENTS')

  // Students
  const STUDENTS_DB = db.collection('STUDENTS')
  const STUDENT_PROFILE_SECRET_REF_DB = db.collection('PROFILE_SECRET_KEYS')

  // Parents
  const PARENTS_DB = db.collection('PARENTS')

  /** Online Classes */
  const ONLINE_LESSONS_DB = db.collection('SCHOOL_ONLINE_LESSONS')
  const LESSON_COMMENTS_DB = db.collection('LESSON_COMMENTS')
  /** School HomeWork */
  const SCHOOL_HOMEWORKS_DB = db.collection('SCHOOL_HOMEWORKS')
  const STUDENTS_HOMEWORK_ANSWERS_DB = db.collection(
    'STUDENTS_HOMEWORK_ANSWERS',
  )

  /** Exams */
  const SCHOOL_EXAMS_DB = db.collection('SCHOOL_EXAMS')
  const STUDENTS_ONLINE_EXAM_SUBMISSIONS_DB = db.collection(
    'STUDENTS_ONLINE_EXAMS_ANSWERS',
  )
  const STUDENT_ONLINE_EXAM_ID = db.collection('STUDENTS_ONLINE_EXAMS')
  /** Live classes */
  const LIVE_CLASSES_DB = db.collection('SCHOOL_LIVE_CLASSES')

  /**School Attendance */
  const SCHOOL_ATTENDANCE_DB = db.collection('SCHOOL_ATTENDANCE')

  /**School staff meeting */
  const SCHOOL_STAFF_MEETING_DB = db.collection('SCHOOL_STAFF_MEETING')
  /**
   * Courses
   */
  const COURSES_DB = db.collection('COURSES')
  const SCHOOL_REFFARALS_FOR_COURSES_DB = db.collection(
    'SCHOOL_REFFARALS_FOR_COURSES',
  )
  const COURSE_PENDING_STUDENTS_DB = db.collection('COURSE_PENDING_STUDENTS')
  /** Storage Refs */
  const ORG_PROFILE_IMAGE_BUCKET = storage.ref().child('ORG_PROFILE_IMAGES')
  const SCHOOL_PROFILE_IMAGE_BUCKET = storage
    .ref()
    .child('SCHOOL_PROFILE_IMAGES')
  const STUDENT_PROFILE_IMAGE_BUCKET = storage
    .ref()
    .child('STUDENT_PROFILE_IMAGES')
  const SCHOOL_ONLINE_CLASS_VIDEOS_BUCKET = storage
    .ref()

    .child('SCHOOL_ONLINE_CLASS_VIDEOS')
  const SCHOOL_ONLINE_CLASS_PDF_BUCKET = storage
    .ref()
    .child('SCHOOL_ONLINE_CLASS_PDF')
  const SCHOOL_HOMEWORK_FILES_BUCKET = storage
    .ref()
    .child('SCHOOL_HOMEWORK_PDF')

  /**Live meet url */
  const VCLASS_URL = 'meetolc-edaffix.in'
  const VCLASS_APP_URL = 'https://meetolc-edaffix.in'

  // useEffect(() => {
  //   if (currentUser != null) {
  //     //console.log(currentUser, 'currentUserFrom DBContext')
  //   }
  // }, [currentUser])

  // To ensure that we are moving to the school
  // that we have selected
  const updateIndex = async (index) => {
    setSchoolIndex(index)
  }

  const fetchSchoolDetailsAssociatedWithUserEmail = async () => {
    // setFetching(true);
    // setError(null);
    // setAllSchoolsData([])
    // setSchoolIndex(0)
    // // checks user associated with any institution
    // const query = SCHOOLSTAFF_DB.where(currentUser.uid, '==', true)
    // try {
    //   const snapShot = await query.get()
    //   if (snapShot.empty) {
    //     setFetching(false)
    //     setAllSchoolsData(null)
    //     console.log('user is not associated with any school')
    //     return
    //   } else {
    //     console.log('user is working with some school')
    //     setFetching(false)
    //     snapShot.forEach(async (eachSchool) => {
    //       // Process Each School Data and add
    //       // to fetchedSchoolsData array
    //       const id = eachSchool.id
    //       console.log('school uid', id)
    //       await fetchSchoolProfileDetails(id)
    //     })
    //   }
    // } catch (error) {
    //   console.log(error.message);
    //   setAllSchoolsData(null)
    //   setFetching(false)
    //   setError(`Db Error: ${error.message}`)
    // }
  }

  // once user loged in user will be shown the list of organizations or
  // if they selected organization or shown the list of courses
  // he is teaching
  const fetchUserAsInstructorProgramsData = () => {
    // fetch school/colleges staff database if there are any schools
    // if the user is member of
    // fetch for programs databse to check if the user is member
    // or not
    // update schools/college profile  subscribed to
    // update courses profile subsribed to
  }

  /** ************  For Organization */

  // create organizatoin
  const createOrganization = async (payload) => {
    setFetching(true)
    setError(null)
    setOrgData(null)
    console.log(payload, 'from the db')

    try {
      await ORGS_DB.add({
        ...payload,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        admin: currentUser.uid,
      })
      setFetching(false)
      setError(null)
      return 0
    } catch (error) {
      console.log(error)
      setFetching(false)
      setError(error)
      return error
    }
  }
  // fetches Organization data associtated with the user
  const fetchUserAsOrganizeationData = async () => {
    setFetching(true)
    setError(null)
    setOrgData(null)
    // fetch for organizations db if the user is admin of
    const orgQuery = ORGS_DB.where('admin', '==', currentUser.uid)
    try {
      const snapShot = await orgQuery.get()
      if (snapShot.empty) {
        setFetching(false)
        setError(`Db Error: ${error.message}`)
        setOrgData(null)
        console.log('No Organization is associated with the user')
        return
      } else {
        console.log('user is working with some Org')
        setFetching(false)
        setError(null)
        // because we are using fetch, the results will come in arry only
        // even if there is one result
        let orgList = []
        snapShot.forEach((doc) => {
          const organizationData = new OrgData(doc.id, doc.data())
          console.log('organizationData', organizationData)
          orgList.push(organizationData)
        })
        if (orgList.length > 0) {
          await setOrgData(orgList[0])
          console.log('orgList[0]', orgList[0])
          await fetchProgramsAssociatedWithOrg(orgList[0])
        }
      }
    } catch (error) {
      console.log(error.message)
      setAllSchoolsData(null)
      setFetching(false)

      setError(`Db Error: ${error.message}`)
      setOrgData(null)
    }
  }

  /*** SCHOOL/COLLEGES */
  const fetchProgramsAssociatedWithOrg = async (orgData) => {
    console.log('fetchProgramsAssociatedWithOrg')
    if (!!orgData) {
      setFetching(true)
      try {
        const orgProgramsQuery = SCHOOLS_DB.where('orgId', '==', orgData.id)
        const snapShot = await orgProgramsQuery.get()
        if (snapShot.empty) {
          console.log('No schools are associted with this Org')
          setAllProgramsData([])
          setSelectedSchool(null)
          setFetching(false)
        } else {
          console.log('programs present for this org')

          const schoolList = []
          snapShot.forEach((doc) => {
            const schoolData = new SchoolProfile(doc.id, doc.data())
            console.log('schoolData', schoolData)
            schoolList.push(schoolData)
          })
          // Updatig state with school list
          setFetching(false)
          setAllSchoolsData(schoolList)
          setSelectedSchool(schoolList[0])
        }
      } catch (error) {
        console.log('fetchProgramsAssociatedWithOrg')
        setAllSchoolsData([])
        setSelectedSchool(null)
        setFetching(false)
      }
    } else {
      console.log('Org data is null')
    }
  }

  const fetchSchoolProfileDetails = async (schoolUid) => {
    // LOADING
    setFetching(true)
    //setError(null);
    try {
      let schoolProfileSnapshot = await SCHOOLS_DB.doc(schoolUid).get()
      if (!schoolProfileSnapshot.exists) {
        console.log('We dont have school profile data')
        setFetching(false)
        //setError('Database Error: Cant find details');
        return null
      } else {
        setFetching(false)
        //console.log(schoolProfileSnapshot.data());
        const schoolDetails = new SchoolProfile(
          schoolUid,
          schoolProfileSnapshot.data(),
        )
        console.log('allSchoolsData 1*** is', allSchoolsData)
        setAllSchoolsData((allSchoolsData) => [
          ...allSchoolsData,
          schoolDetails,
        ])
        console.log('allSchoolsData 2*** is', allSchoolsData)
      }
    } catch (error) {
      console.log(`Database Error: ${error.message}`)
      setFetching(false)
      //setError(`Database Error: ${error.message}`);
    }
    return
  }

  /**
   * This is for creating new school from the welcome screen
   * it take schoolName as input and creates school and adds that name
   * to the uid of the school
   * Imp: it also adds the user as a Admin to the school permissions
   * @param {School Data Object} schoolData
   */
  const createSchoolCollege = async (schoolData) => {
    // LOADING
    setError(null)
    // LOADING
    setFetching(true)
    // to crate uid and as school
    const uid = uuidv1()
    // as we are doing 3 writes simultaniously we are using
    // batch writes for the safty
    let batch = db.batch()
    try {
      // Add user as admin
      const schoolPermissionsRef = SCHOOLPERMISSIONS_DB.doc(uid)
      batch.set(schoolPermissionsRef, { [currentUser.uid]: 'ADMIN' })

      // Add to ORG DATA
      // Set to ORG_SCHOOOLCOLLEGES REF
      const orgSchoolCollegesRef = ORG_SCHOOOLCOLLEGES_DB.doc(orgData.id)
        .collection('OrgPrograms')
        .doc(uid)
      batch.set(orgSchoolCollegesRef, { ...schoolData })

      //Add School Details to DB
      const schoolRef = SCHOOLS_DB.doc(uid)
      batch.set(schoolRef, {
        ...schoolData,
        admin: currentUser.uid,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: {
          uid: currentUser.uid,
          name: currentUser.displayName,
          email: currentUser.email,
        },
        orgId: orgData.id,
        orgData: {
          orgId: orgData.id,
          name: orgData.name,
          imageUrl: orgData.imageUrl,
        },
      })

      //Add user for permission to see school details
      const schoolStaffRef = SCHOOLSTAFF_DB.doc(uid)
      batch.set(schoolStaffRef, { [currentUser.uid]: true })

      // Commit the batch, to save changes
      await batch.commit()
      setFetching(false)
      setIsNewProgramCreating(false)
      // Fetch schoolDetails and update the state with details.
      return fetchAndStateUpdateSchoolProfiledetails(uid)
    } catch (error) {
      setFetching(false)
      console.log(`Can't save details: ${error.message}`)
      return alert(`Can't save details: ${error.message}`)
    }
  }

  /** Sends the data into the Cloud Firestore */
  const updateSchoolCollegeProfileData = async (data) => {
    // LOADING
    setError(null)
    setFetching(true)

    let uid = selectedSchool.id

    const schoolProfileRef = SCHOOLS_DB.doc(uid)
    try {
      // update school data
      await schoolProfileRef.update({
        ...data,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: {
          uid: currentUser.uid,
          name: currentUser.displayName,
          email: currentUser.email,
        },
      })

      //TODO: Run cloud functin to update students school data

      //fetch school data and load the data to the state
      // to make available to settings screen
      setFetching(false)
      console.log('Saved ProfileData success')

      return fetchAndStateUpdateSchoolProfiledetails(uid)
    } catch (error) {
      setFetching(false)
      setError(error.message)
      console.log(error.message, 'saveSchoolProfileData error')
    }
  }

  // Saves the userDetails for the
  // School Admins/ Modifyers
  const saveSchoolUserDetails = (userData, uid) => {
    let ref = SCHOOLUSERS_DB.doc(uid)
    return ref.set({
      ...userData,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    })
  }

  const fetchAndStateUpdateSchoolProfiledetails = async (schoolUid) => {
    console.log('fetchAndStateUpdateSchoolProfiledetails')
    // LOADING
    setFetching(true)
    setError(null)

    try {
      let schoolProfileSnapshot = await SCHOOLS_DB.doc(schoolUid).get()
      if (schoolProfileSnapshot.exists) {
        // if the school data is present this will
        // processs data to by creating SchoolProfile class
        // and it will replace the school data in the all school state
        // at the same index that was before
        // NOTE: If there was no school data before,
        // that means it is the first time that it is setting
        // the data to the cloud so it will add directly to the state.
        const schoolData = new SchoolProfile(
          schoolProfileSnapshot.id,
          schoolProfileSnapshot.data(),
        )
        if (!!allSchoolsData && allSchoolsData.length > 0) {
          // get all schools list
          let schoolsList = [...allSchoolsData]

          // check if the indexed value is having the same
          // uid, if so that means we are updating the
          // data
          if (schoolsList[schoolIndex].id === schoolUid) {
            // replace the present school
            schoolsList[schoolIndex] = schoolData
            // set the modifyed school list to the state
            setAllSchoolsData([...schoolsList])
            setSelectedSchool(schoolData)
          } else {
            // if both are not same that means we are adding
            // new school to the lis
            // add the school at the end
            let index = schoolsList.length
            schoolsList[index] = schoolData
            setSchoolIndex(index)
            setAllSchoolsData([...schoolsList])
            setSelectedSchool(schoolData)
          }
        } else {
          // if there is no school list that means it is
          // the firest time creation
          setAllSchoolsData(schoolData)
          setSchoolIndex(0)
          setSelectedSchool(schoolData)
        }

        setFetching(false)
      } else {
        console.log('We dont have school profile data')
        setError('Sorry! No data Avilable in DB')
        setFetching(false)
      }
    } catch (error) {
      setFetching(false)
      console.log('fetchAndStateUpdateSchoolProfiledetails', error)
    }
  }

  /** FUNCTIONS TO HANDLE STUDENTS ****/
  /**
   * Adds student to the school,
   * 1) Adds the student to the school students list.
   * 2) Updates the student profile with school data.
   * @param {Object} payload
   * @param {String} schoolId
   */
  const addStudetToTheSchool = async (payload) => {
    if (!payload || !selectedSchool) {
      return alert('Error! Something went wrong please try again')
    }
    try {
      setFetching(true)
      const schoolId = selectedSchool.id
      const schoolData = {
        schoolId,
        schoolName: selectedSchool.schoolName,
        address: selectedSchool.address,
        city: selectedSchool.city,
        phone1: selectedSchool.phone1,
        phone2: selectedSchool.phone2,
        imageURL: selectedSchool.imageURL,
      }
      const schoolStudentsRef = SCHOOL_STUDENTS_DB.doc(selectedSchool.id)
        .collection('students')
        .doc(payload.id)
      const studentProfileRef = STUDENTS_DB.doc(payload.id)
      let batch = db.batch()
      console.log('payload', payload)
      // Adding to school
      batch.set(schoolStudentsRef, {
        ...payload,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        schoolId,
        createdBy: {
          uid: currentUser.uid,
          name: currentUser.displayName,
          email: currentUser.email,
        },
        schoolData: {
          schoolId,
          schoolName: selectedSchool.schoolName,
          schoolImageUrl: selectedSchool.imageURL,
        },
      })

      // updating student profile with school data
      batch.update(studentProfileRef, {
        ...schoolData,
        schoolId,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: {
          uid: currentUser.uid,
          name: currentUser.displayName,
          email: currentUser.email,
        },
      })
      await batch.commit()
      setFetching(false)
      return 0
    } catch (error) {
      console.log(error)
      setFetching(false)
      return error
    }
  }

  const updateStudentProfile = async (studentUid, studentProfileData) => {
    console.log('studentPrifileData In Update', studentProfileData)
    setError(null)
    setFetching(true)
    const schoolId = selectedSchool.id
    const schoolStudentsRef = SCHOOL_STUDENTS_DB.doc(selectedSchool.id)
      .collection('students')
      .doc(studentUid)
    const studentProfileRef = STUDENTS_DB.doc(studentUid)
    const parentsRef = PARENTS_DB.doc(studentUid)
    const schoolData = {
      schoolId,
      schoolName: selectedSchool.schoolName,
      address: selectedSchool.address,
      city: selectedSchool.city,
      phone1: selectedSchool.phone1,
      phone2: selectedSchool.phone2,
      imageURL: selectedSchool.imageURL,
    }
    let motherPhoneData = {}
    let fatherPhoneData = {}

    if (studentProfileData.motherPhoneNumber) {
      motherPhoneData[studentProfileData.motherPhoneNumber] = true
    }
    if (studentProfileData.fatherPhoneNumber) {
      fatherPhoneData[studentProfileData.fatherPhoneNumber] = true
    }

    if (!studentProfileData.motherPhoneNumber) {
      studentProfileData.motherPhoneNumber = ''
    }
    if (!studentProfileData.fatherPhoneNumber) {
      studentProfileData.fatherPhoneNumber = ''
    }

    try {
      let batch = db.batch()
      batch.update(studentProfileRef, {
        ...studentProfileData,
        schoolData,
        schoolId,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: {
          uid: currentUser.uid,
          name: currentUser.displayName,
          email: currentUser.email,
        },
      })

      batch.update(schoolStudentsRef, {
        ...studentProfileData,
        schoolData,
        schoolId,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedBy: {
          uid: currentUser.uid,
          name: currentUser.displayName,
          email: currentUser.email,
        },
      })
      // Make Parets number available for to get the kids data from
      // the mobile app only if the  school management enterd the correct details
      console.log(
        'motherPhoneData',
        motherPhoneData,
        fatherPhoneData,
        'fatherPhoneData',
      )

      batch.set(parentsRef, { ...motherPhoneData, ...fatherPhoneData })
      await batch.commit()
      // LOADING
      setError(null)
      setFetching(false)
      return fetchSchoolStudents()
    } catch (error) {
      console.log(error.message)
      setError(error.message)
      setFetching(false)
    }
  }
  /**
   * Removes student from school students collection
   * and modifys the student data
   * @param {String} studentUid
   */

  const removeStudentFromSchool = async (studentUid) => {
    const schoolId = selectedSchool.id
    setFetching(true)
    setError(null)
    try {
      let batch = db.batch()
      const schoolStudentsRef = SCHOOL_STUDENTS_DB.doc(schoolId)
        .collection('students')
        .doc(studentUid)
      const studentProfileRef = STUDENTS_DB.doc(studentUid)
      batch.update(studentProfileRef, {
        schoolData: null,
        classId: null,
        schoolId: null,
        previousSchool: {
          schoolId,
          schoolName: selectedSchool.schoolName,
          address: selectedSchool.address,
          city: selectedSchool.city,
          phone1: selectedSchool.phone1,
          phone2: selectedSchool.phone2,
          imageURL: selectedSchool.imageURL,
        },
      })
      batch.delete(schoolStudentsRef)
      await batch.commit()
      setFetching(false)
      setError(null)
      // remove deleted studdent from the school List
      //setSchoolStudents(schoolStudents => [...schoolStudents].filter(student => student.id !== studentUid))
      return fetchSchoolStudents()
    } catch (error) {
      console.log('deleteStudent', error.message)
      setFetching(false)
      setError(null)
    }
  }

  /**
   * Fetches Studetns of the School from the school collection
   * and makes Studetnt class out of them and saves to the state
   * NOTE: We have added useCallback hook to prevent running this
   * function, everytime it render
   */

  const fetchSchoolStudents = async () => {
    console.log('fetchSchoolStudents called')
    setFetching(true)
    setSchoolStudents([])
    setError(null)
    try {
      console.log('fetchSchoolStudents,')
      const schoolStudentsRef = SCHOOL_STUDENTS_DB.doc(
        selectedSchool.id,
      ).collection('students')
      const schoolStudentsCollectionDocs = await schoolStudentsRef.get()

      let studetsList = []
      schoolStudentsCollectionDocs.docs.forEach((studentDoc) => {
        const student = new Student(studentDoc.id, studentDoc.data())
        studetsList.push(student)
      })
      setSchoolStudents([...studetsList])
      console.log(studetsList.length)
      setFetching(false)
      setError(null)
    } catch (error) {
      setFetching(false)
      setError(error.message)
    }
  }

  const fetchStudentWithSecretAndReturnStudentId = async (secret) => {
    console.log('secret is :', secret)
    try {
      setFetching(true)
      //const now = new Date()
      // const secondsSinceEpoch = Math.round(now.getTime() / 1000)
      // console.log('now', secondsSinceEpoch);
      // Note: we are removing this expiry time at
      //.where('expireAt', '>=', now) //TODO: PLEASE CHANGE
      const snapshot = await STUDENT_PROFILE_SECRET_REF_DB.where(
        'secret',
        '==',
        secret,
      ).get()
      if (snapshot.empty) {
        console.log('No matching documents.')
        setFetching(false)
        return null
      }
      //NOTE: query always return an arry of results
      let secretDAtaList = []
      snapshot.forEach((doc) => secretDAtaList.push(doc.data()))
      setFetching(false)
      return secretDAtaList[0].studentId
    } catch (error) {
      console.log(error)
      setFetching(false)

      return null
    }
  }

  // Since we are creating this function from the dialogue,
  // We are not changing any state Variables
  const createStudent = async (studentPrifileData) => {
    console.log('creating student', studentPrifileData)
    // LOADING
    setError(null)
    setFetching(true)
    const schoolId = selectedSchool.id
    const studentUid = uuidv1()
    const studentProfileRef = STUDENTS_DB.doc(studentUid)
    const schoolStudentsRef = SCHOOL_STUDENTS_DB.doc(schoolId)
      .collection('students')
      .doc(studentUid)
    const parentsRef = PARENTS_DB.doc(studentUid)
    const schoolData = {
      schoolId,
      schoolName: allSchoolsData[schoolIndex].schoolName,
      address: allSchoolsData[schoolIndex].address,
      city: allSchoolsData[schoolIndex].city,
      phone1: allSchoolsData[schoolIndex].phone1,
      phone2: allSchoolsData[schoolIndex].phone2,
      imageURL: allSchoolsData[schoolIndex].imageURL,
    }
    let motherPhoneData = {}
    let fatherPhoneData = {}
    if (studentPrifileData.motherPhoneNumber) {
      motherPhoneData[studentPrifileData.motherPhoneNumber] = true
    }
    if (studentPrifileData.fatherPhoneNumber) {
      fatherPhoneData[studentPrifileData.fatherPhoneNumber] = true
    }
    try {
      let batch = db.batch()
      batch.set(studentProfileRef, {
        ...studentPrifileData,
        schoolData,
        schoolId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createDBy: {
          uid: currentUser.uid,
          name: currentUser.displayName,
          email: currentUser.email,
        },
      })
      batch.set(schoolStudentsRef, {
        ...studentPrifileData,
        schoolData,
        schoolId,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createDBy: {
          uid: currentUser.uid,
          name: currentUser.displayName,
          email: currentUser.email,
        },
      })
      // Make Parets number available for to get the kids data from
      // the mobile app only if the  school management enterd the correct details
      batch.set(parentsRef, { ...motherPhoneData, ...fatherPhoneData })
      await batch.commit()
      // LOADING
      setError(null)
      setFetching(false)

      return fetchSchoolStudents(schoolId)
    } catch (error) {
      console.log(error.message)
      // LOADING
      setError(error.message)
      setFetching(false)
      return alert(error.message)
    }
  }

  /**
   * Fetches student profile data by using
   * studentId and returns student Studetnt Class object;
   * @param {String} studentId
   */
  const fetchStudentData = async (studentId) => {
    setFetching(true)
    try {
      const doc = await STUDENTS_DB.doc(studentId).get()
      if (!doc.exists) {
        setFetching(false)
        return null
      }

      const student = new Student(doc.id, doc.data())
      setFetching(false)
      return student
    } catch (error) {
      console.log(error)
      setFetching(false)
      return null
    }
  }

  /** FOR Fetching ONLINE Lessons */

  const fetchOnlineLessonVideosAndUpdateState = async () => {
    const schoolId = allSchoolsData[schoolIndex].id
    setFetching(true)
    setError(null)
    setonlineLessonsData([])
    const schooOnlineClassesQuery = ONLINE_LESSONS_DB.doc(schoolId)
      .collection('onlineLessons')
      .limit(10)
      .orderBy('createdAt', 'desc')

    try {
      const querySnapShot = await schooOnlineClassesQuery.get()
      if (querySnapShot.empty) {
        setFetching(false)
        // setError('No data available')
        console.log('No lesson data avilable')
      } else {
        setFetching(false)
        setError(null)
        querySnapShot.forEach((eachLesionsItem) => {
          const lesson = new OnlineLesson(
            eachLesionsItem.id,
            eachLesionsItem.data(),
          )
          console.log(lesson)
          setonlineLessonsData((lessons) => [...lessons, lesson])
        })
      }
    } catch (error) {
      console.log(error.message)
      setError(error.message)
      setFetching(false)
    }
  }

  const createOnlineLesson = async (videoData) => {
    const schoolId = selectedSchool.id

    setFetching(true)
    setError(null)

    const schooOnlineClassesRef = ONLINE_LESSONS_DB.doc(schoolId).collection(
      'onlineLessons',
    )
    try {
      await schooOnlineClassesRef.add({
        ...videoData,
        likes: 0,
        comments: 0,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: {
          uid: currentUser.uid,
          name: currentUser.displayName,
          email: currentUser.email,
        },
        schoolData: {
          schoolName: selectedSchool.schoolName,
          schoolId: schoolId,
          schoolImageUrl: selectedSchool.imageURL,
        },
      })
      setError(null)
      setFetching(false)
      // Fetch and update School POSTS
      fetchOnlineLessonVideosAndUpdateState()
    } catch (error) {
      console.log(error.message)
      setError(error.message)
      setFetching(false)
    }
  }

  const updateOnlineLesson = async (videoData, postID) => {
    const schoolId = allSchoolsData[schoolIndex].id
    setFetching(true)
    setError(null)
    const schooOnlineClassesRef = ONLINE_LESSONS_DB.doc(schoolId)
      .collection('onlineLessons')
      .doc(postID)
    try {
      await schooOnlineClassesRef.set(
        {
          ...videoData,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: {
            uid: currentUser.uid,
            name: currentUser.displayName,
            email: currentUser.email,
          },
        },
        { merge: true },
      )
      setError(null)
      setFetching(false)
      //  Fetch and update School POSTS
      fetchOnlineLessonVideosAndUpdateState()
    } catch (error) {
      console.log(error.message)
      setError(error.message)
      setFetching(false)
    }
  }

  const deleteOnlineLesson = async (postID) => {
    const schoolId = allSchoolsData[schoolIndex].id
    setFetching(true)
    setError(null)
    const schooOnlineClassesRef = ONLINE_LESSONS_DB.doc(schoolId)
      .collection('onlineLessons')
      .doc(postID)
    try {
      await schooOnlineClassesRef.delete()
      setError(null)
      setFetching(false)
      // Fetch and update School POSTS
      fetchOnlineLessonVideosAndUpdateState()
    } catch (error) {
      console.log(error.message)
      setError(error.message)
      setFetching(false)
    }
  }

  /** FOR HOMEWORKS */
  const fetchHomeworkData = async () => {
    const schoolId = selectedSchool.id
    setFetching(true)
    setError(null)
    setHomeworkData([])
    const schooHomeworkQuery = SCHOOL_HOMEWORKS_DB.doc(schoolId)
      .collection('homeworks')
      .orderBy('createdAt', 'desc')
      .limit(10)

    try {
      const querySnapShot = await schooHomeworkQuery.get()
      if (querySnapShot.empty) {
        setFetching(false)
        //setError('No Homework data available, Start creating homeowork')
        console.log('No Homework data avilable')
      } else {
        setFetching(false)
        setError(null)
        querySnapShot.forEach((eacHomeworkItem) => {
          const homework = new Homework(
            eacHomeworkItem.id,
            eacHomeworkItem.data(),
          )
          console.log(homework)
          setHomeworkData((homeworks) => [...homeworks, homework])
        })
      }
    } catch (error) {
      console.log(error.message)
      setError(error.message)
      setFetching(false)
    }
  }
  const createHomework = async (homeworkData) => {
    const schoolId = selectedSchool.id

    setFetching(true)
    setError(null)
    const schoolHomeworkRef = SCHOOL_HOMEWORKS_DB.doc(schoolId).collection(
      'homeworks',
    )
    try {
      await schoolHomeworkRef.add({
        ...homeworkData,
        homeworkSubmissionsCount: 0, // to track student student submissions
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: {
          uid: currentUser.uid,
          name: currentUser.displayName,
          email: currentUser.email,
        },
        schoolData: {
          schoolName: selectedSchool.schoolName,
          schoolId: schoolId,
          schoolImageUrl: selectedSchool.imageURL,
        },
      })
      setError(null)
      setFetching(false)
      // Fetch and update School Homework POSTS
      fetchHomeworkData()
    } catch (error) {
      console.log(error.message)
      setError(error.message)
      setFetching(false)
    }
  }

  const updateHomework = async (homeworkData, homeworkId) => {
    const schoolId = selectedSchool.id
    console.log('hoemwork id', homeworkId)
    setFetching(true)
    setError(null)
    const schoolHomeworkRef = SCHOOL_HOMEWORKS_DB.doc(schoolId)
      .collection('homeworks')
      .doc(homeworkId)
    try {
      await schoolHomeworkRef.set(
        {
          ...homeworkData,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: {
            uid: currentUser.uid,
            name: currentUser.displayName,
            email: currentUser.email,
          },
        },
        { merge: true },
      )
      setError(null)
      setFetching(false)
      // Fetch and update School Homework POSTS
      fetchHomeworkData()
    } catch (error) {
      console.log(error.message)
      setError(error.message)
      setFetching(false)
    }
  }

  const deleteHomework = async (homeworkId) => {
    const schoolId = selectedSchool.id
    setFetching(true)
    setError(null)
    const schoolHomeworkRef = SCHOOL_HOMEWORKS_DB.doc(schoolId)
      .collection('homeworks')
      .doc(homeworkId)
    try {
      await schoolHomeworkRef.delete()
      setError(null)
      setFetching(false)
      // Fetch and update School POSTS
      fetchHomeworkData()
    } catch (error) {
      console.log(error.message)
      setError(error.message)
      setFetching(false)
    }
  }

  // for fetching exam submission data
  const fetchHomeworkSubmissions = async (homeworkId) => {
    const schoolHomeworkSubmissonsCollectionRef = STUDENTS_HOMEWORK_ANSWERS_DB.doc(
      homeworkId,
    ).collection('homeworkSubmissions')

    const snapshot = await schoolHomeworkSubmissonsCollectionRef.get()
    if (snapshot.empty) {
      //console.log('homeworkSubmissions is empty ***');
      return null
    } else {
      // console.log('homeworkSubmissions is PRESENT',);
      // snapshot.docs.map(doc => console.log(doc.data()));
      return snapshot.docs.map(
        (doc) => new HomeworkSubmission(doc.id, doc.data()),
      )
    }
  }

  const submittHomeworkEvaluation = async (
    homeworkId,
    submissionId,
    payload,
  ) => {
    const schoolHomeworkSubmissonsCollectionRef = STUDENTS_HOMEWORK_ANSWERS_DB.doc(
      homeworkId,
    )
      .collection('homeworkSubmissions')
      .doc(submissionId)
    return schoolHomeworkSubmissonsCollectionRef.update({
      ...payload,
      evaluationAt: firebase.firestore.FieldValue.serverTimestamp(),
      evalutaionBy: {
        uid: currentUser.uid,
        name: currentUser.displayName,
        email: currentUser.email,
      },
    })
  }

  /**
   * EXAMS
   */

  const fetchExamsData = async () => {
    const schoolId = selectedSchool.id
    setFetching(true)
    setError(null)
    setExamsData([])
    const schoolExamsQuery = SCHOOL_EXAMS_DB.doc(schoolId)
      .collection('examData')
      .orderBy('createdAt', 'desc')

    try {
      const querySnapShot = await schoolExamsQuery.get()
      if (querySnapShot.empty) {
        setFetching(false)
        //setError('No Exams data available, Start creating homeowork')
        console.log('No Exams data avilable')
      } else {
        setFetching(false)
        setError(null)
        querySnapShot.forEach((eachExamData) => {
          const exam = new Exam(eachExamData.id, eachExamData.data())
          console.log(exam)
          setExamsData((exams) => [...exams, exam])
        })
      }
    } catch (error) {
      console.log(error.message)
      setError(error.message)
      setFetching(false)
    }
  }

  const createExam = async (examData) => {
    const schoolId = selectedSchool.id
    setFetching(true)
    setError(null)
    const schoolExamsRef = SCHOOL_EXAMS_DB.doc(schoolId).collection('examData')
    try {
      await schoolExamsRef.add({
        ...examData,
        examSubmissionsCount: 0, // to track exam submissions
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        createdBy: {
          uid: currentUser.uid,
          name: currentUser.displayName,
          email: currentUser.email,
        },
        schoolData: {
          schoolName: selectedSchool.schoolName,
          schoolId: schoolId,
          schoolImageUrl: selectedSchool.imageURL,
        },
      })
      setError(null)
      setFetching(false)
      fetchExamsData()
    } catch (error) {
      console.log(error.message)
      setError(error.message)
      setFetching(false)
    }
  }

  const updateExam = async (examData, examId) => {
    const schoolId = selectedSchool.id
    setFetching(true)
    setError(null)
    const schoolExamsRef = SCHOOL_EXAMS_DB.doc(schoolId)
      .collection('examData')
      .doc(examId)
    try {
      await schoolExamsRef.set(
        {
          ...examData,
          updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
          updatedBy: {
            uid: currentUser.uid,
            name: currentUser.displayName,
            email: currentUser.email,
          },
        },
        { merge: true },
      )
      setError(null)
      setFetching(false)
      fetchExamsData()
    } catch (error) {
      console.log(error.message)
      setError(error.message)
      setFetching(false)
    }
  }

  const deleteExams = async (examId) => {
    const schoolId = selectedSchool.id
    setFetching(true)
    setError(null)
    const schoolExamsRef = SCHOOL_EXAMS_DB.doc(schoolId)
      .collection('examData')
      .doc(examId)

    try {
      await schoolExamsRef.delete()
      setError(null)
      setFetching(false)
      // Fetch and update School POSTS
      fetchExamsData()
    } catch (error) {
      console.log(error.message)
      setError(error.message)
      setFetching(false)
    }
  }

  // for fetching exam submission data
  const fetchExamSubmissions = async (examId) => {
    const schoolExamSubmissonsCollectionRef = STUDENTS_ONLINE_EXAM_SUBMISSIONS_DB.doc(
      examId,
    ).collection('examAnswerData')

    try {
      const snapshot = await schoolExamSubmissonsCollectionRef
        .orderBy('examSubmittedAt', 'desc')
        .get()

      const submissions = await snapshot.docs.map(
        (doc) => new ExamSubmission(doc.id, doc.data()),
      )
      console.log('submissions', submissions)
      return submissions
    } catch (error) {
      console.log('fetchExamSubmissions Error:', error)
      return null
    }
  }

  const submitWrittenExamEvaluation = async (examId, submissonId, payload) => {
    const schoolExamSubmissonsCollectionRef = STUDENTS_ONLINE_EXAM_SUBMISSIONS_DB.doc(
      examId,
    )
      .collection('examAnswerData')
      .doc(submissonId)

    await schoolExamSubmissonsCollectionRef.update({
      evaluationAt: firebase.firestore.FieldValue.serverTimestamp(),
      evaluationDone: true,
      evalutaionBy: {
        uid: currentUser.uid,
        name: currentUser.displayName,
        email: currentUser.email,
      },
      examResult: { scoreReport: { ...payload } },
    })
  }

  // Live classes

  /**TODO: FOR LIVE CLASSES */
  /**
   * This function fetches the live classes from that day
   * and saves in the state.
   */

  const fetchLiveClasses = async () => {
    const schoolId = selectedSchool.id
    const now = new Date()
    const lastMidNight = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
    )
    //console.log('dates', now, lastMidNight);

    setFetching(true)
    const liveLessonsQery = LIVE_CLASSES_DB.doc(schoolId)
      .collection('liveClasses')
      .where('createdAt', '>=', lastMidNight)
      .orderBy('createdAt', 'desc')
    try {
      const querySnapShot = await liveLessonsQery.get()
      // it will set the state an empty array and
      // it will return.
      setFetching(false)
      if (querySnapShot.empty) {
        console.log('no live classes')
        setLiveClassesList([])
        return
      }

      let liveClassesList = []
      querySnapShot.forEach((eachLiveClass) => {
        const live = new LiveClass(eachLiveClass.id, eachLiveClass.data())
        console.log('live', 'live')
        liveClassesList.push(live)
      })

      //console.log('liveClasses', liveClassesList);
      setLiveClassesList([...liveClassesList])
    } catch (error) {
      console.log('fetchLiveclasses', error)
      setFetching(false)
      setLiveClassesList([])
    }
  }

  const createLiveClass = async (
    roomNameString,
    titleText,
    selectedClasses,
    selectedSubject,
  ) => {
    const schoolId = selectedSchool.id
    //TODO: Now we are using room id as a reference id it si not good
    // change it to anothr method

    const schooLiveClassesRef = LIVE_CLASSES_DB.doc(schoolId)
      .collection('liveClasses')
      .doc(roomNameString)

    return schooLiveClassesRef.set({
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      roomId: roomNameString,
      selectedClasses,
      selectedSubject,
      baseUrl: VCLASS_APP_URL,
      status: 'LIVE',
      title: titleText,
      createdBy: {
        uid: currentUser.uid,
        name: currentUser.displayName,
        email: currentUser.email,
      },
      schoolData: {
        schoolName: selectedSchool.schoolName,
        schoolId: schoolId,
        schoolImageUrl: selectedSchool.imageURL,
      },
    })
  }

  const endLiveClass = async (roomId) => {
    console.log('End Live class called')
    const schoolId = selectedSchool.id

    const schooLiveClassesRef = LIVE_CLASSES_DB.doc(schoolId)
      .collection('liveClasses')
      .doc(roomId)

    return schooLiveClassesRef.update({
      status: 'FINISHED',
      endedAt: firebase.firestore.FieldValue.serverTimestamp(),
      roomId: null,
    })
  }

  const getLessonComments = async (lessonId) => {
    setFetching(true)
    setError(null)
    setCommentsData([])

    try {
      const commetsCollectionRef = LESSON_COMMENTS_DB.doc(lessonId)
        .collection('comments')
        .orderBy('createdAt', 'desc')
      const querySnapShot = await commetsCollectionRef.get()

      if (querySnapShot.empty) {
        setFetching(false)
        //setError('No Exams data available, Start creating homeowork')
        console.log('No comments avilable')
      } else {
        setFetching(false)
        setError(null)
        querySnapShot.forEach((commentData) => {
          const comment = new Comment(commentData.id, commentData.data())
          console.log(comment)
          setCommentsData((c) => [...c, comment])
        })
      }
    } catch (error) {
      setFetching(false)
      setError(error)
    }
  }

  const postAnswerToQuestion = async (lessonId, commnetId, replyText) => {
    const commetsCollectionRef = LESSON_COMMENTS_DB.doc(lessonId)
      .collection('comments')
      .doc(commnetId)
    return await commetsCollectionRef.update({
      replyText,
      replyTime: firebase.firestore.FieldValue.serverTimestamp(),
      replyBy: allSchoolsData[schoolIndex].schoolName,
    })
  }

  /**
   * This function fetches the student attendance from the
   * SCHOOL_ATTENDANCE collection by date provided.
   * NOTE: given date will be modifyed to get the biggining of the date(aka: 12AM)
   * @param {Date} date
   */

  const fetchSchoolAttendanceForDate = async (date) => {
    const schoolId = selectedSchool.id
    const beginningOfTheGivenDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    )

    //console.log('dates', now, lastMidNight);
    setFetching(true)
    const attendanceQery = SCHOOL_ATTENDANCE_DB.doc(schoolId)
      .collection('attendance')
      .where('attendanceForDate', '==', beginningOfTheGivenDate)
    try {
      const querySnapShot = await attendanceQery.get()
      // it will set the state an empty array and
      // it will return.
      setFetching(false)
      if (querySnapShot.empty) {
        console.log('no attendanceData')
        setSchoolAttendanceForSelectedDay([])
        return []
      }

      let attendanceList = []
      querySnapShot.forEach((eachAttenadance) => {
        const attendance = new StudentAttendance(
          eachAttenadance.id,
          eachAttenadance.data(),
        )
        attendanceList.push(attendance)
      })

      // console.log('attendanceList', attendanceList);
      // setSchoolAttendanceForSelectedDay([...attendanceList]);
      return attendanceList
    } catch (error) {
      console.log('fetchSchoolAttendanceForDate Eror: ', error)
      setFetching(false)
      setSchoolAttendanceForSelectedDay([])
    }
  }

  // this function will trigger every time we chanve the
  // attendance for the kid.
  const saveStudntAttendanceForSelectedDay = async (
    studentData,
    date,
    isAttended,
  ) => {
    const schoolId = selectedSchool.id
    const beginningOfTheGivenDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    )

    console.log('studentData.id', studentData.id)
    const schoolAttendanceRef = SCHOOL_ATTENDANCE_DB.doc(schoolId)
      .collection('attendance')
      .doc()
    const studentAttendanceData = {
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      attendanceForDate: beginningOfTheGivenDate,
      schoolId: schoolId,
      schoolName: selectedSchool.schoolName,
      studentId: studentData.id,
      studentName: studentData.displayName,
      classId: studentData.studentClass.id,
      isPresent: isAttended,
    }
    setFetching(true)

    try {
      const result = await schoolAttendanceRef.set(studentAttendanceData, {
        merge: true,
      })
      console.log('result after saving', result)
      const resultData = await schoolAttendanceRef.get()

      setFetching(false)

      if (resultData.exists) {
        return new StudentAttendance(resultData.id, resultData.data())
      }
    } catch (error) {
      setFetching(false)
      console.log('setStudntAttendance Error:', error)
    }
  }

  //STAFF MEETING
  const fetchSchoolStaffMeetings = async () => {
    const schoolId = selectedSchool.id
    const now = new Date()
    const beginningOfTheDay = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
    )
    //console.log('dates', now, lastMidNight);
    setFetching(true)
    const meetingQery = SCHOOL_STAFF_MEETING_DB.doc(schoolId)
      .collection('staffMeetings')
      .where('createdAt', '>=', beginningOfTheDay)
      .orderBy('createdAt', 'desc')

    try {
      const querySnapShot = await meetingQery.get()
      // it will set the state an empty array and
      // it will return.
      setFetching(false)
      if (querySnapShot.empty) {
        console.log('no Meetings Data')
        setSchoolStaffMeetings([])
        return
      }

      let meetingsList = []
      querySnapShot.forEach((eachMeeting) => {
        const meeting = new StaffMeeting(eachMeeting.id, eachMeeting.data())
        meetingsList.push(meeting)
      })

      console.log('meetingsList', meetingsList)
      return setSchoolStaffMeetings([...meetingsList])
    } catch (error) {
      console.log('fetchSchoolStaffMeetings Eror: ', error)
      setFetching(false)
      setSchoolStaffMeetings([])
    }
  }

  const startStaffMeeting = async (meetingStartedBy, agenda, roomId) => {
    const schoolId = selectedSchool.id

    //console.log('dates', now, lastMidNight);
    setFetching(true)
    const meetingsRef = SCHOOL_STAFF_MEETING_DB.doc(schoolId)
      .collection('staffMeetings')
      .doc()

    try {
      await meetingsRef.set({
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        agenda: agenda,
        isFinshed: false,
        meetingStartedBy: meetingStartedBy,
        roomId: roomId,
      })
      const resultData = await meetingsRef.get()
      setFetching(false)
      if (resultData.exists) {
        return new StaffMeeting(resultData.id, resultData.data())
      }
    } catch (error) {
      console.log('startStaffMeeting[ERROR]:', error)
      setFetching(false)
    }
  }

  const addUserToStaffMeetingWhileJoining = async (meetingData, userName) => {
    const schoolId = selectedSchool.id

    //console.log('dates', now, lastMidNight);
    setFetching(true)
    const meetingsRef = SCHOOL_STAFF_MEETING_DB.doc(schoolId)
      .collection('staffMeetings')
      .doc(meetingData.id)

    try {
      await meetingsRef.update({
        participents: [...meetingData.participents, userName],
      })
      const resultData = await meetingsRef.get()
      setFetching(false)
      if (resultData.exists) {
        return new StaffMeeting(resultData.id, resultData.data())
      }
    } catch (error) {
      console.log('addUserToStaffMeetingWhileJoining[ERROR]:', error)
      setFetching(false)
    }
  }

  const fetchAvailableCourses = async () => {
    setFetching(true)
    try {
      const collectionSnapShot = await COURSES_DB.get()

      const courses = []
      collectionSnapShot.forEach((eachDoc) => {
        const course = new CourseProfile(eachDoc.id, eachDoc.data())
        console.log(course)
        courses.push(course)
      })
      console.log(courseList)
      setCourseList([...courses])
      setFetching(false)
      return
    } catch (error) {
      setFetching(false)
      console.log(error.message)
      return error.message
    }
  }
  /**
   *
   * @param {String} courseId
   * @returns null/error
   * fetches studetn list for a course
   * it will set the state with the student list
   * and return null if there is no error and returns
   * error if there is an error .
   */
  const fetchCourseRefferalList = async (courseId) => {
    if (!selectedSchool) {
      return 'Please create school/college profile to proceed'
    }
    setFetching(true)
    const schoolReffaralCollection = SCHOOL_REFFARALS_FOR_COURSES_DB.doc(
      selectedSchool.id,
    )
      .collection('courseReffarals')
      .doc(courseId)
      .collection('students')

    try {
      const querySnapShot = await schoolReffaralCollection.get()
      //console.log(snapshot.data())
      let studentsList = []
      querySnapShot.forEach((eachDoc) => {
        studentsList.push({
          id: eachDoc.id,
          ...eachDoc.data(),
        })
      })

      setReffardStudentListForCourse([...studentsList])

      setFetching(false)
    } catch (error) {
      setFetching(false)
      setReffardStudentListForCourse([])

      return error.message
    }
  }
  /**
   *
   * @param {String} courseId
   * @param {Array} studentsList
   * @returns null/error
   * Create/Adds refferal students array to the db and fetches the new list
   * NOTE: we are using set function here so that we can use to crate and update
   * the database with the same function by addign {merge: true}
   */
  // const addCourseReffaralStudentList = async (courseId, studentsList) => {
  //   if (!selectedSchool) {
  //     return 'Please create school/college profile to proceed'
  //   }
  //   if (studentsList < 1) {
  //     return 'Please select atleast 1 student'
  //   }
  //   setFetching(true)

  //   const schoolReffaralCollection = SCHOOL_REFFARALS_FOR_COURSES_DB.doc(
  //     selectedSchool.id,
  //   )
  //     .collection('refferedStudents')
  //     .doc(courseId)
  //   // for course

  //   const coursePendingStudentsRef = COURSE_PENDING_STUDENTS_DB.doc()
  //   /**
  //    * NOTE: Right now we are adding students list to COURSE_PENDING_STUDENTS collection
  //    * we wil create cloud function later to handile this task
  //    *
  //    */
  //   try {
  //     await schoolReffaralCollection.set(
  //       {
  //         studentsList: [...studentsList],
  //         schoolData: selectedSchool.returnSchoolData(),
  //       },
  //       { merge: true },
  //     )
  //     const snapshot = await schoolReffaralCollection.get()

  //     if (snapshot.exists) {
  //       console.log(snapshot.data())
  //       setReffardStudentListForCourse([...snapshot.data().studentsList])
  //     }
  //     setFetching(false)
  //   } catch (error) {
  //     setFetching(false)
  //     setReffardStudentListForCourse([])

  //     return error.message
  //   }
  // }

  const refferStudent = async (courseId, student) => {
    if (!selectedSchool) {
      return 'Please create school/college profile to proceed'
    }
    if (!student) {
      return 'Student data is not available'
    }

    const schoolReffaralRef = SCHOOL_REFFARALS_FOR_COURSES_DB.doc(
      selectedSchool.id,
    )
      .collection('courseReffarals')
      .doc(courseId)
      .collection('students')
      .doc(student.id)

    // for course
    const coursePendingStudentsRef = COURSE_PENDING_STUDENTS_DB.doc(courseId)
      .collection('students')
      .doc(student.id)

    setFetching(true)
    let batch = db.batch()
    // set the data
    try {
      const studetDataObject = student.returnStudentProfileObject()
      const schoolDataObject = selectedSchool.returnSchoolData()
      batch.set(schoolReffaralRef, {
        reffard: true,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      })
      // NOTE: WE ARE SENDING ONLY class name (eg, 1,2,3 not like 1-A ) without section
      batch.set(coursePendingStudentsRef, {
        ...studetDataObject,
        studentClass: student.studentClass.schoolClassName,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        isRefferd: true,
        referrerType: 'SCHOOL',
        refferdBy: { ...schoolDataObject },
      })

      await batch.commit()
      console.error('***', reffardStudentListForCourse.length)

      setFetching(false)
      const reffList = [...reffardStudentListForCourse]

      setReffardStudentListForCourse([
        ...reffList,
        { id: student.id, reffard: true },
      ])
    } catch (error) {
      console.log(error.message)
      setFetching(false)
      return error.message
    }
  }

  return (
    <DBContext.Provider
      value={{
        userData,
        fetching,
        //Verification
        isVeryfied,

        // For Organization
        fetchUserAsOrganizeationData,
        fetchUserAsInstructorProgramsData,
        createOrganization,
        setAllProgramsData,
        setSelectedProgram,
        setSelectedProgramType,
        setIsNewProgramCreating,
        isNewProgramCreating,
        orgData,
        allProgramsData,
        selectedProgramType,

        // For schools
        saveSchoolUserDetails,
        createSchoolCollege,
        setSelectedSchool,
        updateSchoolCollegeProfileData,
        addStudetToTheSchool,
        updateIndex,
        error,
        allSchoolsData,
        schoolIndex,
        isNewSchoolCreating,
        newSchoolNamae,
        selectedSchool,

        // For Students
        createStudent,
        fetchSchoolStudents,
        removeStudentFromSchool,
        updateStudentProfile,
        fetchStudentWithSecretAndReturnStudentId,
        fetchStudentData,
        schoolStudents,

        // //For online Lessons
        fetchOnlineLessonVideosAndUpdateState,
        createOnlineLesson,
        updateOnlineLesson,
        deleteOnlineLesson,
        getLessonComments,
        postAnswerToQuestion,
        onlineLessonsData,
        commentsData,

        // // for Live classes
        createLiveClass,
        endLiveClass,
        fetchLiveClasses,

        liveClassesList,

        // //For homeworks
        fetchHomeworkData,
        createHomework,
        updateHomework,
        deleteHomework,
        fetchHomeworkSubmissions,
        submittHomeworkEvaluation,
        homeworkData,

        // // For Exams
        fetchExamsData,
        createExam,
        updateExam,
        deleteExams,
        fetchExamSubmissions,
        submitWrittenExamEvaluation,
        examsData,

        // // Attendance
        fetchSchoolAttendanceForDate,
        saveStudntAttendanceForSelectedDay,
        schoolAttendanceForSelectedDay,

        // // Staff meeting
        // startStaffMeeting,
        // fetchSchoolStaffMeetings,
        // addUserToStaffMeetingWhileJoining,
        // schoolStaffMeetings,

        // Courses
        fetchAvailableCourses,
        //addCourseReffaralStudentList,
        fetchCourseRefferalList,
        courseList,
        reffardStudentListForCourse,
        refferStudent,

        // Storage refs
        storage,
        ORG_PROFILE_IMAGE_BUCKET,
        SCHOOL_PROFILE_IMAGE_BUCKET,
        STUDENT_PROFILE_IMAGE_BUCKET,
        SCHOOL_ONLINE_CLASS_VIDEOS_BUCKET,
        SCHOOL_ONLINE_CLASS_PDF_BUCKET,
        SCHOOL_HOMEWORK_FILES_BUCKET,
        // Live class urls
        VCLASS_URL,
        VCLASS_APP_URL,
      }}
    >
      {children}
    </DBContext.Provider>
  )
}
