import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
const cryptoRandomString = require('crypto-random-string');

const WrittenExamEntryEdit = ({ open, closeDialogue, questionData, addQuestionToQuestionsList, type }) => {
  const classes = useStyles();
  const [questionsList, setQuestionsList] = useState([]);
  const [sectionIntro, setSectionIntro] = useState('');
  const [marksForEachQuestion, setMarksForEachQuestion] = useState(null);

  useEffect(() => {
    if (!!questionData) {

      console.log(questionData.options);
      setSectionIntro(questionData.sectionIntro)
      setQuestionsList(questionData.questionsList)
      setMarksForEachQuestion(questionData.marksForEachQuestion)
    }
  }, [])



  const handleChange = name => event => {
    if (name === 'sIntro') {
      setSectionIntro(event.target.value);
    }
    if (name === 'marks') {
      if (parseInt(event.target.value)) {
        setMarksForEachQuestion(parseInt(event.target.value));
      } else {
        return alert('Please enter a valid number for marks')
      }
    }
  }

  const handleQuestionTextChange = (name, index) => event => {
    //console.log(name, index);
    if (name === 'qText') {
      let questions = [...questionsList];
      questions[index].qText = event.target.value
      setQuestionsList([...questions]);
    }
    if (name === 'qExplanation') {
      let questions = [...questionsList];
      questions[index].qExplanation = event.target.value
      setQuestionsList([...questions]);
    }
  }
  // add state with empty question object
  // called when clicked on add new questin . 
  const addQuestionObjectToState = () => {
    if (checkQuestions() === 1) {
      return alert('Please add all the details for the previous questions before adding new question')
    }
    let questions = [...questionsList];
    questions.push({ 'qNumber': questionsList.length, 'qText': '', 'qExplanation': '' })
    setQuestionsList([...questions])
  }

  const checkQuestions = () => {

    for (let i = 0; i < questionsList.length; i++) {
      if (questionsList[i].qText.length < 5) {
        return 1
      }
      return 0
    }
  }
  // send back to the question creaton 
  const addSection = () => {
    if (questionsList.length < 1) {
      return alert('Please make sure you have added atleast 1 question');
    }
    if (checkQuestions() != 0) {
      return alert('Please make sure you have entered question text');
    }
    if (sectionIntro.length < 1) {
      return alert("Sorry, section intro can't be  empty");
    }
    if (marksForEachQuestion === null || parseInt(marksForEachQuestion) < 1) {
      return alert('Please enter marks for each question');
    }

    if (!!questionData) {
      addQuestionToQuestionsList({
        questionsList, sectionIntro, marksForEachQuestion, id: questionData.id
      })
    } else {
      addQuestionToQuestionsList({
        questionsList, sectionIntro, marksForEachQuestion, id: cryptoRandomString({ length: 8 })
      })
    }
  }
  const deleteQuestion = index => {
    let questions = [...questionsList];
    questions.splice(index, 1)
    setQuestionsList([...questions])
  }

  const close = () => {
    console.log('close');
    closeDialogue()
  }

  return (
    <>
      <Dialog onClose={close} aria-labelledby="customized-dialog-title-for-q-enty" open={open}>
        <DialogTitle id="customized-dialog-title-title-for-q-enty" onClose={close}>
          {!!questionData ? <Typography variant="h5">Update Question</Typography> :
            'Add Exam section'
          }
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={3} className={classes.padding} >
            {/* Section Intro */}
            <Grid item xs={12} sm={9} >
              <TextField
                required
                id="outlined-required-questiondi-w4qfdsa$"
                value={sectionIntro}
                onChange={handleChange('sIntro')}
                fullWidth
                multiline
                autoFocus
              />
              <Typography variant='caption' color='textSecondary' paragraph>* Section Intro/Titel</Typography>
            </Grid>

            <Grid item xs={12} sm={9} >
              <TextField
                required
                id="outlined-required-questbvcxiondi-$"
                value={marksForEachQuestion}
                onChange={handleChange('marks')}
              />
              <Typography variant='caption' color='textSecondary' paragraph>* Marks for each question</Typography>

            </Grid>
            <Grid item xs={12} sm={12}>
              {questionsList.map((eachQuestion, index) => {

                return (
                  <div>

                    {/** For Question List*/}
                    <Typography color='textSecondary' > {`Question ${index + 1}`} </Typography>
                    <TextField
                      id={`${Math.random()}`}

                      multiline
                      value={eachQuestion.qText}
                      onChange={handleQuestionTextChange('qText', index)}

                      rows={2}
                      variant="outlined"
                      fullWidth
                    />


                    {/** For Explenations */}
                    <Typography variant='subtitle1' color='textSecondary' >
                      {`Explenation for Question ${index + 1}  (optional)`}
                    </Typography>
                    <TextField
                      id={`${Math.random()}`}
                      multiline
                      value={eachQuestion.qExplanation}
                      onChange={handleQuestionTextChange('qExplanation', index)}
                      rows={3}
                      variant="outlined"
                      fullWidth

                    />


                    <IconButton aria-label="delete"
                      style={{ fontSize: 12 }}
                      onClick={() => deleteQuestion(index)}
                    >
                      <DeleteIcon />
                    </IconButton>




                  </div>
                )
              })}
            </Grid>

            <Grid item xs={12} sm={12}>
              <Button
                fullWidth
                onClick={addQuestionObjectToState}
              > {`+ Add Question ${questionsList.length + 1}`}</Button>
            </Grid>

          </Grid>

        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={addSection} color="primary">
            {!!questionData ? 'Update Question ' : '+ Add'}
          </Button>
        </DialogActions>
      </Dialog>

    </>);
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '8%',
    padding: theme.spacing(2),
    marginBottom: '2%',

  },
  padding: {
    padding: 12,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
  margin: {
    margin: theme.spacing(1),

  },
  withoutLabel: {
    marginTop: theme.spacing(3),
  },
  textField: {
    padding: theme.spacing(1)
  },
}));

export default WrittenExamEntryEdit;