import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Modal, CircularProgress } from '@material-ui/core';

const Loading = () => {
  const classes = useStyles();
  return (
    <div classes={classes.root}>
      <Modal
        open={true}
        className={classes.model}
      >
        <CircularProgress size={30} className={classes.progress} />
      </Modal>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh'
  },
  model: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progress: {
    margin: theme.spacing.unit,
  },
}));

// We need an intermediary variable for handling the recursive nesting.
export default Loading;