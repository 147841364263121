export default class OnlineLesson {
  constructor(id, lessonData) {
    console.log('lesson pdf url', lessonData.lessonUrl);
    this.id = id;
    this.createdAt = lessonData.createdAt;
    this.selectedClasses = lessonData.selectedClasses;
    this.selectedSubject = lessonData.selectedSubject;
    this.videoUrl = lessonData.videoUrl;
    this.videoTitle = lessonData.videoTitle;
    this.videoDiscreption = lessonData.videoDiscreption;
    this.studentCount = lessonData.studentCount;
    this.viewsCount = lessonData.viewsCount;
    this.commentsCount = lessonData.commentsCount;
    //new
    this.type = lessonData.type;
    this.lessonUrl = !!lessonData.lessonUrl ? lessonData.lessonUrl.toString() : null;
  }

}