export default class StudentAttendance {
  constructor(id, data) {
    this.id = id;
    this.createdAt = data.createdAt;
    this.attendanceForDate = data.attendanceForDate;
    this.schoolId = data.schoolId;
    this.schoolName = data.schoolName;
    this.studentId = data.studentId;
    this.studentName = data.studentName;
    this.classId = data.classId;
    this.isPresent = data.isPresent;
  }
}