import React, { useState, useContext } from 'react'
import { DBContext } from '../../../controller/contexts/dbContext'
import { makeStyles } from '@material-ui/core/styles'
import Layout from '../../layout'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import AddIcon from '@material-ui/icons/Add'
import Selectors from '../../components/selectors'
import Backdrop from '@material-ui/core/Backdrop'
import HomeworkEntryEditDialogue from '../../components/homework/homeworkEntryEdit'
import HomeworkTable from '../../components/homework/homeworkTable'
import AccountVerificationError from '../../components/accoutVerificationError'

const HomeWork = () => {
  const classes = useStyles()
  const { fetching, allSchoolsData, schoolIndex, error , selectedSchool} = useContext(DBContext)
  
  const [openHomeworkEntryDialogue, setOpenHomeworkEntryDialogue] = useState(
    false,
  )
  const [selectedClasses, setSelectedClasses] = useState([])
  const [selectedSubject, setSelectedSubject] = useState('')
  const [homeworkToBeEditied, setHomeworkToBeEditied] = useState(null)

  const handleClose = () => {
    setOpenHomeworkEntryDialogue(false)
  }
  const openHomeWorkEntry = () => {
    setOpenHomeworkEntryDialogue(true)
  }
  return (
    <>
      <div>
        <Backdrop className={classes.backdrop} open={fetching}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
      <div>{error && alert(error)}</div>
      <Layout>
        <Container component="main" maxWidth="md">
          <CssBaseline />
          {/**For opeing and closing HomeworkEntryEditDialogue */}
          {openHomeworkEntryDialogue && (
            <HomeworkEntryEditDialogue
              open={openHomeworkEntryDialogue}
              closeDialogue={handleClose}
              selectedSchool={selectedSchool}
              lessionData={homeworkToBeEditied}
            />
          )}
          <Grid
            continer
            spacing={4}
            direaction="column"
            className={classes.root}
          >
            <AccountVerificationError />
            {/* <Grid item xs={12} sm={10}>
              <Grid container
                spacing={1}
                direction="row"
                justify='space-around'
                alignItems="center"
              >
                <Grid item xs={12} sm={6}>
                  <Selectors updateClasses={setSelectedClasses}
                    updateSubject={setSelectedSubject}
                    selectedClasses={selectedClasses}
                    selectedSubject={selectedSubject}
                    selectedSchool={selectedSchool}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.button}
                    startIcon={<AddIcon />}
                    onClick={openHomeWorkEntry}>
                    Add Homework
                    </Button>
                </Grid>
              </Grid>
            </Grid> */}

            {/** For showing homework tiles */}
            {/* <Grid item xs={12} sm={10}>
              <HomeworkTable selectedSchool={selectedSchool} />
            </Grid> */}
          </Grid>
        </Container>
      </Layout>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginTop: '8%',
    padding: theme.spacing(2),
    marginBottom: '2%',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  padding: {
    padding: 12,
  },
  playerWrapper: {
    position: 'relative',
    paddingTop: '56.25%' /* Player ratio: 100 / (1280 / 720) */,
    padding: 20,
    marginBottom: 8,
  },
  reactPlayer: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  input: {
    display: 'none',
  },
}))

export default HomeWork
