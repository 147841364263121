import React, { useEffect, useState, useContext } from "react";
import { DBContext } from '../../../controller/contexts/dbContext';
import List from '@material-ui/core/List';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import HomeworkEntryEdit from './homeworkEntryEdit';
import HomeworkTile from './homeworkTile';
import Slide from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/CloseOutlined';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Toolbar from '@material-ui/core/Toolbar';
import HomeworkSubmissionsPage from './homeworkSubmissionsPage';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const HomeworkList = ({ selectedSchool }) => {
  const classes = useStyles();
  const {
    homeworkData,
    fetchHomeworkData,
    deleteHomework,
  } = useContext(DBContext);
  const [homeworkEntryEditDialogueOpen, setHomeworkEntryEditDialogueOpen] = useState(false);
  const [homeworkToBeEditied, setHomeworkToBeEditied] = useState(null);
  const [homeworkToViewSubmission, setHomeworkToViewSubmission] = useState(null);
  const [homeworkSubmissionDataToViewAndEdit, setHomeworkSubmissionDataToViewAndEdit] = useState(null);
  const [isHomeworkSubmissionDialogueOpen, setHomeworkSubmissionDialogueOpen] = useState(false);
  useEffect(() => {
    fetchHomeworkData()
  }, [])

  const openEditDialogue = (homework) => {
    setHomeworkToBeEditied(homework)
    setHomeworkEntryEditDialogueOpen(true)
  }
  const openHomeworkSubmissionsDialogue = (homework, homeworkSubmissionData) => {
    console.log('openHomeworkSubmissionsDialogue');
    setHomeworkToViewSubmission(homework);
    setHomeworkSubmissionDataToViewAndEdit(homeworkSubmissionData);
    setHomeworkSubmissionDialogueOpen(true);
  }
  const closeHomeworkSubmissionDialogue = () => {
    setHomeworkToViewSubmission(null);
    setHomeworkSubmissionDataToViewAndEdit(null);
    setHomeworkSubmissionDialogueOpen(false);
  }

  const handleClose = () => {
    setHomeworkEntryEditDialogueOpen(false)
  }

  const returnHomeworkSubmissionsViewDialogue = () => {
    return (
      <>
        <Dialog fullScreen TransitionComponent={Transition}
          aria-labelledby="customized-dialog-title-homework"
          open={isHomeworkSubmissionDialogueOpen}
        >

          <Toolbar>
            <IconButton color='inherit' edge="end" onClick={closeHomeworkSubmissionDialogue} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography>Close</Typography>

          </Toolbar>

          <DialogContent >
            <HomeworkSubmissionsPage
              close={closeHomeworkSubmissionDialogue}
              homeworkToView={homeworkToViewSubmission}
              homeworkSubmissonData={homeworkSubmissionDataToViewAndEdit}
            />
          </DialogContent>

        </Dialog>
      </>
    )
  }


  return (
    <>
      {isHomeworkSubmissionDialogueOpen && returnHomeworkSubmissionsViewDialogue()}
      {homeworkEntryEditDialogueOpen && <HomeworkEntryEdit
        open={homeworkEntryEditDialogueOpen}
        closeDialogue={handleClose}
        selectedSchool={selectedSchool}
        homeworkData={homeworkToBeEditied}
      />}
      <Grid container spacing={1} direction='column' className={classes.root}>
        <List >
          {homeworkData.map((eachHomework) => {
            //console.log('eachHomework', eachHomework, eachHomework.HomeworkTile);
            return <HomeworkTile

              key={`${Math.random()}+HOMEWORK`}
              homework={eachHomework}
              deleteHomework={deleteHomework}
              selectedSchool={selectedSchool}
              openEdit={openEditDialogue}
              openHomeworkSubmissionsDialogue={openHomeworkSubmissionsDialogue}
            />
          })
          }
        </List>
      </Grid>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  padding: {
    paddingTop: theme.spacing(1)
  },
  error: {
    color: 'red'
  }
}));

export default HomeworkList;