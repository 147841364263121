export default class HomeworkSubmission {
    constructor(id, submissionData) {
        //console.log('submittedAt fro submissiondata', submissionData);
        this.id = id;
        this.imageUrls = submissionData.imageUrls;
        this.kidName = submissionData.kidName;
        this.studyingClass = submissionData.studyingClass;
        this.submittedAt = submissionData.submittedAt.toDate();
        this.remarks = submissionData.remarks;
        this.homeworkScore = submissionData.score;
        this.isEvaluationDone = submissionData.isEvaluationDone;
    }
}