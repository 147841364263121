import React, { useState, useContext } from 'react'
import { DBContext } from '../../../controller/contexts/dbContext'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import placeholder from '../../assets/image-placeholder.jpg'
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton'
import Select from '@material-ui/core/Select'
import { DatePicker } from '@material-ui/pickers'
import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
const cryptoRandomString = require('crypto-random-string')

const BasicDetailsEntry = (props) => {
  const classes = useStyles()
  const {
    fetching,
    allSchoolsData,
    schoolIndex,
    STUDENT_PROFILE_IMAGE_BUCKET,
  } = useContext(DBContext)
  const [imageuploading, setImageUploading] = useState(false)
  const [progress, setProgress] = useState(0)

  let selectedSchool = allSchoolsData[schoolIndex]
  //Student Details
  const {
    dob,
    setDob,
    studentProfileImageUrl,
    setStudentProfileImageUrl,
    studentClass,
    setStudentClass,
    isNewStudentCreating,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    address,
    setAddress,
    city,
    setCity,
    aadharNumber,
    setAadharNumber,
    pincode,
    setPincode,
    country,
    setCountry,
    landmark,
    setLandmark,
    stateName,
    setStateName,
    district,
    setDistrict,
  } = props

  const updateDOB = (date) => {
    setDob(date.toDate())
  }

  /** Functions to handle file uploads */
  const handleUploadStart = () => {
    setProgress(0)
    setImageUploading(true)
  }
  const handleProgress = (progress) => setProgress(progress)
  const handleUploadError = (error) => {
    setProgress(0)
    setImageUploading(false)
    console.error(error)
    alert(error)
  }

  const handleUploadSuccess = (filename) => {
    setProgress(100)
    setImageUploading(false)
    STUDENT_PROFILE_IMAGE_BUCKET.child(filename)
      .getDownloadURL()
      .then((url) => {
        console.log('Url', url)
        setStudentProfileImageUrl(url)
      })
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item>
              {imageuploading ? (
                <CircularProgress />
              ) : (
                <Avatar
                  alt="Icon"
                  src={
                    studentProfileImageUrl != null
                      ? studentProfileImageUrl
                      : placeholder
                  }
                  className={classes.bigAvatar}
                />
              )}
            </Grid>
            {/* For upload button */}
            <Grid item>
              <CustomUploadButton
                hidden
                accept="image/*"
                maxHeight="450"
                maxWidth="450"
                filename={(file) => cryptoRandomString({ length: 18 })}
                storageRef={STUDENT_PROFILE_IMAGE_BUCKET}
                onUploadStart={handleUploadStart}
                onUploadError={handleUploadError}
                onUploadSuccess={handleUploadSuccess}
                onProgress={handleProgress}
                style={{ color: 'white', borderRadius: 4 }}
              >
                <Button component="span">UPLOAD/CHANGE</Button>
              </CustomUploadButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="firstName"
            name="firstName"
            label="First name"
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            fullWidth
            autoComplete="fname"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="lastName"
            name="lastName"
            label="Last name"
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            fullWidth
            autoComplete="lname"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="aadhar"
            name="aadhar"
            label="Aadhar"
            value={aadharNumber}
            onChange={(event) => setAadharNumber(event.target.value)}
            fullWidth
            autoComplete="aadhar"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            required
            id="address"
            name="address"
            label="Address"
            value={address}
            onChange={(event) => setAddress(event.target.value)}
            fullWidth
            autoComplete="billing address-line1"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="landmark"
            name="landmark"
            label="Landmark"
            value={landmark}
            onChange={(event) => setLandmark(event.target.value)}
            fullWidth
            autoComplete="billing address-line2"
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            required
            id="city"
            name="city"
            label="City/Town/Village"
            value={city}
            onChange={(event) => setCity(event.target.value)}
            fullWidth
            autoComplete="billing address-level2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="District/County"
            name="District/County"
            label="District/County"
            value={district}
            onChange={(event) => setDistrict(event.target.value)}
            fullWidth
            autoComplete="billing address-level2"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            id="state"
            name="state"
            value={stateName}
            onChange={(event) => setStateName(event.target.value)}
            label="State/Province/Region"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="zip"
            name="zip"
            label="Zip/Postal code"
            value={pincode}
            onChange={(event) => setPincode(event.target.value)}
            fullWidth
            autoComplete="billing postal-code"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            value={country}
            onChange={(event) => setCountry(event.target.value)}
            autoComplete="billing country"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            fullWidth
            disableFuture
            openTo="year"
            format="MMMM Do YYYY"
            label="Date of birth"
            views={['year', 'month', 'date']}
            value={dob}
            onChange={updateDOB}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl className={classes.formControl}>
            <InputLabel id="select-joining-class-$">Joining Class</InputLabel>
            <Select
              fullWidth
              labelId="class-name-label77"
              id="class-name-selecting-id11"
              value={studentClass}
              onChange={(event) => setStudentClass(event.target.value)}
            >
              {!!selectedSchool && selectedSchool.schoolClasses
                ? selectedSchool.schoolClasses.map((option) => (
                    <MenuItem
                      key={option + `${Math.random()}`}
                      value={option}
                      fullWidth
                    >
                      {option.classDisplayName}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  bigAvatar: {
    display: 'flex',
    alignContent: 'center',
    width: 120,
    height: 120,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}))

export default BasicDetailsEntry
