import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useCollectionOnce } from 'react-firebase-hooks/firestore'
import { AuthContext } from '../../../controller/contexts/authContext'
import { DBContext } from '../../../controller/contexts/dbContext'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import CssBaseline from '@material-ui/core/CssBaseline'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import ArrowForwordIcon from '@material-ui/icons/ArrowForwardOutlined'
import LinearProgress from '@material-ui/core/LinearProgress'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import TextField from '@material-ui/core/TextField'
import placeholder from '../../assets/image-placeholder.jpg'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import Divider from '@material-ui/core/Divider'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { CircularProgress } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import CardActions from '@material-ui/core/CardActions'
import { flexbox } from '@material-ui/system'

const Welcome = () => {
  const { currentUser } = useContext(AuthContext)

  const {
    allSchoolsData,
    fetching,
    proceedToCreateNewSchool,
    setSelectedSchool,
    updateIndex,
    setSelectedProgramType,
    setIsNewProgramCreating,
    fetchUserAsOrganizeationData,
    orgData,
  } = useContext(DBContext)
  const [loading, setLoading] = useState(false)
  const [schoolName, setSchoolName] = useState('')
  const [schoolNameError, setSchoolNameError] = useState(false)
  const [role, setRole] = useState('INSTRUCTOR')
  const history = useHistory()
  const classes = useStyles()
  //console.log('the history is', history);

  useEffect(() => {
    // Calling DBcontext mathod to fetch
    // schools related to the email id
    // for showing schools.
    // fetchSchoolDetailsAssociatedWithUserEmail()
    if (role === 'INSTRUCTOR') {
    } else {
      fetchOrgAssociatedData()
    }
  }, [role])

  const fetchOrgAssociatedData = async () => {
    console.log('fetchOrgAssociatedData')
    await fetchUserAsOrganizeationData()
    console.log('Org data after initial fetch', orgData)
  }

  // to move to the seclected school details
  const moveToSelectedSchoolFromList = async (index) => {
    await updateIndex(index)
    await history.push('/Home')
  }
  // Moves to organizations view
  const moveToOrgViewPage = async () => {
    return await history.push('/OrgView')
  }

  const createNewSchool = async () => {
    if (!!schoolName && schoolName.length > 8) {
      try {
        proceedToCreateNewSchool(schoolName)
        history.push('/Settings')
      } catch (error) {
        alert(error.message)
      }
    } else {
      setSchoolNameError('School Name Must be Minimum 8 Charactors')
    }
  }

  const createAdditonalSchool = async () => {
    proceedToCreateNewSchool(schoolName)
    history.push('/Settings')
  }

  const resendEmailVarification = () => {
    setLoading(true)
    //  verify the email id
    currentUser
      .sendEmailVerification()
      .then(function () {
        setLoading(false)
        alert('Verification Email is sent')
      })
      .catch((error) => {
        setLoading(false)
        console.log(error.message, error.errorcode)
        var errorCode = error.code
        var errorMessage = error.message
        console.log(errorMessage)
        errorMessage && alert(`${errorMessage} \n ${errorCode}`)
      })
  }

  const returnNoEmailVerificationMessage = () => {
    return (
      <Grid container spacing={0}>
        <Paper className={classes.paper}>
          <HighlightOffRoundedIcon
            fontSize="large"
            color="error"
            className={classes.icon}
          />
          <Typography
            variant="h5"
            noWrap={true}
            gutterBottom={true}
            color="inherit"
          >
            Email Verification Not Finished{' '}
          </Typography>
          <Typography variant="body1" gutterBottom={true} color="textPrimary">
            Please Check Your Email Address To Verify.{' '}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            If you dont recive verification email, click below to send
            veryifiction mail again.{' '}
          </Typography>
          <Button
            color="primary"
            onClick={resendEmailVarification}
            className={classes.verificationButton}
          >
            Resend Verification Email
          </Button>
        </Paper>
      </Grid>
    )
  }

  const returnSchoolListTiles = () => {
    console.log('returnSchoolListTiles', allSchoolsData)

    return (
      <div className={classes.paper}>
        <Typography
          variant="h5"
          component="h1"
          noWrap={true}
          gutterBottom={true}
          color="inherit"
        >
          {`Welcome, ${currentUser.displayName}`}
        </Typography>
        <Typography variant="body1" color="textSecondary">
          You are autherized to work on school/college listed below, click any
          of them to proceed.
        </Typography>
        <List dense className={classes.list}>
          {!!allSchoolsData &&
            allSchoolsData.map((eachSchoolProfile, index) => {
              return (
                <>
                  <ListItem
                    key={index + `${Math.random()}`}
                    button
                    className={classes.listItem}
                  >
                    <ListItemAvatar>
                      <Avatar
                        alt={`${eachSchoolProfile.schoolName} School Logo Image`}
                        src={
                          eachSchoolProfile.imageURL
                            ? eachSchoolProfile.imageURL
                            : placeholder
                        }
                      />
                    </ListItemAvatar>
                    <ListItemText
                      onClick={() => moveToSelectedSchoolFromList(index)}
                      primary={
                        <>
                          <Typography variant="subtitle2" color="textPrimary">
                            {`${eachSchoolProfile.schoolName} - ${eachSchoolProfile.branchName}`}
                          </Typography>
                        </>
                      }
                      secondary={`${eachSchoolProfile.address}`}
                    />

                    <ListItemSecondaryAction>
                      <ArrowForwordIcon />
                    </ListItemSecondaryAction>
                  </ListItem>
                  <Divider variant="inset" component="li" />
                </>
              )
            })}
        </List>

        <Button
          color="primary"
          className={classes.addButton}
          onClick={createAdditonalSchool}
        >
          + Add New School/College
        </Button>
      </div>
    )
  }

  const returnNoActiveSchoolMessage = () => {
    console.log('returnNoActiveSchoolMessage')
    return (
      <Paper className={classes.paper}>
        <Typography
          variant="h5"
          component="h1"
          noWrap={true}
          gutterBottom={true}
          color="inherit"
        >
          {`Welcome, ${currentUser.displayName}`}
        </Typography>
        <Typography variant="body1" color="inherit" noWrap={true}>
          You are not listed in any School/College so far.
        </Typography>
        <Typography variant="body1" color="textSecondary" align="center">
          Ask your school management to add your email id, as modifyer.
        </Typography>
        <div className={classes.orDiv}>
          <Typography variant="body1" color="textSecondary" align="center">
            --------- OR ----------
          </Typography>
        </div>

        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id={`${Math.random()}`}
            helperText="Enter your institution name and click below button to proceed "
            label="Enter Your School/College Name"
            name="schoolName"
            value={schoolName}
            onChange={(event) => {
              setSchoolNameError(null)
              setSchoolName(event.target.value)
            }}
          />
          {schoolNameError && (
            <Typography variant="subtitle2" color="error">
              {schoolNameError}
            </Typography>
          )}

          <Button
            onClick={createNewSchool}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.button}
            endIcon={<ArrowForwordIcon />}
          >
            Register Your School/College
          </Button>
        </form>
      </Paper>
    )
  }

  // If the organazation selectors changed
  // calles the appropraate fetching function to
  // get the data accordingly +
  const handleOrgSelectorChange = async (event) => {
    setRole(event.target.value)
    // if (event.target.value === 'ORGANIZATION') {
    //   await fetchUserAsOrganizeationData();
    //   setRole(event.target.value);
    // }
  }

  const renderUserAsInstructorCoursersView = () => {
    if (fetching || loading) {
      return <CircularProgress />
    }

    return (
      <div>
        <p> Classes for instructor</p>
      </div>
    )
  }

  const renderUserAsOrganizationData = () => {
    if (fetching || loading) {
      return <CircularProgress />
    }
    return (
      <div>
        {!!orgData
          ? returnOrgsListAndSchoolCollegeCoursesList()
          : returnCreateOrgTile()}
      </div>
    )
  }

  const returnCreateOrgTile = () => {
    return (
      <div>
        <Paper className={classes.fullWidth}>
          <Box mb={1} p={2} px={8}>
            <Typography variant="body1" color="textSecondary">
              No organization is associated with this account
            </Typography>
          </Box>
          <Box p={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={moveToOrgViewPage}
            >
              + Create Organization
            </Button>
          </Box>
        </Paper>
      </div>
    )
  }

  const returnOrgsListAndSchoolCollegeCoursesList = () => {
    //console.log('returnOrgsListAndSchoolCollegeCoursesList', orgData.imageUrl);
    return (
      <div>
        <Grid container direction="column" className={classes.fullWidth}>
          <Grid item xs={12} sm={12}>
            <Box mt={2} p={2}>
              <Card className={classes.card}>
              {orgData.imageUrl && <CardMedia
                className={classes.cover}
                image={orgData.imageUrl}
                title={orgData.name}
              />}
                <div className={classes.details}>
                  <CardContent className={classes.content}>
                    <Typography component="h5" variant="h5">
                      {orgData.name.toUpperCase()}
                    </Typography>
                    <Typography variant="subtitle2" color="textSecondary">
                      {orgData.orgType}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {/* <Button size="small" color="primary" onClick={moveToOrgViewPage}>
                      Settings
                    </Button> */}
                  </CardActions>
                </div>
              </Card>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} className={classes.card}>
            <Box mt={1}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="flex-start"
              >
                <Grid item>
                  <Typography paragraph variant="subtitle1">
                    Schools/Colleges
                  </Typography>

                  <Box
                    display="flex"
                    flexDirection="column"
                    alignContent="center"
                    p={1}
                    mb={1}
                  >
                    <Box width="100%">
                      {!!allSchoolsData && allSchoolsData.length > 0
                        ? returnSchoolCollegeTiles()
                        : returnNoSchoolCollegeMessage()}
                    </Box>
                    <Box>
                      <Button color="primary" onClick={createNewSchoolCollege}>
                        + Add/Create School or College
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </div>
    )
  }

  const returnSchoolCollegeTiles = () => {
    return (
      <div className={classes.tile}>
        <List dense={true}>
          {!!allSchoolsData && allSchoolsData.length > 0 ? (
            allSchoolsData.map((eachSchool) => (
              <ListItem key={`${Math.random()}`}>
                <ListItemAvatar>
                  <Avatar src={eachSchool.imageURL} />
                </ListItemAvatar>
                <ListItemText
                  primary={eachSchool.schoolName}
                  secondary={eachSchool.branchName}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="arrow-in"
                    onClick={() => handleClickOnSchool(eachSchool)}
                  >
                    <ArrowForwordIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))
          ) : (
            <Typography variant="h6" color="textSecondary">
              {' '}
              No Schools present
            </Typography>
          )}
        </List>
      </div>
    )
  }

  // When click on the school tile
  // it will add the
  const handleClickOnSchool = (schoolData) => {
    setSelectedSchool(schoolData)
    setSelectedProgramType('SCHOOLCOLLEGE')
    history.push('/Settings')
  }

  const returnNoSchoolCollegeMessage = () => {
    return (
      <Typography paragraph variant="subtitle2" color="textSecondary">
        No School/College associated with this organization
      </Typography>
    )
  }

  /**
   * Sets the type of the program
   * and moves to the setting page of the school/college
   */
  const createNewSchoolCollege = async () => {
    setSelectedProgramType('SCHOOLCOLLEGE')
    setIsNewProgramCreating(true)
    history.push('/Settings')
  }

  /**Fetching the body component */
  const fetchBody = () => {
    return (
      <div className={classes.root}>
        <Grid
          container
          direction="column"
          alignContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item sm={12}>
            <Typography
              variant="h5"
              component="h1"
              noWrap={true}
              gutterBottom={true}
              color="inherit"
            >
              {`Welcome, ${currentUser.displayName}`}
            </Typography>
          </Grid>
          <Grid item className={classes.select} sm={12}>
            <Typography
              variant="subtitle2"
              color="textSecondary"
              className={classes.icon}
            >
              {' '}
              View As
            </Typography>
            <Select
              labelId={`${Math.random()}`}
              id={`${Math.random()}`}
              value={role}
              onChange={handleOrgSelectorChange}
            >
              <MenuItem key={`${Math.random()}`} value={'INSTRUCTOR'}>
                INSTRUCTOR
              </MenuItem>
              <MenuItem key={`${Math.random()}`} value={'ORGANIZATION'}>
                ORGANIZATION
              </MenuItem>
            </Select>
          </Grid>
          <Grid item>
            {
              //!!allSchoolsData ? returnSchoolListTiles() : returnNoActiveSchoolMessage()
              role === 'INSTRUCTOR'
                ? renderUserAsInstructorCoursersView()
                : renderUserAsOrganizationData()
            }
          </Grid>
        </Grid>
      </div>
    )
  }
  return (
    <>
      {(fetching || loading) && <LinearProgress />}

      <Container component="main" maxWidth="sm">
        <CssBaseline />
        {!!currentUser.emailVerified
          ? fetchBody()
          : returnNoEmailVerificationMessage()}
      </Container>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
    display: 'flex',
  },
  card: {
    display: 'flex',
    padding: 16,
    
  },
  cover: {
    width: 120,
    height: 150,
    padding: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
    width: '100%',
  },
  fullWidth: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    justifyItems: 'center',
  },
  tile: {
    backgroundColor: theme.palette.background.paper,
  },

  select: {
    display: 'flex',
    paddingLeft: '10%',
  },
  list: {
    width: '100%',
    maxWidth: 400,
    padding: 8,
    backgroundColor: theme.palette.background.paper,
    margin: 16,
  },
  icon: {
    margin: theme.spacing(1),
  },
  verificationButton: {
    marginTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(2, 0, 1),
    backgroundColor: 'green',
  },
  orDiv: {
    marginTop: theme.spacing(3),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  addButton: {
    marginTop: 20,
  },
  listItem: {
    padding: 8,
    margin: 8,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  center: {
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
}))

export default Welcome
