import React, { useState, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../../../controller/contexts/authContext';
import { DBContext } from '../../../controller/contexts/dbContext';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import SignUpSuccessDialogue from '../../components/signUpSuccessDialogue';
import { validateEmail, validatePassword, validateText } from '../../../controller/helpers';
import LinearProgress from '@material-ui/core/LinearProgress';


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        Edaffix Technologies LLP
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



export default function SignUp() {
  const { loading, doCreateUserWithEmailAndPassword, auth, doSignOut } = useContext(AuthContext);
  const { saveSchoolUserDetails } = useContext(DBContext);
  const [dialagueOpen, setDialogueOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [firstNameError, setFirstNameError] = useState(null);
  const [lastName, setLastName] = useState('');
  const [lastNameError, setLastNameError] = useState(null);
  const [agreedToTAC, setAgreedToTAC] = useState(false);
  const [agreedToTACError, setAgreedToTACError] = useState(null);
  const [fetching, setFetching] = useState(false);

  const history = useHistory();
  const classes = useStyles();



  const setErrorsNill = () => {
    setEmailError(null)
    setPasswordError(null)
    setFirstNameError(null)
    setLastNameError(null)
    setAgreedToTACError(null)
  }

  const setErrors = () => {
    !validateEmail(email) && setEmailError('Please enter valid Email')
    !validatePassword(password) && setPasswordError('Password must be minimum 8 charactors')
    !validateText(firstName) && setFirstNameError('Firstname cannot be empty')
    !validateText(lastName) && setLastNameError('Lastname cannot be empty')
    !agreedToTAC && setAgreedToTACError('Please review and aggree to terms and conditions')
  }

  const handleSignUp = async () => {
    if (validateEmail(email) && validatePassword(password) && validateText(firstName) && validateText(lastName) && !!agreedToTAC) {

      setErrorsNill();
      // 
      setFetching(true);
      try {
        await doCreateUserWithEmailAndPassword(email, password)

        var user = await auth.currentUser;
        // update the user profile with provided name
        await user.updateProfile({
          displayName: `${firstName} ${lastName}`,
        })
        // save the user Details to the database
        await saveSchoolUserDetails({
          'id': user.uid,
          'firstName': firstName,
          'lastName': lastName,
          'displayName': `${firstName} ${lastName}`,
          'email': user.email,

        }, user.uid)
        // we need to verify the email id
        await user.sendEmailVerification()
        // Sign Out the user
        await doSignOut()
        setFetching(false);
        // Open the conformation dialog 
        // saying that successfully signed up
        // and ask to conform emaiil address
        return await setDialogueOpen(true);


      } catch (error) {
        // Handle Errors here.
        setFetching(false);
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorMessage)
        errorMessage && alert(`${errorMessage} \n ${errorCode}`);
      }
    } else {
      setErrors();
    }
  }

  const closeDialogue = () => {
    setDialogueOpen(false);
    history.push("/");
  }

  return (
    <>
      {(fetching || loading) && <LinearProgress />}
      <Container component="main" maxWidth="xs">
        {dialagueOpen &&
          <SignUpSuccessDialogue
            close={closeDialogue}
          />}
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign up
        </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="fname"
                  name="firstName"
                  variant="outlined"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={firstName}
                  onChange={(event) => {
                    setFirstName(event.target.value)
                    setFirstNameError(null)
                  }
                  }
                />
                {firstNameError &&
                  <Typography variant="subtitle2" color="error">{firstNameError}</Typography>
                }
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="lname"
                  value={lastName}
                  onChange={(event) => {
                    setLastName(event.target.value)
                    setLastNameError(null)
                  }
                  }
                />
                {lastNameError &&
                  <Typography variant="subtitle2" color="error">{lastNameError}</Typography>
                }
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(event) => {
                    setEmail(event.target.value)
                    setEmailError(null)
                  }
                  }
                />
                {emailError &&
                  <Typography variant="subtitle2" color="error">{emailError}</Typography>
                }
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(event) => {
                    setPassword(event.target.value)
                    setPasswordError(null)
                  }}

                />
                {passwordError &&
                  <Typography variant="subtitle2" color="error">{passwordError}</Typography>
                }
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel

                  control={
                    <Checkbox
                      value="agree Terms and Conditions"
                      color="primary"
                      checked={agreedToTAC}
                      onChange={(event) => {
                        setAgreedToTAC(event.target.checked)
                        setAgreedToTACError(null)
                      }}
                    />
                  }
                  label="I / We agree with Terms and Conditions."
                />
                {agreedToTACError &&
                  <Typography variant="subtitle2" color="error">{agreedToTACError}</Typography>
                }
              </Grid>
            </Grid>
            <Button
              onClick={handleSignUp}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign Up
          </Button>
            <Grid container justify="center">
              <Grid item>
                <Link href="/" variant="body2">
                  Already have an account? Sign in
              </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={5}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'green',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));