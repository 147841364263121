import React, { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, 
  DialogTitle, Button, TextField,DialogContentText
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const SchoolSubjectsEntry = (props) => {
  const [subject, setSubject] = useState('');
  const { updateSubjectDetails, closeSubjectDialogue,isOpen } = props;
  const classes = useStyles();

  // send the Subject name to the Settings 

  const updateSubject = () => {
    // Update the subject in the settings page
    // and reset the state with empty 
    // and closes the dialogue 
    if (subject !== '') {
        updateSubjectDetails(subject.trim().toUpperCase())
      console.log(subject)
      setSubject((''))
      close();
    }
  }
  const close = () => {
    closeSubjectDialogue();
  }
  return (
    <>
    <Dialog
          open={isOpen}
          onClose={null}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth = {'sm'}
        >
          <DialogTitle id="alert-dialog-title">{"Add Subjects you Teach"}</DialogTitle>
          <DialogContent>
          <DialogContentText>
                Pleas Add Subjects
                
          </DialogContentText>

            
            <TextField
              id="outlined-subject"
              placeholder="Eg: Scince, Sanskrit, Maths,..."
              className={classes.textField}
              value={subject}
              onChange={(event)=>setSubject(event.target.value)}
              margin="normal"
              variant="outlined"
              helperText="Subject"
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={close} color="primary">
              Cancel
            </Button>
            <Button onClick={updateSubject} color="primary" >
              Add
            </Button>
          </DialogActions>
        </Dialog>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  textField: {
    margin: 8,
    marginTop: 16,

  },
}));

export default SchoolSubjectsEntry;