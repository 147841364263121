/**
 * This component is for creating school/college with details and 
 * Editing the details
 * NOTE: acadamic year cannot be deleted can only editable
 */

import React, { useState, useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { AuthContext } from '../../../controller/contexts/authContext'
import { DBContext } from '../../../controller/contexts/dbContext'
import Layout from '../../layout';
import ClassesEntryDialog from "../../components/classEntryDialogue";
import SubjectEntryDialog from '../../components/schoolSubjectsEntry';
import LocationEntryDiaog from '../../components/studetnLocationEntry';
import placeholder from '../../assets/image-placeholder.jpg';
import CustomUploadButton from 'react-firebase-file-uploader/lib/CustomUploadButton';
import Loading from '../../components/loading';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import Backdrop from '@material-ui/core/Backdrop';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CallenderIcon from '@material-ui/icons/CalendarTodayOutlined';
import SettingsIcon from '@material-ui/icons/Settings';

import {
  TextField, Button,
  Paper, Chip
} from '@material-ui/core';
import AcadamicYear from '../../components/settings/addOrEditAcadamicYear';
const cryptoRandomString = require('crypto-random-string');
var moment = require('moment');

const SchoolCollegeEntryEdit = (props) => {

  const classes = useStyles();
  const { currentUser } = useContext(AuthContext);
  const {
    fetching, allSchoolsData, schoolIndex,
    isNewProgramCreating, selectedSchool,
    updateSchoolCollegeProfileData,
    createSchoolCollege, selectedProgramType,
    SCHOOL_PROFILE_IMAGE_BUCKET,
  } = useContext(DBContext);

  // Schoo Main Details
  const [imageURL, setImageURL] = useState(null);
  const [schoolName, setSchoolName] = useState('');
  const [branchName, setBranchName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('India');
  const [pincode, setPincode] = useState('');
  const [phone1, setPhone1] = useState('');
  const [phone2, setPhone2] = useState('');
  const [schoolClasses, setSchoolClasses] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [locations, setLocations] = useState([]);
  const [acadamicYears, setAcdamicYears] = useState([])

  // School Function Status modifyers
  const [classDialogOpen, setClassDialogOpen] = useState(false);
  const [subjectDialogOpen, setSubjectDialogOpen] = useState(false);
  const [locationDialogOpen, setLocationDialogOpen] = useState(false);
  const [isAcadamicYearEntryEditOpen, setAcadamicYearEntryEditOpen] = useState(false);

  const [selectedAcadamicYearDataToEdit, setSelectedAcadamicYearDataToEdit] = useState(null)

  // Progress Indicators
  const [loading, setLoading] = useState(false);
  const [imageUploading, setImageUploading] = useState(false);
  const [progress, setProgress] = useState(0);




  useEffect(() => {
    // if (isNewSchoolCreating) {
    //   // nothing to do do here, we dont' have the 
    //   // data regarding the new school yet.
    //   // we need to save the details
    //   !!newSchoolNamae && setSchoolName(newSchoolNamae)
    // } 
    if (isNewProgramCreating && selectedProgramType) {
      console.log('New school is creating')
    }

    else {
      //const schoolDetails = allSchoolsData[schoolIndex];
      const schoolDetails = selectedSchool;
      console.log('selected School', schoolDetails);
      console.log(isNewProgramCreating);

      setSchoolName(schoolDetails.schoolName);
      setImageURL(schoolDetails.imageURL);
      setBranchName(schoolDetails.branchName);
      setAddress(schoolDetails.address);
      setCity(schoolDetails.city);
      setDistrict(schoolDetails.district)
      setState(schoolDetails.state);
      setCountry(schoolDetails.country);
      setPincode(schoolDetails.pincode);
      setPhone1(schoolDetails.phone1);
      setPhone2(schoolDetails.phone2);
      setSchoolClasses(schoolDetails.schoolClasses);
      setSubjects(schoolDetails.subjects);
      setLocations(schoolDetails.locations);
    }
  }, [])

  /**
   * Acadamic year Tile
   * @param {Object} props 
   */
  const AcadamicYearTile = (props) => {
    const { acadamicYearData } = props;
    return (
      <Paper style={{ margin: 8 }}>
        <ListItem>
          <ListItemAvatar>
            <Avatar>
              <CallenderIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${acadamicYearData.displayName}`}
            secondary={`From ${moment(acadamicYearData.yearStartingDate).format("MMM Do YYYY")} to ${moment(acadamicYearData.yearEndingDate).format("MMM Do YYYY")}`}
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="delete" onClick={() => handleEditAcadamicYear(acadamicYearData)}>
              <SettingsIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </Paper>

    )
  }

  /**
   * Save School profile data
   */
  const saveData = () => {
    const dataPayload = {
      imageURL, schoolName, branchName, address, city, district, state,
      country, pincode, phone1, phone2, schoolClasses, subjects, locations,
      type: 'SCHOOLCOLLEGE'
    }
    if(!schoolName || !branchName || !address|| !city || !district || !state || !phone1){
      return alert('Please fill basic details')
    }
    console.log(dataPayload);

    isNewProgramCreating ? createSchoolCollege({ ...dataPayload }) :
      updateSchoolCollegeProfileData({ ...dataPayload })
  }


  /*** For Adding Accadamic year data */

  // For Acadamic year EntryEdit
  const openAcadamicYearDialogue = () => {
    setSelectedAcadamicYearDataToEdit(null);
    return setAcadamicYearEntryEditOpen(true);
  }
  const closeAcadamicYearDialogue = () => {
    setSelectedAcadamicYearDataToEdit(null);
    return setAcadamicYearEntryEditOpen(false);
  }
  const addAcadamicYear = (acYearData) => {
    setAcdamicYears(acadamicYears => [...acadamicYears, acYearData])
    return closeAcadamicYearDialogue();
  }

  const handleEditAcadamicYear = (data) => {
    //console.log('should open to edit', data);
    setSelectedAcadamicYearDataToEdit(data);
    setAcadamicYearEntryEditOpen(true);
  }
  const updateAcadamicYearData = (data) => {
    setSelectedAcadamicYearDataToEdit(null);
    let index = null
    acadamicYears.forEach((y, i) => {
      if (y.id === data.id) {
        index = i
      }
    })
    let allAcYears = [...acadamicYears];
    if (index !== null) { allAcYears[index] = data }
    setAcdamicYears([...allAcYears]);
    closeAcadamicYearDialogue();

  }


  /******* END: Acadamic years ********* */


  // for updating class names  
  const updateClassNames = value => {
    let classI = null
    schoolClasses.forEach((item, index) => {
      if (item.id === value.id) {
        classI = index
      }
    })
    classI == null && setSchoolClasses([...schoolClasses, value]);

  }

  //// for updating subjects 
  const updateSubjects = value => {
    let subI = null
    subjects.forEach((item, index) => {
      if (item === value) {
        subI = index
      }
    })

    subI == null && setSubjects([...subjects, value])

  }

  /** Update the location */
  const updateLocation = value => {
    let locI = null
    locations.forEach((eachLocation, index) => {
      if (eachLocation === value) {
        locI = index
      }
    })

    locI == null && setLocations([...locations, value]);
  }

  /**
   * For handling Location Detete
   */

  const handleLocationChipDelete = name => {
    const chipData = [...locations]
    const chipToDelete = chipData.indexOf(name)
    chipData.splice(chipToDelete, 1);
    console.log(chipData)
    setLocations([...chipData])

  }

  // For Handling delete function for Subject
  const handleSubjectChipDelete = name => {


    const chipData = [...subjects];
    const chipToDelete = chipData.indexOf(name);

    chipData.splice(chipToDelete, 1);
    console.log(chipData)
    setSubjects([...chipData]);
  }

  // // For Handling delete function for Classes
  const handleClassesChipDelete = name => {

    const chipData = [...schoolClasses];
    const chipToDelete = chipData.indexOf(name);

    chipData.splice(chipToDelete, 1);
    console.log(chipData)
    setSchoolClasses([...chipData]);

  }


  /** Functions to handle file uploads */
  const handleUploadStart = () => {
    setProgress(0);
    setImageUploading(true);
  }
  const handleProgress = progress => setProgress(progress);
  const handleUploadError = error => {
    setProgress(0);
    setImageUploading(false);
    console.error(error);
    alert(error);
  };

  const handleUploadSuccess = filename => {
    console.log('handleUploadSuccess', filename)
    setProgress(100);
    setImageUploading(false);
    SCHOOL_PROFILE_IMAGE_BUCKET.child(filename)
      .getDownloadURL()
      .then(url => {
        console.log('Url', url);
        setImageURL(url)
      });

  };


  const returnSchoolCollegeEntryEdit = () => {
    return (
      <>
        <div>
          {
            isAcadamicYearEntryEditOpen && <AcadamicYear
              save={addAcadamicYear}
              update={updateAcadamicYearData}
              close={closeAcadamicYearDialogue}
              data={selectedAcadamicYearDataToEdit}
              isOpen={isAcadamicYearEntryEditOpen}
            />
          }
        </div>
        {/* For Classes Entry dialog componet  */}
        <div>
          <ClassesEntryDialog
            isOpen={classDialogOpen}
            closeClassEntryDialogue={() => setClassDialogOpen(false)}
            updateClassDetails={updateClassNames}
          />
        </div>


        {/* For  Subject Entry dialog componet  */}

        <div>
          <SubjectEntryDialog
            isOpen={subjectDialogOpen}
            closeSubjectDialogue={() => setSubjectDialogOpen(false)}
            updateSubjectDetails={updateSubjects}
          />
        </div>

        {/* For  Location Entry dialog componet  */}

        <div>
          <LocationEntryDiaog
            isOpen={locationDialogOpen}
            closeLocationDialogue={() => setLocationDialogOpen(false)}
            updateLocationDetails={updateLocation}
          />
        </div>

        <Grid container spacing={1} direction="column">

          {/* Name, Address, and Logo Image */}
          <Grid item sm={12}>

            <Grid container spacing={1} direction={'row'}>

              {/* For School Name , Branch and Address */}
              <Grid item sm={8}>
                <Grid container spacing={1} direction={"column"}>
                  <Grid item sm={8}>
                    <TextField
                      id="outlined-name"
                      label="School Name"
                      className={classes.textField}
                      value={schoolName}
                      onChange={(event) => setSchoolName(event.target.value)}
                      margin="normal"
                      variant="outlined"
                      helperText="SCHOOL NAME"
                    />
                  </Grid>
                  <Grid item sm={8}>
                    <TextField
                      id="outlined-Branch Name"
                      label="Branch Name"
                      className={classes.textField}
                      value={branchName}
                      onChange={(event) => setBranchName(event.target.value)}
                      margin="normal"
                      variant="outlined"
                      helperText="Branch Name"
                    />
                  </Grid>
                  <Grid item sm={8}>
                    <TextField
                      multiline
                      rowsMax="4"
                      id="outlined-School Address"
                      label="School Address"
                      className={classes.textField}
                      value={address}
                      onChange={(event) => setAddress(event.target.value)}
                      margin="normal"
                      variant='outlined'
                      rows={4}
                      helperText="ADDRESS"
                    />
                  </Grid>
                </Grid>
              </Grid>

              {/* For Imag Display and Uploading */}
              <Grid item sm={4}>
                <Grid container direction={'column'} alignItems='center' spacing={1}>
                  <Grid item sm>
                    {/* For the image  */}
                    {imageUploading ? <CircularProgress /> : <img className={classes.image} alt='icon' src={imageURL != null ? imageURL : placeholder} />}
                  </Grid>

                  <Grid item sm >
                    {imageUploading && <p>Progress: {progress}</p>}
                    {/* For upload button */}
                    <CustomUploadButton
                      hidden
                      maxWidth={500}
                      maxHeight={500}
                      accept="image/*"
                      filename={file => cryptoRandomString({ length: 11 })}
                      storageRef={SCHOOL_PROFILE_IMAGE_BUCKET}
                      onUploadStart={handleUploadStart}
                      onUploadError={handleUploadError}
                      onUploadSuccess={handleUploadSuccess}
                      onProgress={handleProgress}
                      style={{ backgroundColor: 'grey', color: 'white', padding: 4, borderRadius: 8 }}
                    >
                      UPLOAD/CHANGE
                        </CustomUploadButton>
                  </Grid>

                </Grid>
              </Grid>



            </Grid>

          </Grid>

          {/* City, State, Country and Zip code */}
          <Grid item sm>

            <Grid container spacing={1} direction={"row"}>

              <Grid item sm={4}>
                <TextField
                  id="outlined-cityCity/Town"
                  label="City/Town"
                  className={classes.textField}
                  value={city}
                  onChange={(event) => setCity(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  helperText="CITY/TOWN"
                />
              </Grid>

              <Grid item sm={4}>
                <TextField
                  id="outlined-district"
                  label="District"
                  className={classes.textField}
                  value={district}
                  onChange={(event) => setDistrict(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  helperText="District"
                />
              </Grid>



            </Grid>

            <Grid container spacing={1} direction={"row"}>

              <Grid item sm={3}>
                <TextField
                  id="outlined-state"
                  label="State"
                  className={classes.textField}
                  value={state}
                  onChange={(event) => setState(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  helperText="STATE"
                />
              </Grid>

              <Grid item sm={3}>
                <TextField
                disabled
                  id="outlined-Country"
                  label="India"
                  className={classes.textField}
                  value={country}
                  onChange={(event) => setCountry(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  helperText="COUNTRY"
                />
              </Grid>

              <Grid item sm={2}>
                <TextField
                  id="outlined-zip"
                  label="ZIP/PIN "
                  className={classes.textField}
                  value={pincode}
                  onChange={(event) => setPincode(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  helperText="ZIP/PIN CODE"
                />
              </Grid>

            </Grid>

          </Grid>

          {/* Phone Numbers */}
          <Grid item sm>

            <Grid container spacing={1} >

              <Grid item sm={4}>
                <TextField
                  id="phone1"
                  label="Phone 1 "
                  className={classes.textField}
                  value={phone1}
                  onChange={(event) => setPhone1(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  helperText="PHONE NUMBER 1"
                />
              </Grid>

              <Grid item sm={4}>
                <TextField
                  id="phone2"
                  label="Phone 2 "
                  className={classes.textField}
                  value={phone2}
                  onChange={(event) => setPhone2(event.target.value)}
                  margin="normal"
                  variant="outlined"
                  helperText="PHONE NUMBER 2"
                />
              </Grid>

            </Grid>

          </Grid>

          {/* Starting and ending date of Academic year */}
          {/* <Grid item xs={6} p={2}>
            <Typography> Please Add/Edit Acadamic Years</Typography>
            <Grid container spacing={1} direction={'column'}>

              {acadamicYears && acadamicYears.length > 0 ? acadamicYears.map((eachYearData, index) => <AcadamicYearTile acadamicYearData={eachYearData} index={index} />) :

                <Typography paragraph color='textSecondary'>No Acadamic years added yet</Typography>


              }


              <Grid item >
                <Button color='primary' onClick={openAcadamicYearDialogue}> +Add Acadamic Year</Button>

              </Grid>
              <Grid item>
                <Typography variant='caption'>* Please note that you you can only edit acaamic year if it has been saved you cannot delete it</Typography>
              </Grid>


            </Grid>



          </Grid> */}


          {/* For adding Classes in the schoold  */}
          <Grid item sm={8} >
            <Grid container spacing={1} direction={'column'}>

              <Grid item sm >
                <Typography variant="subtitle1" gutterBottom>
                  Select All Classes you have in your school
                </Typography>

                <Typography variant="caption" color='textSecondary' gutterBottom>
                  You can add or remove the classes
                </Typography>

              </Grid>

              <Grid item sm>

                {!!schoolClasses && schoolClasses.map((eachClass, eachKey) => (
                  <Chip
                    key={eachClass.classDisplayName}
                    label={eachClass.classDisplayName}
                    onDelete={() => handleClassesChipDelete(eachClass)}
                    className={classes.chip}
                    color="primary"
                    variant="outlined"
                  />
                ))}
              </Grid>

              <Grid item sm >
                <Button
                  color="primary"
                  className={classes.button}
                  onClick={() => setClassDialogOpen(true)}
                >
                  + ADD CLASS
                </Button>
              </Grid>

            </Grid>
          </Grid>


          {/* For Adding Subjects  */}
          <Grid item sm={8}>
            <Grid container spacing={1} direction={'column'}>

              <Grid item >
                <Typography variant="subtitle1" gutterBottom>
                  Select all subjects you have
                                </Typography>

                <Typography variant="caption" color='textSecondary' gutterBottom>
                  You can add or remove the subjects you teach in your school
        </Typography>


              </Grid>

              <Grid item>

                {!!subjects && subjects.map((eachSubject, eachKey) => (
                  <Chip
                    key={eachSubject}
                    label={eachSubject}
                    onDelete={() => handleSubjectChipDelete(eachSubject)}
                    className={classes.chip}
                    color="secondary"
                    variant="outlined"
                  />
                ))}
              </Grid>

              <Grid item>
                <Button color="primary" className={classes.button} onClick={() => setSubjectDialogOpen(true)}>
                  + ADD SUBJECT
                </Button>
              </Grid>

            </Grid>

          </Grid>



          {/* For Adding Locations  */}

          <Grid item sm={8}>
            <Grid container spacing={1} direction={'column'}>

              <Grid item >
                <Typography variant="subtitle1" gutterBottom>
                  Select Locations
          </Typography>

                <Typography variant="caption" gutterBottom>
                  You can add or remove locations your students come from
        </Typography>
              </Grid>

              <Grid item>

                {!!locations && locations.map((eachLocation, eachKey) => (
                  <Chip
                    key={eachLocation}
                    label={eachLocation}
                    onDelete={() => handleLocationChipDelete(eachLocation)}
                    className={classes.chip}
                    variant="outlined"
                  />
                ))}

              </Grid>

              <Grid item>
                <Button color="primary" className={classes.button} onClick={() => setLocationDialogOpen(true)}>
                  + ADD LOCATIONS
                  </Button>
              </Grid>

            </Grid>

          </Grid>


          {/* For Submit And Edit Buttons  */}


          <Grid item xs={6}>

            <Button
              variant="outlined"
              color="primary"
              size="large"
              disabled={loading || imageUploading || fetching}
              onClick={saveData}
              fullWidth
            >
              {isNewProgramCreating ? ('Create School/College') : ('Update Details')}
            </Button>


          </Grid>
        </Grid>

      </>
    )
  }



  return (
    <>

      <Layout>

        <Container component="main" maxWidth="md">
          {/* Displays loading screen if app fetching/updating DATA from the cloud */}

          <CssBaseline />
          <div className={classes.root}>

            {
              returnSchoolCollegeEntryEdit()
            }
          </div>

        </Container>
      </Layout>
    </>
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '1%',
    position: 'relative'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  image: {
    width: '32vh',
    height: '32vh',
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  schoolClasses: {
    marginTop: theme.spacing.unit * 2,
    padding: theme.spacing.unit * 2,
  },
  addButton: {
    padding: theme.spacing.unit * 2,
  },
  textField: {
    width: '100%',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit
  },
  chip: {
    margin: theme.spacing.unit / 2,
  },

}))




export default SchoolCollegeEntryEdit;