import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import { AuthContext } from '../../../controller/contexts/authContext';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import { validateEmail, validatePassword } from '../../../controller/helpers';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="#">
        Edaffix Technologies LLP
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}



export default function SignIn() {
  const { loading, doSignInWithEmailAndPassword, currentUser } = useContext(AuthContext);
  const classes = useStyles();
  const history = useHistory();
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(null);
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(null);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    // If the current user is present in the 
    // context push to welcome page.

    currentUser && history.push("/Welcome");
  }, [currentUser, history])


  function handleSignIn() {

    if (validateEmail(email) && validatePassword(password)) {

      setEmailError(null)
      setPasswordError(null)
      // 
      setFetching(true);
      doSignInWithEmailAndPassword(email, password).catch(function (error) {
        // Handle Errors here.
        setFetching(false);
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorMessage)
        errorMessage && alert(`${errorMessage} \n ${errorCode}`);
        // ...
      });

    } else {
      setFetching(false)
      !validateEmail(email) && setEmailError('Please enter valid Email')
      !validatePassword(password) && setPasswordError('Password must be minimum 8 charactors')
    }
  }
  return (
    <>
      {(fetching || loading) && <LinearProgress />}
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in Securely
        </Typography>
          <form className={classes.form} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={email}
              onChange={(event) => {
                setEmailError(null)
                setEmail(event.target.value)
              }}
              autoFocus
            />
            {emailError && <Typography variant="subtitle2" color="error">{emailError}</Typography>}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(event) => {
                setPasswordError(null)
                setPassword(event.target.value)
              }}
              autoComplete="current-password"
            />
            {passwordError && <Typography variant="subtitle2" color="error">{passwordError}</Typography>}
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              onClick={handleSignIn}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Sign In
          </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/ForgetPassword" variant="body2">
                  Forgot password?
              </Link>
              </Grid>
              <Grid item>
                <Link href='/Signup' variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}


const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: 'green',
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));